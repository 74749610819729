import React from 'react';
import {Link} from "react-router-dom";
import axios, {put} from "axios";
import {Skeleton, message, notification, Icon} from 'antd';

const api_url = process.env.REACT_APP_API_URL;

export default class Edit extends React.Component {
    constructor(props) {
        super(props);

        this.state = { id: this.props.match.params.id, nama:'',url:'',image:'', new_image: null };

        this.onFormSubmit = this.onFormSubmit.bind(this)
        this.onChangeFile = this.onChangeFile.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.fileUpload = this.fileUpload.bind(this)
    }

    componentDidMount() {

        document.title = process.env.REACT_APP_APP_NAME + "::Haba APP";
        this.get_data();

    }


    get_data(){
        var header = {
          headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };
        axios.get( api_url+'link_aplikasi/get/'+this.state.id, header)
        .then(
          response => response.data,
          error => { alert( error ) }
        )
        .then((data) => {

          if( typeof data == 'object' ){
            if( data.status === 'success' ){
              this.setState({
                nama: data.data.nama,
 					url: data.data.url,
 					image: data.data.image,
 					//##state_field##:##state_value##//
                loading: false
            });
            }else{
              message.error( data.data.data );
            }

          }
        })
      }


      onFormSubmit(e){
        e.preventDefault();
        this.setState({loading: true});
        this.fileUpload(this.state.new_image).then((data)=>{

          if( typeof data == 'object' ){
            if( data.data.status === 'success' ){

              notification.open({
                message: 'Berhasil',
                description:'Data telah disimpan ke database!',
                icon: <Icon type="check-circle" className="primary-color" />,
              });

              this.setState({loading: false});

              this.componentDidMount();


            }else{
              notification.open({
                  message: 'Error',
                  description: data.data.data,
                  icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
                });
                this.setState({loading: false});
            }
          }
        })
      }

      handleChange(event) {
          const target = event.target;
          const value = target.type === 'checkbox'
              ? target.checked
              : target.value;
          const name = target.name;

          this.setState({[name]: value});
      }

      onChangeFile(e) {
        this.setState({new_image:e.target.files[0]})
      }

      fileUpload(file){
        const url = api_url+'link_aplikasi/edit/'+this.state.id;
        const formData = new FormData();
        formData.append('image',file);
        formData.append('nama',this.state.nama);
        formData.append('url',this.state.url);
        formData.append('category', "HABA APP");

        const config = {
            headers: {
                'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN),
                'content-type': 'multipart/form-data'
            }
        }
        return  put(url, formData,config)
      }

    render() {

        return (
            <div>
                <div className="col-12 grid-margin">

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Haba Mobile</li>
                            <Link className="breadcrumb-item" to="/admin/aplikasi/haba_app/list">Haba APP</Link>
                            <li className="breadcrumb-item active">Edit</li>
                        </ol>
                    </nav>

                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="display-4 primary-color text-center">Edit Haba APP</h4>
                            <br />

                            {/* Form Input */}
                            <form onSubmit={this.onFormSubmit}>
                                <div className="row">


				{/** Nama Aplikasi **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Nama Aplikasi<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Nama Aplikasi" name="nama" value={this.state.nama} onChange={this.handleChange} required/>
                  </div>
                </div>


				{/** URL **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>URL<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="URL" name="url" value={this.state.url} onChange={this.handleChange} required/>
                  </div>
                </div>

        <div className="col-md-12">
            <img src={api_url+this.state.image} width="300" />
            <br/><br/>
        </div>

				{/** Gambar **/}
        <div className="col-md-12">
          <div className="form-group">
            <label>Ganti Gambar</label><br/>
            <input type="file" className="form-control-file" onChange={this.onChangeFile} />
          </div>
        </div>

			{/*##form_input##*/}

                                </div>
                                <button stype="submit" className="btn btn-full primary-button">Simpan</button>
                            </form>


                        </div>
                    </div>



                </div>

            </div>
        );
    }

}
