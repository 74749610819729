import React from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import { message, notification, Skeleton } from 'antd';
import Aform from "../../helper/Aform";

const api_url = process.env.REACT_APP_API_URL;

export default class Add extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading       : true,
      inputs        : "",
      field_name    : "",
      submit        : "",
      FormUrl       : "",
      name          : "",
      nama          : ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submodule_id  = this.props.match.params.submodule_id;
  }

  componentDidMount(){
     document.title = process.env.REACT_APP_APP_NAME+"::Tambah";
     this.load_form();
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

     this.setState({
       [name]: value
     });
  }

  handleSubmit(event) {
    event.preventDefault();

    var form_data = {};
    const data = this.state.inputs;

    if( data.length > 0 ){
      for( var x in data){
        var field = data[x].field_name;
        if(typeof this.state[field] != "undefined"){
          form_data[field] = this.state[field];
        }else{
          form_data[field] = null;
        }
      }
    }

    var header = {
      headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
    };

    axios.post( api_url+'form/add_submodule_item/'+this.submodule_id, form_data, header
    ).then(
      response => response.data,
      error => {
        alert(error)
      }
    )
    .then((data) => {
      if( data['status'] === 'success' ){

        notification.open({
          message: 'Berhasil',
          description:'Data telah disimpan ke database!',
        });

        this.props.history.push("/admin/form/list/"+this.submodule_id);

      }else{
        message.error( data['data'] );
      }

    })

  }

  load_form(){
    var header = {
      headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
    };
    axios.get( api_url+'form/get_submodule_form/'+this.submodule_id, header)
    .then(
      response => response.data,
      error => { alert( error ) }
    )
    .then((data) => {
      if( typeof data == 'object' ){
        if( data['status'] === 'success' ){
          this.setState({
            name          : data.data.name,
            field_name    : data.data.field_name,
            inputs        : data.data.inputs,
            loading       : false,
            submit        : data.data.submit,
            FormUrl       : data.data.url
          });
        }else{
          message.error( data['data'] );
        }

      }
    })
  }

  generate_form(){
    const data = this.state.inputs;
    if( data.length > 0 ){
      const res = data.map((value, index) =>
        <Aform
          type={value.type}
          name={value.field_name}
          label={value.label}
          placeholder={value.placeholder}
          value={this.state[value.field_name]}
          col={value.col}
          required={value.required}
          handleChange={this.handleChange}
          key={ value.field_name }
          />
      );
      return res;
    }
  }


  render() {

    return (
      <div>
      <Skeleton loading={this.state.loading} active>
        <div className="col-12 grid-margin">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <Link className="breadcrumb-item" to={"/admin/form/list/"+this.submodule_id}>{this.state.name}</Link>
              <li className="breadcrumb-item active">Tambah</li>
            </ol>
          </nav>
        </div>

          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
              <h4 className="display-4 primary-color text-center">Tambah {this.state.name}</h4>
              <br />

              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  {this.generate_form()}
                </div>
                <button stype="submit" className="btn btn-full primary-button">Simpan</button>
              </form>

              </div>
            </div>
          </div>
          </Skeleton>
      </div>
    );
  }

}
