import React from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import {Skeleton, message, notification, Icon, Modal} from 'antd';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Editor } from '@tinymce/tinymce-react';
import moment from 'moment';
import 'moment/locale/id';
const confirm = Modal.confirm;

const api_url = process.env.REACT_APP_API_URL;

export default class Detail extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.id,
            judul:'',
            tanggal_mulai:'',
            tanggal_selesai:'',
            deskripsi:'',
            status:'',
            pertanyaan: '',
            pertanyaan_input: '',
            jawaban: [],
            jumlah_pilihan_jawaban: 1,
            jawaban_input: [],
            opd: "",
            created_at: '',
            updated_at: '',
            loading: true,
            show_pertanyaan_form: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleTambahPertanyaan = this.handleTambahPertanyaan.bind(this);
        this.handleChangeDesc = this.handleChangeDesc.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {

        document.title = process.env.REACT_APP_APP_NAME + "::Survei";
        this.get_data();

					//##componentDidMount##//

    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        const name = target.name;

        this.setState({[name]: value});

    }

    handleChangeDesc( e ){

      this.setState({ deskripsi: e.target.getContent()});
  }

  tanggal_mulai = date => {
    this.setState({
      tanggal_mulai: date
    });
  };

  tanggal_selesai = date => {
    this.setState({
      tanggal_selesai: date
    });
  };

  get_data(){
      var header = {
        headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
      };
      axios.get( api_url+'survei/get_survei/'+this.state.id, header)
      .then(
        response => response.data,
        error => { alert( error ) }
      )
      .then((data) => {

        if( typeof data == 'object' ){
          if( data.status === 'success' ){
            this.setState({
              judul: data.data[0].judul,
              tanggal_mulai: new Date(data.data[0].tanggal_mulai),
      tanggal_selesai:new Date(data.data[0].tanggal_selesai),
      deskripsi: data.data[0].deskripsi,
              status: data.data[0].status,
              pertanyaan: data.data[0].pertanyaan,
              opd: data.data[1],
                created_at: new Date( data.data[0].created_at ),
                updated_at: new Date(data.data[0].updated_at),
              loading: false
          });
          }else{
            message.error( data.data[0].data );
          }

        }
      })
    }


    handleSubmit(event) {
        event.preventDefault();

        message.loading('Loading...', 2.5);

        var form_data =
        {
          judul:this.state.judul,
 					tanggal_mulai:this.state.tanggal_mulai,
 					tanggal_selesai:this.state.tanggal_selesai,
 					deskripsi:this.state.deskripsi,
 					status:this.state.status,
 					//##field##:##value##//
        };

        var header = {
          headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };

        axios.put( api_url+'survei/edit/'+this.state.id, form_data, header
        ).then(
          response => response.data,
          error => {
            alert(error)
          }
        )
        .then((data) => {
            if( typeof data == 'object' ){
                  if( data.status === 'success' ){

                    notification.open({
                      message: 'Berhasil',
                      description:'Data telah disimpan ke database!',
                    });

                    this.props.history.push("/admin/manajemen_survei/detail/"+this.state.id);

                  }else{
                    notification.open({
                        message: 'Error',
                        description: data.data,
                        icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
                      });
                  }
            }

        })
      }

      handleTambahPertanyaan(event){
        event.preventDefault();
        let formElements = document.getElementById("formPertanyaan").elements;

        let postData={};
        for (let i=0; i<formElements.length; i++){
            if (formElements[i].type != "submit"){
                postData[formElements[i].name]=formElements[i].value;
            }
        }

        let jawaban   = {
          a: postData["jawaban[0]"],
          b: postData["jawaban[1]"],
          c: postData["jawaban[2]"],
          d: postData["jawaban[3]"]
      }

      let form_data =
      {
        pertanyaan:postData.pertanyaan_input,
        pilihan_jawaban : [jawaban]
      };

        var header = {
          headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };

        axios.post( api_url+'survei/add_pertanyaan/'+this.state.id, form_data, header
        ).then(
          response => response.data,
          error => {
            alert(error)
          }
        )
        .then((data) => {
            if( typeof data == 'object' ){
                  if( data.status === 'success' ){

                    notification.open({
                      message: 'Berhasil',
                      description:'Pertanyaan telah ditambah!',
                    });

                    this.setState({ show_pertanyaan_form: false, jumlah_pilihan_jawaban: 1 })

                    this.componentDidMount();

                  }else{
                    notification.open({
                        message: 'Error',
                        description: data.data,
                        icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
                      });
                  }
            }

        })
      }


      form_tambah_pertanyaan(){

          let pilihan = [];
          for (let i = 0; i < this.state.jumlah_pilihan_jawaban; i++) {
            pilihan.push( <input type="text" className="form-control" key={ i } placeholder="Pilihan Jawaban" name={`jawaban[${i}]`} value={this.state.jawaban_input[i]} onChange={this.handleChange}/> );
          }

          return(
              <div>
                  <form onSubmit={this.handleTambahPertanyaan} id="formPertanyaan">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Pertanyaan<span className="text-danger">*</span></label>
                            <input type="text" className="form-control" placeholder="Pertanyaan" name="pertanyaan_input" value={this.state.pertanyaan_input} onChange={this.handleChange} required/>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Pilihan Jawaban</label>
                            {pilihan}
                        </div>
                    </div>

                    <div className="col-md-12 text-right">
                        <button type="button" className="btn btn-sm btn-danger" onClick={() => this.addFormJawaban()}><i className="fas fa-plus" /></button>
                    </div>

                    <div className="col-md-12">
                        <button type="submit" className="btn primary-button">Simpan</button>
                    </div>
                  </form>
              </div>
          );
      }

      showPertanyaanForm(e){
        this.setState({ show_pertanyaan_form: true })
      }

      addFormJawaban(e){
          if( this.state.jumlah_pilihan_jawaban < 4 ){
            this.setState({
                jumlah_pilihan_jawaban: (this.state.jumlah_pilihan_jawaban+1 )
            });
          }else{
            notification.open({
                message: 'Uppsss!',
                description: "Pilihan jawaban maksimal 4 item",
                icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
              });
          }

      }

    list_pertanyaan(){
      if( this.state.pertanyaan.length > 0 ){

        let list = this.state.pertanyaan.map(( value ) =>
          <li className="soal-suvei-list" key={ Math.random() } >
            {value.pertanyaan} <a href="javascript:undefined" className="text-danger" onClick={() => this.hapusPertanyaan(value._id)}>Hapus</a>
            {this.list_pilihan_jawaban( value.pilihan_jawaban )}
          </li>
        );

        return (<ol className="list-pertanyaan-survey">{list}</ol>);
      }
    }

    list_pilihan_jawaban( jawaban ){
      let res = [];
      for( let j in jawaban ){
        for( let k in jawaban[j] ){
          res.push( <li key={ Math.random() } >{jawaban[j][k]}</li>);
        }
      }
      return (<ol type="a" className="list-jawaban-survey">{res}</ol>);
    }

    hapusPertanyaan(pertanyaan_id){
      let id = this.state.id;
      let t = this;

      const header = {
        headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
      };
      confirm({
        title: "Apakah anda yakin ingin menghapus pertanyaan terpilih?",
        content: "Pertanyaan akan terhapus secara permanen",
        onOk() {
          axios.delete(api_url+"survei/delete_pertanyaan/"+id+"/"+pertanyaan_id, header)
           .then(
             response => {
               console.log( response );

               notification.open({
                 message: 'Berhasil',
                 description:'Pertanyaan telah terhapus!'
               });
                return t.componentDidMount()
            },
            error => {
              alert(error)
            }
          )

          ;
        }
      });
    }


    setStatus(id){
      const e     = this;
      let status  = (this.state.status == 'publish')?'draft':'publish';
      let message = (this.state.status == 'publish')?'Apakah anda ingin menjadikan draft?':'Apakah anda yakin mempublikasi survei ini?';

      let form_data =
        {
          status:status
        };

      const header = {
        headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
      };
      confirm({
        title: message,
        content: "Status survei akan berubah",
        onOk() {
          axios.put(api_url+"survei/edit/"+id, form_data, header)
           .then(
             response => {
               notification.open({
                 message: 'Berhasil',
                 description:'Status Telah Berubah!'
               });
               return e.componentDidMount()
            },
            error => {
              alert(error)
            }
          )

          ;
        }
      });
    }

    render() {
      let btn_publish = [];
      let status = [];
      if( this.state.status == 'draft' ){
        btn_publish.push(<button type="button" className="btn btn-lg btn-outline-danger btn-block" onClick={() => this.setStatus(this.state.id)}>Publish</button>);
        status.push(<span className="badge badge-warning">DRAFT</span>);
      }else{
        btn_publish.push(<button type="button" className="btn btn-lg btn-outline-secondary btn-block" onClick={() => this.setStatus(this.state.id)}>Jadikan Draft</button>);
        status.push(<span className="badge badge-info">PUBLISH</span>);
      }

        return (
          <div className="row">
          <div className="col-12 grid-margin">

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">Manajemen Survei</li>
                        <Link className="breadcrumb-item" to="/admin/manajemen_survei/list">Daftar Survei</Link>
                    <li className="breadcrumb-item active">Detail Survei</li>
                </ol>
            </nav>

          </div>


          <Skeleton loading={this.state.loading} active>
              <div className="col-md-8 grid-margin">
                  <div className="card">
                      <div className="card-body">
                        <h4 className="display-4 primary-color">{this.state.judul}</h4>
                        <br />

                        <div className="paragraph" dangerouslySetInnerHTML={{ __html: this.state.deskripsi }} />

                        <br/>
                        <button className="btn primary-button" onClick={() => this.showPertanyaanForm()} >Tambah Pertanyaan</button>
                        <br/>
                        <p className="text-right primary-color">Jumlah pertanyaan: {this.state.pertanyaan.length}</p>

                        { this.state.show_pertanyaan_form ? this.form_tambah_pertanyaan() : null }

                        {this.list_pertanyaan()}

                      </div>
                  </div>

              </div>

              <div className="col-md-4 grid-margin">
                <div className="card">
                  <div className="card-body">
                  {/* OPD */}
                  <div className="col-md-12">
                      <div className="form-group">
                          <label>Pemilik Survei:</label>
                          <label>{this.state.opd}</label>
                      </div>
                  </div>

                    {/* Tanggal */}
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Tanggal Mulai Survei</label>
                            <input type="text" className="form-control" value={ moment(this.state.tanggal_mulai).format("D MMMM YYYY") } disabled/>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Tanggal Berakhir Survei</label>
                            <input type="text" className="form-control" value={ moment(this.state.tanggal_selesai).format("D MMMM YYYY") } disabled/>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Status</label><br/>
                            {status}
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Dibuat { moment(this.state.created_at, "YYYYMMDD").fromNow() }</label> <br/>
                            <label>Diperbaharui { moment(this.state.updated_at, "YYYYMMDD").fromNow() }</label>
                        </div>

                    </div>

                    {btn_publish}

                    <hr/>
                    <Link className="btn btn-lg btn-outline-warning btn-block" to={`/admin/manajemen_survei/responden/${this.state.id}`}>Lihat Hasil Survei</Link>

                  </div>
                  </div>
              </div>
              </Skeleton>
      </div>
        );
    }

}
