import React from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import {Skeleton, message, notification, Icon} from 'antd';
import Auth from "../../../helper/Authentication";

const api_url = process.env.REACT_APP_API_URL;

export default class Add extends React.Component {
    constructor(props) {
        super(props);
        Auth.is_access( window.location.pathname )
        this.state = { kode:'',nama:'',telepon:'',jenis:'',kabupaten_id:'',alamat:'',latitude:'',longitude:'', };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {

        document.title = process.env.REACT_APP_APP_NAME + "::Bandara";
        this.get_kabupaten_id_list();
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        const name = target.name;

        this.setState({[name]: value});
    }


    handleSubmit(event) {
        event.preventDefault();

        message.loading('Loading...', 2.5);

        var form_data =
        {
          kode:this.state.kode,
 					nama:this.state.nama,
 					telepon:this.state.telepon,
 					jenis:this.state.jenis,
 					kabupaten_id:this.state.kabupaten_id,
 					alamat:this.state.alamat,
 					latitude:this.state.latitude,
 					longitude:this.state.longitude,
 					//##field##:##value##//
        };

        var header = {
          headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };

        axios.post( api_url+'bandara/add', form_data, header
        ).then(
          response => response.data,
          error => {
            alert(error)
          }
        )
        .then((data) => {
          if( data.status === 'success' ){

            notification.open({
              message: 'Berhasil',
              description:'Data telah disimpan ke database!',
            });

            this.props.history.push("/admin/master_data/bandara/list");

          }else{
            notification.open({
                message: 'Error',
                description: data.data,
                icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
              });
          }

        })
      }

      get_kabupaten_id_list(){
        axios.get(api_url+"wilayah/kabupaten/get_by_provinsi/11").then(
            response => {
              this.setState({ kab_kota_list: response.data.data});
          },
          error => {
            alert(error)
          }

        );
      }

      select_option_for_kab_kota(){
        if( this.state.kab_kota_list !== undefined && typeof this.state.kab_kota_list == "object"){
          if( this.state.kab_kota_list.length > 0 ){
            const res = (
              <select name="kabupaten_id" className="form-control" onChange={this.handleChange} required>
                <option value="" key={ Math.random() }>Pilih</option>
                {this.state.kab_kota_list.map(( value ) =>
                  <option value={value.kabupaten_id} key={ Math.random() } selected= {(value.kabupaten_id == this.state.kabupaten_id)?true:false}>{value.kabupaten}</option>
                )}
              </select>
            );

            return res;

          }
        }
      }

    render() {

        return (
            <div>
                <div className="col-12 grid-margin">

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Master Data</li>
                            <Link className="breadcrumb-item" to="/admin/master_data/bandara/list">Bandara</Link>
                            <li className="breadcrumb-item active">Tambah</li>
                        </ol>
                    </nav>

                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="display-4 primary-color text-center">Tambah Bandara</h4>
                            <br />

                            {/* Form Input */}
                            <form onSubmit={this.handleSubmit}>
                                <div className="row">


				{/** Kode Bandara **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Kode Bandara<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Kode Bandara" name="kode" value={this.state.kode} onChange={this.handleChange} required/>
                  </div>
                </div>


				{/** Nama Bandara **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Nama Bandara<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Nama Bandara" name="nama" value={this.state.nama} onChange={this.handleChange} required/>
                  </div>
                </div>


				{/** Nomor Telepon **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Nomor Telepon</label>
                    <input type="text" className="form-control" placeholder="Nomor Telepon" name="telepon" value={this.state.telepon} onChange={this.handleChange} />
                  </div>
                </div>


				{/** Jenis Bandara **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Jenis Bandara<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Jenis Bandara" name="jenis" value={this.state.jenis} onChange={this.handleChange} required/>
                  </div>
                </div>


        {/** Kabupaten **/}
        <div className="col-md-6">
          <div className="form-group">
            <label>Kabupaten/Kota<span className="text-danger">*</span></label>
              { this.select_option_for_kab_kota() }
          </div>
        </div>


				{/** Alamat **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Alamat<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Alamat" name="alamat" value={this.state.alamat} onChange={this.handleChange} required/>
                  </div>
                </div>


				{/** Latitude **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Latitude<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Latitude" name="latitude" value={this.state.latitude} onChange={this.handleChange} required/>
                  </div>
                </div>


				{/** Longitude **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Longitude<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Longitude" name="longitude" value={this.state.longitude} onChange={this.handleChange} required/>
                  </div>
                </div>

			{/*##form_input##*/}

                                </div>
                                <button stype="submit" className="btn btn-full primary-button">Simpan</button>
                            </form>


                        </div>
                    </div>



                </div>

            </div>
        );
    }

}
