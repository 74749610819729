import React from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import {Skeleton, message, notification, Icon} from 'antd';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Editor } from '@tinymce/tinymce-react';
import moment from 'moment';

const api_url = process.env.REACT_APP_API_URL;

export default class Edit extends React.Component {
    constructor(props) {
        super(props);

        this.state = { id: this.props.match.params.id, judul:'',tanggal_mulai:'',tanggal_selesai:'',deskripsi:'', opd_id: "", status:'', loading: true};

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDesc = this.handleChangeDesc.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {

        document.title = process.env.REACT_APP_APP_NAME + "::Survei";
        this.get_data();
        this.get_opd_list()
					//##componentDidMount##//

    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        const name = target.name;

        this.setState({[name]: value});
    }

    handleChangeDesc( e ){

      this.setState({ deskripsi: e.target.getContent()});
  }

  tanggal_mulai = date => {
    this.setState({
      tanggal_mulai: date
    });
  };

  tanggal_selesai = date => {
    this.setState({
      tanggal_selesai: date
    });
  };

    get_data(){
        var header = {
          headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };
        axios.get( api_url+'survei/get_survei/'+this.state.id, header)
        .then(
          response => response.data,
          error => { alert( error ) }
        )
        .then((data) => {

          if( typeof data == 'object' ){
            if( data.status === 'success' ){
              this.setState({
                judul: data.data[0].judul,
                tanggal_mulai: new Date(data.data[0].tanggal_mulai),
 					      tanggal_selesai:new Date(data.data[0].tanggal_selesai),
                opd_id: data.data[0].opd_id,
 					      deskripsi: data.data[0].deskripsi,
 					      status: data.data[0].status,
                loading: false
            });
            }else{
              message.error( data.data[0].data );
            }

          }
        })
      }


    handleSubmit(event) {
        event.preventDefault();

        message.loading('Loading...', 2.5);

        var form_data =
        {
          judul:this.state.judul,
 					tanggal_mulai:this.state.tanggal_mulai,
 					tanggal_selesai:this.state.tanggal_selesai,
 					deskripsi:this.state.deskripsi,
 					status:this.state.status,
          opd_id: this.state.opd_id
 					//##field##:##value##//
        };

        var header = {
          headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };

        axios.put( api_url+'survei/edit/'+this.state.id, form_data, header
        ).then(
          response => response.data,
          error => {
            alert(error)
          }
        )
        .then((data) => {
            if( typeof data == 'object' ){
                  if( data.status === 'success' ){

                    notification.open({
                      message: 'Berhasil',
                      description:'Data telah disimpan ke database!',
                    });

                    this.props.history.push("/admin/manajemen_survei/detail/"+this.state.id);

                  }else{
                    notification.open({
                        message: 'Error',
                        description: data.data,
                        icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
                      });
                  }
            }

        })
      }


      get_opd_list(){
        axios.get(api_url+"opd/list_skpa").then(
            response => {
              this.setState({ opd_list: response.data.data});
          },
          error => {
            alert(error)
          }

        );
      }

      select_option_for_opd(){
        if( this.state.opd_list != undefined && typeof this.state.opd_list == "object"){
          if( this.state.opd_list.length > 0 ){
            const res = (
              <select name="opd_id" className="form-control" onChange={this.handleChange} required>
                <option value="" key={ Math.random() }>Pilih</option>
                {this.state.opd_list.map(( value ) =>
                  <option value={value._id} key={ Math.random() } selected= {(value._id == this.state.opd_id)?true:false}>{value.nama_opd}</option>
                )}
              </select>
            );

            return res;

          }
        }
      }


//##cst_function##/


    render() {

        return (
          <div className="row">
          <div className="col-12 grid-margin">

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">Manajemen Survei</li>
                        <Link className="breadcrumb-item" to="/admin/manajemen_survei/list">Daftar Survei</Link>
                    <li className="breadcrumb-item active">Edit Survei</li>
                </ol>
            </nav>

          </div>


          <Skeleton loading={this.state.loading} active>
              <div className="col-md-8 grid-margin">
                  <div className="card">
                      <div className="card-body">
                        <h4 className="display-4 primary-color">Edit Survei</h4>
                        <br />

                          {/* Form Input */}
                          <form onSubmit={this.handleSubmit}>
                              <div className="row">
                                {/** Judul Survei **/}
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label>Judul Survei<span className="text-danger">*</span></label>
                                      <input type="text" className="form-control" placeholder="Judul Survei" name="judul" value={this.state.judul} onChange={this.handleChange} required/>
                                  </div>
                                </div>


                                {/** Tanggal Mulai Survei **/}
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Tanggal Mulai Survei<span className="text-danger">*</span></label><br/>
                                    <div className="datepicker-container">
                                      <DatePicker className="react-datepicker-input form-control" dateFormat="dd-MM-yyyy" selected={this.state.tanggal_mulai} onChange={date => this.tanggal_mulai(date)} />
                                    </div>
                                  </div>
                                </div>


                                {/** Tanggal Berakhir Survei **/}
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Tanggal Berakhir Survei<span className="text-danger">*</span></label><br/>
                                    <div className="datepicker-container">
                                      <DatePicker className="react-datepicker-input form-control" dateFormat="dd-MM-yyyy" selected={this.state.tanggal_selesai} onChange={date => this.tanggal_selesai(date)} />
                                    </div>
                                  </div>
                                </div>

                                {/* OPD */}
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label>Pemilik Survei<span className="text-danger">*</span></label>
                                      { this.select_option_for_opd() }
                                  </div>
                                </div>

                                {/** Deskripsi **/}
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label>Deskripsi</label>
                                    <Editor
                                      initialValue={this.state.deskripsi}
                                      apiKey={process.env.REACT_APP_TINYMCE}
                                      init={{
                                        height: 400,
                                        menubar: false,
                                        plugins: [
                                          'advlist autolink lists link image charmap print preview anchor',
                                          'searchreplace visualblocks code fullscreen',
                                          'insertdatetime media table paste code help wordcount'
                                        ],
                                        toolbar:
                                          'undo redo | formatselect | bold italic backcolor | \
                                          alignleft aligncenter alignright alignjustify | \
                                          bullist numlist outdent indent | removeformat | help'
                                      }}
                                      onChange={this.handleChangeDesc}
                                    />
                                  </div>
                                </div>

                                <button type="submit" className="btn btn-lg primary-button">Simpan </button>

                              </div>
                            </form>
                      </div>
                  </div>

              </div>

              <div className="col-md-4 grid-margin">
                <div className="card">
                  <div className="card-body">

                  </div>
                  </div>
              </div>
              </Skeleton>
      </div>
        );
    }

}
