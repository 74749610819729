import React from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import {Skeleton, message, notification, Icon} from 'antd';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const api_url = process.env.REACT_APP_API_URL;

export default class Add extends React.Component {
    constructor(props) {
        super(props);

        this.state = { judul:'', tanggal:new Date(), pesan:'', };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {

        document.title = process.env.REACT_APP_APP_NAME + "::Notifikasi";

    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        const name = target.name;

        this.setState({[name]: value});
    }

    tanggal = date => {
      this.setState({
        tanggal: date
      });
    };


    handleSubmit(event) {
        event.preventDefault();

        message.loading('Loading...', 2.5);

        var form_data =
        {
          judul:this.state.judul,
 					tanggal:this.state.tanggal,
 					pesan:this.state.pesan,
 					//##field##:##value##//
        };

        var header = {
          headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };

        axios.post( api_url+'notifikasi/add', form_data, header
        ).then(
          response => response.data,
          error => {
            alert(error)
          }
        )
        .then((data) => {
          if( data.status === 'success' ){

            notification.open({
              message: 'Berhasil',
              description:'Data telah disimpan ke database!',
            });

            this.props.history.push("/admin/aplikasi/notifikasi/list");

          }else{
            notification.open({
                message: 'Error',
                description: data.data,
                icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
              });
          }

        })
      }

    render() {

        return (
            <div>
                <div className="col-12 grid-margin">

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Haba Mobile</li>
                            <Link className="breadcrumb-item" to="/admin/aplikasi/notifikasi/list">Notifikasi</Link>
                            <li className="breadcrumb-item active">Tambah</li>
                        </ol>
                    </nav>

                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="display-4 primary-color text-center">Tambah Notifikasi</h4>
                            <br />

                            {/* Form Input */}
                            <form onSubmit={this.handleSubmit}>
                                <div className="row">


				{/** Judul **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Judul<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Judul" name="judul" value={this.state.judul} onChange={this.handleChange} required/>
                  </div>
                </div>


      {/** Tanggal **/}
      <div className="col-md-6">
        <div className="form-group">
          <label>Tanggal<span className="text-danger">*</span></label><br/>
          <div className="datepicker-container">
            <DatePicker className="react-datepicker-input form-control" dateFormat="dd-MM-yyyy" selected={this.state.tanggal} onChange={date => this.tanggal(date)} />
          </div>
        </div>
      </div>


				{/** Pesan **/}
				<div className="col-md-12">
                  <div className="form-group">
                    <label>Pesan<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Pesan" name="pesan" value={this.state.pesan} onChange={this.handleChange} required/>
                  </div>
                </div>

			{/*##form_input##*/}

                                </div>
                                <button stype="submit" className="btn btn-full primary-button">Simpan</button>
                            </form>


                        </div>
                    </div>



                </div>

            </div>
        );
    }

}
