import React from 'react';
import { Link } from "react-router-dom";
import Atable from "../../lib/Atable";
import axios from "axios";
import { notification, Modal, Icon, Skeleton } from 'antd';
import ReactHtmlParser from 'react-html-parser';

const api_url       = process.env.REACT_APP_API_URL;
const confirm       = Modal.confirm;

export default class List extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      total_record  : "",
      total_filter  : "",
      limit         : 10,
      active_page   : 1,
      search        : "",
      table_data    : "",
      loading       : true,
      name          : "",
      inputs        : ""
    };

    this.submodule_id   = this.props.match.params.submodule_id;
    this.handleChange   = this.handleChange.bind(this);

  }

  componentDidMount(){
     document.title = process.env.REACT_APP_APP_NAME+"::Form";
     this.load_datatable();
     this.load_form();
  }

   load_form(){
     var header = {
       headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
     };
     axios.get( api_url+'form/get_submodule_form/'+this.submodule_id, header)
     .then(
       response => response.data,
       error => { alert( error ) }
     )
     .then((data) => {
       if( typeof data == 'object' ){
         if( data['status'] === 'success' ){
           this.setState({
             inputs        : data.data.inputs,
             name          : data.data.name
           });
         }else{
           notification.open({
             message: 'API Error',
             description: data['data'],
             icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
           });
         }

       }
     })
   }

   load_datatable(){
     var header = {
       headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
     };
     axios.get( api_url+'form/get_submodule_items_datatable/'+this.submodule_id, header)
     .then(
       response => response.data,
       error => { alert( error ) }
     ).then((data) => {
       if( typeof data == 'object' ){
         this.setState({
           total_record: data.recordsTotal,
           table_data: data.data,
           loading: false
         });
       }
     })
   }

   handleChange(event) {
     const target = event.target;
     const value = target.type === 'checkbox' ? target.checked : target.value;
     const name = target.name;
      this.setState(
        { [name]: value }, () => {
          this.load_datatable();
      })
   }

   deleteItem(itemID){
     const e               = this;
     const header = {
       headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
     };
     confirm({
       title: "Apakah anda ingin menghapus data?",
       content: "",
       onOk() {
         axios.delete(api_url+"form/delete_submodule_item/"+e.submodule_id+"/"+itemID, header)
          .then(
           response => response.data,
           error => {
             alert(error)
           }
         )
         .then((data) => {
           if( typeof data == 'object' ){
             if( data['status'] === 'success' ){
               notification.open({
                   message: 'Berhasil',
                   description:'Data telah berhasil dihapus!'
                 });
                 return e.componentDidMount()
             }
           }
         });
       }
     });
   }

  render() {
    // Table head
    const form_data = this.state.inputs;
    let thead = [];
    for( var input in form_data ){
      // regex
      let datatable = form_data[input].datatable;
      let show = datatable.match(/show/i);
      if( show !== null ){
        if( show[0] === "show" ){
          thead.push([form_data[input].label]);
        }
      }

    }
    thead.push(["Aksi"]);

    // Table data
    const data      = this.state.table_data;

    var table_data  = [];
    for( var t in data ){

      // open tag for <tr>
      table_data.push(ReactHtmlParser('<tr>'));

      // dynamic table columns
      for( var td in data[t] ){
          if( td !== "id" ){
            table_data.push(<td key={Math.random()}>{data[t][td]}</td>);
          }
      }

      let id = data[t]['id'];

      // Action column
      table_data.push(
        <td key={Math.random()}>
          <Link className="btn secondary-button btn-sm" to={"/admin/form/edit/"+this.submodule_id+"/"+data[t]['id']}>
            <i className="fas fa-edit"></i>
          </Link>
          <button onClick={() => this.deleteItem(id)} className="btn btn-danger btn-sm">
            <i className="fas fa-trash"></i>
          </button>
        </td>);

      //close tag for </tr>
      table_data.push(ReactHtmlParser('</tr>'));
    }

    // modulus array
    let arr = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    let res = [];

    // for( var x in arr ){
    //   var modulus = x % 3;
    //
    //   if( modulus ){
    //     res[x] = "<td>"+x+"</td>";
    //   }else{
    //     res[x] = "<tr>";
    //   }
    //
    //
    // }
    // console.log( modulus );
    // console.log( res );

    // var arr = [];
    // arr[0] = "Jani";
    // arr[1] = "Hege";
    // arr[2] = "Stale";
    // arr[3] = "Kai Jim";
    // arr[4] = "Borge";
    //
    // console.log(arr.join());
    // arr.splice(2, 0, "Lene");
    // console.log(arr.join());


    return (
      <div>
        <Skeleton loading={this.state.loading} active>
        {/* Breadcrumb */}
        <div className="col-12 grid-margin">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item active">{this.state.name}</li>
            </ol>
          </nav>
        </div>

        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <h4 className="display-4 primary-color text-center">{this.state.name}</h4>
              <br />
              <Link className="btn primary-button" to={"/admin/form/add/"+this.submodule_id}>
                Tambah
              </Link>
              <hr className="divider soft" />

              <Atable
                head={ thead }
                data={ table_data }
                total_filter={ this.state.total_filter }
                total_record={ this.state.total_record }
                current_page={this.state.active_page}
                search={this.state.search}
                handleChange={this.handleChange}
                limit={this.state.limit}
              />

            </div>
          </div>
        </div>
        </Skeleton>
      </div>
    );
  }

}
