import React from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import {Skeleton, message, notification, Icon} from 'antd';

const api_url = process.env.REACT_APP_API_URL;

export default class Add extends React.Component {
    constructor(props) {
        super(props);

        this.state = { masjid_id:'',nama_masjid:'',alamat:'',kab_kota:"",kecamatan:"",tipologi:'',luas_tanah:'',latitude:'',longitude:'', };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {

        document.title = process.env.REACT_APP_APP_NAME + "::Masjid";

        this.get_kabupaten_id_list();
					//##componentDidMount##//

    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        const name = target.name;

        this.setState({[name]: value}, () =>{
          this.get_kecamatan_id_list();
        });
    }


    handleSubmit(event) {
        event.preventDefault();

        message.loading('Loading...', 2.5);

        var form_data =
        {
          masjid_id:this.state.masjid_id,
 					nama_masjid:this.state.nama_masjid,
 					alamat:this.state.alamat,
 					kab_kota:this.state.kab_kota,
 					kecamatan:this.state.kecamatan,
 					tipologi:this.state.tipologi,
 					luas_tanah:this.state.luas_tanah,
 					latitude:this.state.latitude,
 					longitude:this.state.longitude,
 					//##field##:##value##//
        };

        var header = {
          headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };

        axios.post( api_url+'masjid//add', form_data, header
        ).then(
          response => response.data,
          error => {
            alert(error)
          }
        )
        .then((data) => {
          if( data.status === 'success' ){

            notification.open({
              message: 'Berhasil',
              description:'Data telah disimpan ke database!',
            });

            this.props.history.push("/admin/master_data/masjid/list");

          }else{
            notification.open({
                message: 'Error',
                description: data.data,
                icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
              });
          }

        })
      }


					get_kab_kota_list(){
		        axios.get(api_url+"wilayah/kabupaten/get_by_provinsi/11").then(
		            response => {
		              this.setState({ kab_kota_list: response.data.data});
		          },
		          error => {
		            alert(error)
		          }

		        );
		      }

					select_option_for_kab_kota(){
		        if( this.state.kab_kota_list !== undefined && typeof this.state.kab_kota_list == "object"){
		          if( this.state.kab_kota_list.length > 0 ){
		            const res = (
		              <select name="kab_kota" className="form-control" onChange={this.handleChange} required>
                    <option value="" key={ Math.random() }>Pilih</option>
		                {this.state.kab_kota_list.map(( value ) =>
		                  <option value={value.kab_kota} key={ Math.random() } selected= {(value.kab_kota == this.state.kab_kota)?true:false}>{value.kabupaten}</option>
		                )}
		              </select>
		            );

		            return res;

		          }
		        }
		      }




          get_kecamatan_id_list(){
            if( this.state.kabupaten_id ){

              axios.get(api_url+"wilayah/kecamatan/get_by_kabupaten/"+this.state.kabupaten_id).then(
  		            response => {
  		              this.setState({ kecamatan_id_list: response.data.data});
  		          },
  		          error => {
  		            alert(error)
  		          }

  		        );
            }

		      }

					select_option_for_kecamatan_id(){
		        if( this.state.kecamatan_id_list !== undefined && typeof this.state.kecamatan_id_list == "object"){
		          if( this.state.kecamatan_id_list.length > 0 ){
		            const res = (
		              <select name="kecamatan_id" className="form-control" onChange={this.handleChange} required>
                    <option value="" key={ Math.random() }>Pilih</option>
                    {this.state.kecamatan_id_list.map(( value ) =>
		                  <option value={value.kecamatan_id} key={ Math.random() } selected= {(value.kecamatan_id == this.state.kecamatan_id)?true:false}>{value.kecamatan}</option>
		                )}
		              </select>
		            );

		            return res;

		          }
		        }
		      }

					get_kabupaten_id_list(){
		        axios.get(api_url+"wilayah/kabupaten/get_by_provinsi/11").then(
		            response => {
		              this.setState({ kabupaten_id_list: response.data.data});
		          },
		          error => {
		            alert(error)
		          }

		        );
		      }

					select_option_for_kabupaten_id(){
		        if( this.state.kabupaten_id_list !== undefined && typeof this.state.kabupaten_id_list == "object"){
		          if( this.state.kabupaten_id_list.length > 0 ){
		            const res = (
		              <select name="kabupaten_id" className="form-control" onChange={this.handleChange} required>
                    <option value="" key={ Math.random() }>Pilih</option>
                    {this.state.kabupaten_id_list.map(( value ) =>
		                  <option value={value.kabupaten_id} key={ Math.random() } selected= {(value.kabupaten_id == this.state.kabupaten_id)?true:false}>{value.kabupaten}</option>
		                )}
		              </select>
		            );

		            return res;

		          }
		        }
          }


          get_kecamatan_id_list(){
            if( this.state.kabupaten_id ){
  		        axios.get(api_url+"wilayah/kecamatan/get_by_kabupaten/"+this.state.kabupaten_id).then(
  		            response => {
  		              this.setState({ kecamatan_id_list: response.data.data});
  		          },
  		          error => {
  		            alert(error)
  		          }

  		        );
            }
		      }

					select_option_for_kecamatan_id(){
		        if( this.state.kecamatan_id_list !== undefined && typeof this.state.kecamatan_id_list == "object"){
		          if( this.state.kecamatan_id_list.length > 0 ){
		            const res = (
		              <select name="kecamatan_id" className="form-control" onChange={this.handleChange} required>
		                {this.state.kecamatan_id_list.map(( value ) =>
		                  <option value={value.kecamatan_id} key={ Math.random() } selected= {(value.kecamatan_id == this.state.kecamatan_id)?true:false}>{value.kecamatan}</option>
		                )}
		              </select>
		            );

		            return res;

		          }
		        }
		      }

//##cst_function##/

    render() {

        return (
            <div>
                <div className="col-12 grid-margin">

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Master Data</li>
                            <Link className="breadcrumb-item" to="/admin/master_data/masjid/list">Masjid</Link>
                            <li className="breadcrumb-item active">Tambah</li>
                        </ol>
                    </nav>

                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="display-4 primary-color text-center">Tambah Masjid</h4>
                            <br />

                            {/* Form Input */}
                            <form onSubmit={this.handleSubmit}>
                                <div className="row">

                                {/** Kabupaten **/}
                      					<div className="col-md-6">
                                  <div className="form-group">
                                    <label>Kabupaten/Kota<span className="text-danger">*</span></label>
                      								{ this.select_option_for_kabupaten_id() }
                      						</div>
                                </div>


                      				{/** Kecamatan **/}
                              <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Kecamatan<span className="text-danger">*</span></label>
                      								{ this.select_option_for_kecamatan_id() }
                      						</div>
                                </div>

                      				{/** Kode Masjid **/}
                      				<div className="col-md-12">
                                        <div className="form-group">
                                          <label>Kode Masjid<span className="text-danger">*</span></label>
                                          <input type="text" className="form-control" placeholder="Kode Masjid" name="masjid_id" value={this.state.masjid_id} onChange={this.handleChange} required/>
                                        </div>
                                      </div>


                      				{/** Nama Masjid **/}
                      				<div className="col-md-12">
                                        <div className="form-group">
                                          <label>Nama Masjid<span className="text-danger">*</span></label>
                                          <input type="text" className="form-control" placeholder="Nama Masjid" name="nama_masjid" value={this.state.nama_masjid} onChange={this.handleChange} required/>
                                        </div>
                                      </div>


                      				{/** Alamat **/}
                      				<div className="col-md-12">
                                        <div className="form-group">
                                          <label>Alamat<span className="text-danger">*</span></label>
                                          <input type="text" className="form-control" placeholder="Alamat" name="alamat" value={this.state.alamat} onChange={this.handleChange} required/>
                                        </div>
                                      </div>



                      				{/** Tipologi **/}
                      				<div className="col-md-6">
                                        <div className="form-group">
                                          <label>Tipologi<span className="text-danger">*</span></label>
                                          <input type="text" className="form-control" placeholder="Tipologi" name="tipologi" value={this.state.tipologi} onChange={this.handleChange} required/>
                                        </div>
                                      </div>


                      				{/** Luas Tanah **/}
                      				<div className="col-md-6">
                                        <div className="form-group">
                                          <label>Luas Tanah</label>
                                          <input type="text" className="form-control" placeholder="Luas Tanah" name="luas_tanah" value={this.state.luas_tanah} onChange={this.handleChange} />
                                        </div>
                                      </div>


                      				{/** Latitude **/}
                      				<div className="col-md-6">
                                        <div className="form-group">
                                          <label>Latitude<span className="text-danger">*</span></label>
                                          <input type="text" className="form-control" placeholder="Latitude" name="latitude" value={this.state.latitude} onChange={this.handleChange} required/>
                                        </div>
                                      </div>


                      				{/** Longitude **/}
                      				<div className="col-md-6">
                                        <div className="form-group">
                                          <label>Longitude<span className="text-danger">*</span></label>
                                          <input type="text" className="form-control" placeholder="Longitude" name="longitude" value={this.state.longitude} onChange={this.handleChange} required/>
                                        </div>
                                      </div>

                      			{/*##form_input##*/}

                                </div>
                                <button stype="submit" className="btn btn-full primary-button">Simpan</button>
                            </form>


                        </div>
                    </div>



                </div>

            </div>
        );
    }

}
