import React from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import {Skeleton, message, notification, Icon} from 'antd';

const api_url = process.env.REACT_APP_API_URL;

export default class Edit extends React.Component {
    constructor(props) {
        super(props);

        this.state = { id: this.props.match.params.id, nama:'',satuan:'',kabupaten_id:'',pasar:'',tanggal:'',harga:'', };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {

        document.title = process.env.REACT_APP_APP_NAME + "::Harga Pangan";
        this.get_data();
        
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        const name = target.name;

        this.setState({[name]: value});
    }

    get_data(){
        var header = {
          headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };
        axios.get( api_url+'harga_pangan/get/'+this.state.id, header)
        .then(
          response => response.data,
          error => { alert( error ) }
        )
        .then((data) => {

          if( typeof data == 'object' ){
            if( data.status === 'success' ){
              this.setState({ 
                nama: data.data.nama, 
 					satuan: data.data.satuan, 
 					kabupaten_id: data.data.kabupaten_id, 
 					pasar: data.data.pasar, 
 					tanggal: data.data.tanggal, 
 					harga: data.data.harga, 
 					//##state_field##:##state_value##//       
                loading: false
            });
            }else{
              message.error( data.data.data );
            }

          }
        })
      }


    handleSubmit(event) {
        event.preventDefault();

        message.loading('Loading...', 2.5);

        var form_data =
        {
          nama:this.state.nama,
 					satuan:this.state.satuan,
 					kabupaten_id:this.state.kabupaten_id,
 					pasar:this.state.pasar,
 					tanggal:this.state.tanggal,
 					harga:this.state.harga,
 					//##field##:##value##//
        };

        var header = {
          headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };

        axios.put( api_url+'harga_pangan/edit/'+this.state.id, form_data, header
        ).then(
          response => response.data,
          error => {
            alert(error)
          }
        )
        .then((data) => {
            if( typeof data == 'object' ){
                  if( data.status === 'success' ){

                    notification.open({
                      message: 'Berhasil',
                      description:'Data telah disimpan ke database!',
                    });

                    this.props.history.push("/admin/master_data/harga_pangan/list");

                  }else{
                    notification.open({
                        message: 'Error',
                        description: data.data,
                        icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
                      });
                  }
            }

        })
      }

    render() {
            
        return (
            <div>
                <div className="col-12 grid-margin">

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Master Data</li>
                            <Link className="breadcrumb-item" to="/admin/master_data/harga_pangan/list">Harga Pangan</Link>
                            <li className="breadcrumb-item active">Edit</li>
                        </ol>
                    </nav>

                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="display-4 primary-color text-center">Edit Harga Pangan</h4>
                            <br />

                            {/* Form Input */}
                            <form onSubmit={this.handleSubmit}>
                                <div className="row">

                                  
				{/** Nama MaBahan Pokok **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Nama MaBahan Pokok<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Nama MaBahan Pokok" name="nama" value={this.state.nama} onChange={this.handleChange} required/>
                  </div>
                </div>

			
				{/** Satuan **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Satuan<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Satuan" name="satuan" value={this.state.satuan} onChange={this.handleChange} required/>
                  </div>
                </div>

			
				{/** Kabupaten/Kota ID **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Kabupaten/Kota ID<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Kabupaten/Kota ID" name="kabupaten_id" value={this.state.kabupaten_id} onChange={this.handleChange} required/>
                  </div>
                </div>

			
				{/** Pasar **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Pasar<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Pasar" name="pasar" value={this.state.pasar} onChange={this.handleChange} required/>
                  </div>
                </div>

			
				{/** Tanggal **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Tanggal<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Tanggal" name="tanggal" value={this.state.tanggal} onChange={this.handleChange} required/>
                  </div>
                </div>

			
				{/** Harga **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Harga<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Harga" name="harga" value={this.state.harga} onChange={this.handleChange} required/>
                  </div>
                </div>

			{/*##form_input##*/}

                                </div>
                                <button stype="submit" className="btn btn-full primary-button">Simpan</button>
                            </form>


                        </div>
                    </div>

                    

                </div>

            </div>
        );
    }

}
