import React from 'react';
import {Link} from "react-router-dom";
import axios, {put} from "axios";
import {Skeleton, message, notification, Icon} from 'antd';

const api_url = process.env.REACT_APP_API_URL;

export default class Edit extends React.Component {
    constructor(props) {
        super(props);

        this.state = { id: this.props.match.params.id, kabupaten_id:'',nama_wisata:'',alamat_wisata:'',latitude:'',longitude:'', image: null, new_image: null, loading: true};

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeFile = this.onChangeFile.bind(this);
        this.fileUpload = this.fileUpload.bind(this);
    }

    componentDidMount() {

        document.title = process.env.REACT_APP_APP_NAME + "::Tempat Wisata";
        this.get_data();

        this.get_kabupaten_id_list();
					//##componentDidMount##//

    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        const name = target.name;

        this.setState({[name]: value});
    }

    onChangeFile(e) {
      this.setState({new_image:e.target.files[0]})
    }

    get_data(){
        var header = {
          headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };
        axios.get( api_url+'wisata/get/'+this.state.id, header)
        .then(
          response => response.data,
          error => { alert( error ) }
        )
        .then((data) => {

          if( typeof data == 'object' ){
            if( data.status === 'success' ){
              this.setState({
                kabupaten_id: data.data.kabupaten_id,
 					nama_wisata: data.data.nama_wisata,
 					alamat_wisata: data.data.alamat_wisata,
          image: data.data.image,
 					latitude: data.data.location.coordinates[1],
 					longitude: data.data.location.coordinates[0],
 					//##state_field##:##state_value##//
                loading: false
            });
            }else{
              message.error( data.data.data );
            }

          }
        })
      }


    handleSubmit(event) {
        event.preventDefault();
        this.setState({loading: true});
        this.fileUpload(this.state.new_image).then((data)=>{

          if( typeof data == 'object' ){
            if( data.data.status === 'success' ){

              notification.open({
                message: 'Berhasil',
                description:'Data telah disimpan ke database!',
                icon: <Icon type="check-circle" className="primary-color" />,
              });

              this.setState({loading: false});

              this.componentDidMount();


            }else{
              notification.open({
                  message: 'Error',
                  description: data.data.data,
                  icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
                });
                this.setState({loading: false});
            }
          }
        })

        // message.loading('Loading...', 2.5);
        //
        // var form_data =
        // {
        //   kabupaten_id:this.state.kabupaten_id,
 				// 	nama_wisata:this.state.nama_wisata,
 				// 	alamat_wisata:this.state.alamat_wisata,
 				// 	latitude:this.state.latitude,
 				// 	longitude:this.state.longitude,
 				// 	//##field##:##value##//
        // };
        //
        // var header = {
        //   headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        // };
        //
        // axios.put( api_url+'wisata/edit/'+this.state.id, form_data, header
        // ).then(
        //   response => response.data,
        //   error => {
        //     alert(error)
        //   }
        // )
        // .then((data) => {
        //     if( typeof data == 'object' ){
        //           if( data.status === 'success' ){
        //
        //             notification.open({
        //               message: 'Berhasil',
        //               description:'Data telah disimpan ke database!',
        //             });
        //
        //             this.props.history.push("/admin/master_data/wisata/list");
        //
        //           }else{
        //             notification.open({
        //                 message: 'Error',
        //                 description: data.data,
        //                 icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
        //               });
        //           }
        //     }
        //
        // })
      }

      fileUpload(file){
        const url = api_url+'wisata/edit/'+this.state.id;
        const formData = new FormData();
        formData.append('image',file);
        formData.append('nama_wisata',this.state.nama_wisata);
        formData.append('kabupaten_id',this.state.kabupaten_id);
        formData.append('alamat_wisata',this.state.alamat_wisata);
        formData.append('latitude',this.state.latitude);
        formData.append('longitude',this.state.longitude);

        const config = {
            headers: {
                'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN),
                'content-type': 'multipart/form-data'
            }
        }
        return  put(url, formData,config)
      }


					get_kabupaten_id_list(){
		        axios.get(api_url+"wilayah/kabupaten/get_by_provinsi/11").then(
		            response => {
		              this.setState({ kabupaten_id_list: response.data.data});
		          },
		          error => {
		            alert(error)
		          }

		        );
		      }

					select_option_for_kabupaten_id(){
		        if( this.state.kabupaten_id_list !== undefined && typeof this.state.kabupaten_id_list == "object"){
		          if( this.state.kabupaten_id_list.length > 0 ){
		            const res = (
		              <select name="kabupaten_id" className="form-control" onChange={this.handleChange} required>
		                {this.state.kabupaten_id_list.map(( value ) =>
		                  <option value={value.kabupaten_id} key={ Math.random() } selected= {(value.kabupaten_id == this.state.kabupaten_id)?true:false}>{value.kabupaten}</option>
		                )}
		              </select>
		            );

		            return res;

		          }
		        }
		      }


//##cst_function##/


    render() {

        return (
            <div>
                <div className="col-12 grid-margin">

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Master Data</li>
                            <Link className="breadcrumb-item" to="/admin/master_data/wisata/list">Tempat Wisata</Link>
                            <li className="breadcrumb-item active">Edit</li>
                        </ol>
                    </nav>

                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="display-4 primary-color text-center">Edit Tempat Wisata</h4>
                            <br />

                            {/* Form Input */}
                            <Skeleton loading={this.state.loading} active>
                            <form onSubmit={this.handleSubmit}>
                                <div className="row">


					{/** Kabupaten **/}
					<div className="col-md-12">
            <div className="form-group">
              <label>Kabupaten/Kota<span className="text-danger">*</span></label>
								{ this.select_option_for_kabupaten_id() }
						</div>
          </div>


				{/** Nama Tempat **/}
				<div className="col-md-12">
                  <div className="form-group">
                    <label>Nama Tempat<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Nama Tempat" name="nama_wisata" value={this.state.nama_wisata} onChange={this.handleChange} required/>
                  </div>
                </div>


				{/** Alamat **/}
				<div className="col-md-12">
                  <div className="form-group">
                    <label>Alamat<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Alamat" name="alamat_wisata" value={this.state.alamat_wisata} onChange={this.handleChange} required/>
                  </div>
                </div>


				{/** Latitude **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Latitude<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Latitude" name="latitude" value={this.state.latitude} onChange={this.handleChange} required/>
                  </div>
                </div>


				{/** Longitude **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Longitude<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Longitude" name="longitude" value={this.state.longitude} onChange={this.handleChange} required/>
                  </div>
                </div>

        <div className="col-md-6">
          <div className="form-group">
            <label>Ganti Gambar</label><br/>
            <input type="file" className="form-control-file" onChange={this.onChangeFile} />
          </div>
        </div>

			{/*##form_input##*/}
      <div className="col-md-4">
          <img src={api_url+this.state.image} className="w-100"/>
      </div>

                                </div>
                                <button stype="submit" className="btn btn-full primary-button">Simpan</button>
                            </form>
                            </Skeleton>


                        </div>
                    </div>

                </div>

            </div>
        );
    }

}
