import React from 'react';
import {Link} from "react-router-dom";
import axios, {post} from "axios";
import {Skeleton, message, notification, Icon} from 'antd';

const api_url = process.env.REACT_APP_API_URL;

export default class Add extends React.Component {
    constructor(props) {
        super(props);

        this.state = { nama:'',url:'',image:'', };

        this.onFormSubmit = this.onFormSubmit.bind(this)
        this.onChangeFile = this.onChangeFile.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.fileUpload = this.fileUpload.bind(this)
    }

    componentDidMount() {

        document.title = process.env.REACT_APP_APP_NAME + "::Haba Collaboration";

    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        const name = target.name;

        this.setState({[name]: value});
    }

    onChangeFile(e) {
      this.setState({logo:e.target.files[0]})
    }

    onFormSubmit(e){
      e.preventDefault();
      this.fileUpload(this.state.logo).then((data)=>{

        if( typeof data == 'object' ){
          if( data.data.status === 'success' ){

            notification.open({
              message: 'Berhasil',
              description:'Data telah disimpan ke database!',
            });

            this.setState({loading: false});

            this.componentDidMount();
            this.props.history.push("/admin/aplikasi/haba_collaboration/list");

          }else{
            notification.open({
                message: 'Error',
                description: data.data.data,
                icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
              });
              this.setState({loading: false});
          }
        }
      })
    }

    fileUpload(file){
      const url = api_url+'link_aplikasi/add';
      const formData = new FormData();
      formData.append('image',file);
      formData.append('nama',this.state.nama);
      formData.append('url',this.state.url);
      formData.append('category', "HABA COLLABORATION");

      const config = {
          headers: {
              'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN),
              'content-type': 'multipart/form-data'
          }
      }
      return  post(url, formData,config)
    }


    render() {

        return (
            <div>
                <div className="col-12 grid-margin">

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Haba Mobile</li>
                            <Link className="breadcrumb-item" to="/admin/aplikasi/haba_collaboration/list">Haba Collaboration</Link>
                            <li className="breadcrumb-item active">Tambah</li>
                        </ol>
                    </nav>

                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="display-4 primary-color text-center">Tambah Haba Collaboration</h4>
                            <br />

                            {/* Form Input */}
                            <form onSubmit={this.onFormSubmit}>
                                <div className="row">


				{/** Nama Aplikasi **/}
				<div className="col-md-6">
          <div className="form-group">
            <label>Nama Aplikasi<span className="text-danger">*</span></label>
            <input type="text" className="form-control" placeholder="Nama Aplikasi" name="nama" value={this.state.nama} onChange={this.handleChange} required/>
          </div>
        </div>


				{/** URL **/}
				<div className="col-md-6">
          <div className="form-group">
            <label>URL<span className="text-danger">*</span></label>
            <input type="text" className="form-control" placeholder="URL" name="url" value={this.state.url} onChange={this.handleChange} required/>
          </div>
        </div>

        <div className="col-md-6">

            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="web"/>
              <label className="form-check-label"><i class="fas fa-globe fa-2x"></i></label>
            </div>

            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="android"/>
              <label className="form-check-label"><i className="fab fa-android fa-2x"></i></label>
            </div>

            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="ios"/>
              <label className="form-check-label"><i className="fab fa-apple fa-2x"></i></label>
            </div>


        </div>





      {/** Upload Gambar  **/}
      <div className="col-md-6">
        <div className="form-group">
          <label>Upload Gambar<span className="text-danger">*</span></label><br/>
          <input type="file" className="form-control-file" onChange={this.onChangeFile} />
        </div>
      </div>

			{/*##form_input##*/}

                                </div>
                                <button stype="submit" className="btn btn-full primary-button">Simpan</button>
                            </form>


                        </div>
                    </div>



                </div>

            </div>
        );
    }

}
