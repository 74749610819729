import React from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import {Skeleton, message, notification, Icon, Modal} from 'antd';
// import { Page, Text, View, Document, StyleSheet, PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import ReactPDF, {Page, Text, View, Document, StyleSheet, PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';

import Survei_doc from "./Document_object";

import moment from 'moment';
import 'moment/locale/id';

const api_url = process.env.REACT_APP_API_URL;

export default class Print extends React.Component {
  constructor(props) {
      super(props);

      this.state = {
          id: this.props.match.params.id
      };


  }
  componentDidMount() {
    this.get_data()
  }


  get_data(){
      let header = {
        headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
      };
      axios.get( api_url+'survei/responden/'+this.state.id, header)
      .then(
        response => response.data,
        error => { alert( error ) }
      )
      .then((data) => {

        if( typeof data == 'object' ){
          if( data.status === 'success' ){
            this.setState({
              data: data.data[0],
              loading: false
          });
          }else{
            message.error( data.data[0].data );
          }

        }
      })
    }

  onDocumentLoad = ({ numPages }) => {
    alert("Hulaa")
  }


  render() {
    return (
      <div>
      <div className="col-12 grid-margin">

        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                <li className="breadcrumb-item">Manajemen Survei</li>
                <Link className="breadcrumb-item" to="/admin/manajemen_survei/list">Daftar Survei</Link>
                <Link className="breadcrumb-item" to={`/admin/manajemen_survei/detail/${this.state.id}`}>Detail Survei</Link>
                <Link className="breadcrumb-item" to={`/admin/manajemen_survei/responden/${this.state.id}`}>Hasil</Link>
                <li className="breadcrumb-item active">Cetak</li>
            </ol>
        </nav>



          {/*
        <PDFDownloadLink className="btn btn-danger" document={<Survei_doc id={this.state.id}/>} fileName="hasil_survei.pdf">
          {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download')}
        </PDFDownloadLink>


        <PDFViewer width="100%" height="500">
          <Survei_doc id={this.state.id}/>
        </PDFViewer>
        */}

        <PDFViewer width="100%" height="500" onLoadSuccess={this.onDocumentLoad}>
          <Survei_doc id={this.state.id} data={this.state.data}/>
        </PDFViewer>


      </div>
      </div>
    );
  }

}
