import React from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import {Skeleton, message, notification, Icon} from 'antd';

const api_url = process.env.REACT_APP_API_URL;

export default class Edit extends React.Component {
    constructor(props) {
        super(props);

        this.state = { id: this.props.match.params.id, provinsi_id:'',provinsi:'', };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {

        document.title = process.env.REACT_APP_APP_NAME + "::Provinsi";
        this.get_data();

        //##componentDidMount##//

    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        const name = target.name;

        this.setState({[name]: value});
    }

    get_data(){
        var header = {
          headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };
        axios.get( api_url+'wilayah/provinsi/get/'+this.state.id, header)
        .then(
          response => response.data,
          error => { alert( error ) }
        )
        .then((data) => {

          if( typeof data == 'object' ){
            if( data.status === 'success' ){
              this.setState({
                provinsi_id: data.data.provinsi_id, 
 					provinsi: data.data.provinsi, 
 					//##state_field##:##state_value##//  
                loading: false
            });
            }else{
              message.error( data.data.data );
            }

          }
        })
      }


    handleSubmit(event) {
        event.preventDefault();

        message.loading('Loading...', 2.5);

        var form_data =
        {
          provinsi_id:this.state.provinsi_id,
 					provinsi:this.state.provinsi,
 					//##field##:##value##//
        };

        var header = {
          headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };

        axios.put( api_url+'wilayah/provinsi/edit/'+this.state.id, form_data, header
        ).then(
          response => response.data,
          error => {
            alert(error)
          }
        )
        .then((data) => {
            if( typeof data == 'object' ){
                  if( data.status === 'success' ){

                    notification.open({
                      message: 'Berhasil',
                      description:'Data telah disimpan ke database!',
                    });

                    this.props.history.push("/admin/data_wilayah/provinsi/list");

                  }else{
                    notification.open({
                        message: 'Error',
                        description: data.data,
                        icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
                      });
                  }
            }

        })
      }

    //##cst_function##/


    render() {

        return (
            <div>
                <div className="col-12 grid-margin">

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Data Wilayah</li>
                            <Link className="breadcrumb-item" to="/admin/data_wilayah/provinsi/list">Provinsi</Link>
                            <li className="breadcrumb-item active">Edit</li>
                        </ol>
                    </nav>

                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="display-4 primary-color text-center">Edit Provinsi</h4>
                            <br />

                            {/* Form Input */}
                            <form onSubmit={this.handleSubmit}>
                                <div className="row">

                                  
				{/** Kode Provinsi **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Kode Provinsi<span className="text-danger">*</span></label>
                    <input type="number" className="form-control" placeholder="Kode Provinsi" name="provinsi_id" value={this.state.provinsi_id} onChange={this.handleChange} required/>
                  </div>
                </div>

			
				{/** Nama Provinsi **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Nama Provinsi<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Nama Provinsi" name="provinsi" value={this.state.provinsi} onChange={this.handleChange} required/>
                  </div>
                </div>

			{/*##form_input##*/}

                                </div>
                                <button stype="submit" className="btn btn-full primary-button">Simpan</button>
                            </form>


                        </div>
                    </div>



                </div>

            </div>
        );
    }

}
