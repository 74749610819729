import React from 'react';
import {Link} from "react-router-dom";
import {Skeleton, message, notification, Icon, Modal} from 'antd';
import { Page, Text, View, Document, StyleSheet, PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import axios from "axios";
const api_url = process.env.REACT_APP_API_URL;

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#ffffff'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  }
});

export default class Document_object extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.id,
            data: "",
            judul: "",
            loading: true
        };

        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);


    }

    componentDidMount() {
    }

    onDocumentLoadSuccess(bind){

      let header = {
        headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
      };
      axios.get( api_url+'survei/responden/'+this.state.id, header).then((data) => {
        if( typeof data == 'object' ){

          if( data.data.status === 'success' ){
            let survei_data = data.data.data[0];
            this.setState({
                judul: survei_data.judul,
                loading: false
            });

          }
        }
      })
    }

    render() {

      return (
          <Document onRender={this.onDocumentLoadSuccess}>
            <Page size="A4" style={styles.page} pageNumber={1}>
              <View style={styles.section}>
                <Text>Judul {this.state.judul}</Text>
              </View>
            </Page>
          </Document>
      );


    }

}
