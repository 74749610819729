import React from 'react';
import { Link } from "react-router-dom";
import axios, {post} from "axios";
import Atable from "../../../lib/Atable";
import { message, notification, Icon, Modal, Tooltip, Skeleton } from 'antd';
import DatePicker from "react-datepicker";

import moment from 'moment';
import 'moment/locale/id';

const api_url = process.env.REACT_APP_API_URL;
const confirm = Modal.confirm;

export default class Detail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pelabuhan_id: this.props.match.params.id,
      kabupaten: "",
      kapal_list: null,
      kapal_id: null,

      asal      : "",
      tujuan    : "",
      tanggal   : "",
      jam       : null,

      image       : null,

      total_record  : 0,
      total_filter  : 0,
      limit         : 10,
      order         : "ASC",
      order_column  : 0,
      active_page   : 0,
      search        : "",
      table_data    : "",
      loading       : true,
      jadwal_loader: true,
      loading_form: false
    };

    this.handleChange   = this.handleChange.bind(this);
    this.handleSubmit   = this.handleSubmit.bind(this);
    this.onChangeFile   = this.onChangeFile.bind(this);
  }

  componentDidMount(){
     document.title = process.env.REACT_APP_APP_NAME+"::Master Data";
     this.get_data();
     this.get_kapal_list();
     this.load_jadwal();
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

     this.setState({
       [name]: value
     });

  }

  tanggal = date => {
    this.setState({
      tanggal: date
    });
  };

  onChangeFile(e) {
    this.setState({image:e.target.files[0]})
  }


  reset_input(){
    this.setState({
      asal      : "",
      tujuan    : "",
      tanggal   : "",
      jam       : ""
    });
  }

  handleSubmit(event) {
      event.preventDefault();

      // loading_form
      this.setState({ loading_form: true });

      let form_data =
      {
        asal:this.state.asal,
        tujuan:this.state.tujuan,
        kapal_id:this.state.kapal_id,
        pelabuhan_id: this.state.pelabuhan_id,
        jam:this.state.jam,
        tanggal:this.state.tanggal
      };

      let header = {
        headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
      };

      axios.post( api_url+'pelabuhan/jadwal_kapal/add', form_data, header
      ).then(
        response => response.data,
        error => {
          alert(error)
        }
      )
      .then((data) => {
        if( data.status === 'success' ){

          this.setState({ loading_form: false }, () =>{
            this.reset_input()
          });

          notification.open({
            message: 'Berhasil',
            description:'Data telah disimpan ke database!',
          });

          this.componentDidMount()

        }else{
          notification.open({
              message: 'Error',
              description: data.data,
              icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
            });
        }

      })
    }

  // handleSubmit(event) {
  //   event.preventDefault();
  //   this.fileUpload(this.state.image).then((data)=>{
  //
  //     if( typeof data == 'object' ){
  //       if( data.data.status === 'success' ){
  //
  //         notification.open({
  //           message: 'Berhasil',
  //           description:'Data telah disimpan ke database!',
  //         });
  //
  //         this.setState({loading: false});
  //
  //         this.componentDidMount();
  //
  //       }else{
  //         notification.open({
  //             message: 'Error',
  //             description: data.data.data,
  //             icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
  //           });
  //           this.setState({loading: false});
  //       }
  //     }
  //   })
  // }

  get_data(){
    var header = {
      headers: {
        'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
    };

    axios.get( api_url+'pelabuhan/get/'+this.state.pelabuhan_id, header)
    .then(
      response => response.data,
      error    => { alert(error) }
    )
    .then((data) => {
      if( typeof data == 'object' ){
        if( data['status'] === 'success' ){

          this.setState({
            nama_pelabuhan: data.data.nama,
            kabupaten: data.data.kabupaten[0].kabupaten,
            jam:this.state.jam,
            tanggal: this.state.tanggal,
            // new Date()
            loading: false
          });
        }else{
          notification.open({
            message: 'API Error',
            description: data.data,
            icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
          });
        }
      }

    })
  }

  head_jadwal(){
    return ["Kapal", "Lintasan", "Tanggal", "Jam", ""];
  }

  load_jadwal(){
    let form_data = {
      params: { search: this.state.search, limit: this.state.limit, offset: this.state.active_page, order: this.state.order, order_column: this.state.order_column },
      headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
    };

    axios.get(api_url+"pelabuhan/jadwal_kapal/list/"+this.props.match.params.id, form_data)
    .then(

      response => {
       this.setState({
         table_data: response.data.data,
         total_record: response.data.recordsTotal,
         total_filter: response.data.recordsFiltered,
         loading_jadwal: false
       });

     },
     error => {
       alert(error)
     }
   );

  }

  generate_rows(){
    const data = this.state.table_data;

    if( typeof data == "object" && data !== null){
      const res = data.map((value, index) =>
          <tr key={ Math.random() }>
          <td>{ value.kapal[0].nama }</td>
          <td>{value.asal} <i class="fas fa-long-arrow-alt-right"></i> {value.tujuan}</td>
          <td>{ moment(value.tanggal).format("D MMMM YYYY") }</td>
          <td>{ value.jam }</td>
          <td>
              <Tooltip title="Edit Jadwal">
                <Link className="btn secondary-button btn-sm" to={`/admin/master_data/jadwal_kapal/edit/${value._id}`}>
                  <i className="fas fa-edit"></i>
                </Link>
             </Tooltip>

             <Tooltip title="Hapus">
               <button onClick={() => this.delete(value._id)} className="btn btn-danger btn-sm" title="Hapus">
                 <i className="fas fa-trash"></i>
               </button>
             </Tooltip>
          </td>
          </tr>
      );

        return res;
    }
  }

  get_kapal_list(){
    axios.get(api_url+"pelabuhan/get_kapal_list").then(
        response => {
          this.setState({ kapal_list: response.data.data });

        },
      error => {
        alert(error)
      }

    );
  }

  select_option_for_kapal(){

    if( this.state.kapal_list !== null && typeof this.state.kapal_list == "object"){


      if( this.state.kapal_list.length > 0 ){
        const res = (
          <select name="kapal_id" className="form-control" onChange={this.handleChange} required>
            <option value="">Pilih</option>
            {this.state.kapal_list.map(( value ) =>
              <option value={value._id} key={ Math.random() } selected= {(value._id == this.state.kapal_id)?true:false}>{value.nama}</option>
            )}
          </select>
        );

        return res;

      }
    }
  }


     delete(id){
       const e     = this;

       const header = {
         headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
       };
       confirm({
         title: "Apakah anda ingin menghapus data?",
         content: "Data akan terhapus secara permanen",
         onOk() {
           axios.delete(api_url+"pelabuhan/jadwal_kapal/delete/"+id, header)
            .then(
              response => {
                notification.open({
                  message: 'Berhasil',
                  description:'Data telah berhasil dihapus!'
                });
                return e.componentDidMount()
             },
             error => {
               alert(error)
             }
           )

           ;
         }
       });
     }

  render() {

    return (
      <div>
      <div className="col-12 grid-margin">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item primary-color">Master Data</li>
            <Link className="breadcrumb-item" to="/admin/master_data/jadwal_kapal/list">Jadwal Kapal</Link>
            <li className="breadcrumb-item active">Kelola Jadwal</li>
          </ol>
        </nav>
      </div>

        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">

            <div className="row">
              <div className="col-md-6">
                <h4 className="display-4 primary-color">Pelabuhan</h4>

                <Skeleton loading={this.state.loading} active>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Nama Pelabuhan<span className="text-danger">*</span></label>
                      <input type="text" className="form-control" value={this.state.nama_pelabuhan} readOnly/>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Kabupaten/Kota<span className="text-danger">*</span></label>
                      <input type="text" className="form-control" value={this.state.kabupaten} readOnly/>
                    </div>
                  </div>

                </Skeleton>

              </div>

              <div className="col-md-6">
                <h4 className="display-4 primary-color">Tambah Jadwal</h4>

                <form onSubmit={this.handleSubmit}>
                <Skeleton loading={this.state.loading_form} active>
                <div className="row">
                  {/** Maskapai **/}
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Kapal<span className="text-danger">*</span></label>
                        { this.select_option_for_kapal() }
                    </div>
                  </div>

                  {/* Input Asal */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Asal<span className="text-danger">*</span></label>
                      <input type="text" className="form-control" placeholder="Asal" name="asal" value={this.state.asal} onChange={this.handleChange} required/>
                    </div>
                  </div>


                    {/* Input Tujuan */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Tujuan<span className="text-danger">*</span></label>
                        <input type="text" className="form-control" placeholder="Tujuan" name="tujuan" value={this.state.tujuan} onChange={this.handleChange} required/>
                      </div>
                    </div>

                    {/* Tanggal */}
                    {/*
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Tanggal<span className="text-danger">*</span></label>
                       <div className="datepicker-container">
                          <DatePicker className=" form-control" dateFormat="dd-MM-yyyy" selected={this.state.tanggal} onChange={date => this.tanggal(date)} />
                        </div>

                      </div>
                    </div>
                    */}

                    {/* Jam */}
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Jam<span className="text-danger">*</span></label>
                        <input type="text" className="form-control" placeholder="Jam" name="jam" value={this.state.jam} onChange={this.handleChange} required/>
                      </div>
                    </div>

                    {/** Upload Jadwal  **/}
                    {/*
                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Upload Jadwal<span className="text-danger">*</span></label><br/>
                        <input type="file" className="form-control-file" onChange={this.onChangeFile} />
                        <small className="form-text text-muted">Gambar dengan format JPG atau PNG</small>
                      </div>
                    </div>
                    */}

                    <button stype="submit" className="btn btn-full primary-button">Tambah</button>
                  </div>
                  </Skeleton>
                </form>

              </div>

              {/* Jadwal list */}
              <div className="col-md-12">
                <hr className="divider soft"/>
                <h4 className="display-4 primary-color">Daftar Jadwal</h4>

                <Skeleton loading={this.state.loading_jadwal} active>

                  <Atable
                    head={ this.head_jadwal() }
                    data={ this.generate_rows() }
                    total_filter={ this.state.total_filter }
                    total_record={ this.state.total_record }
                    current_page={this.state.active_page}
                    search={this.state.search}
                    handleChange={this.handleChange}
                    limit={this.state.limit}
                  />

                </Skeleton>

              </div>

            </div>

            </div>
          </div>
        </div>

      </div>
    );
  }

}
