import React, { Component } from "react";
import { Route } from 'react-router-dom';
import Moment from 'moment';
import { message } from 'antd';

import Header from "../layout/Header";
import Sidebar from "../layout/Sidebar";
import Footer from "../layout/Footer";

import Dashboard from "./dashboard/Dashboard";
import Edit_profil from "./Edit_profil";

import Modules from "./acl/modules/Modules";
import Add_module from "./acl/modules/Add_module";
import Edit_module from "./acl/modules/Edit_module";
import Detail_module from "./acl/modules/Detail_module";
import Edit_submodule from "./acl/modules/Edit_submodule";
import Group from "./acl/group/Group";
import Add from "./acl/group/Add";
import Edit from "./acl/group/Edit";

import Level from "./acl/level/List_level";
import Add_level from "./acl/level/Add_level";
import Edit_level from "./acl/level/Edit_level";
import Hak_Akses from "./acl/level/Hak_akses";

import Users from "./acl/users/List_users";
import Add_users from "./acl/users/Add_users";
import Edit_users from "./acl/users/Edit_users";
import Password from "./acl/users/Password_users";

import List_form from "./form/List";
import Add_form from "./form/Add";
import Edit_form from "./form/Edit";

import Provinsi_List from "./data_wilayah/provinsi/List";
import Provinsi_Add from "./data_wilayah/provinsi/Add";
import Provinsi_Edit from "./data_wilayah/provinsi/Edit";
import Kabupaten_List from "./data_wilayah/kabupaten/List";
import Kabupaten_Add from "./data_wilayah/kabupaten/Add";
import Kabupaten_Edit from "./data_wilayah/kabupaten/Edit";
import Kecamatan_List from "./data_wilayah/kecamatan/List";
import Kecamatan_Add from "./data_wilayah/kecamatan/Add";
import Kecamatan_Edit from "./data_wilayah/kecamatan/Edit";
import Masjid_List from "./master_data/masjid/List";
import Masjid_Add from "./master_data/masjid/Add";
import Masjid_Edit from "./master_data/masjid/Edit";
import PemadamKebakaran_List from "./master_data/pemadam/List";
import PemadamKebakaran_Add from "./master_data/pemadam/Add";
import PemadamKebakaran_Edit from "./master_data/pemadam/Edit";
import Polres_List from "./master_data/polres/List";
import Polres_Add from "./master_data/polres/Add";
import Polres_Edit from "./master_data/polres/Edit";
import Polsek_List from "./master_data/polsek/List";
import Polsek_Add from "./master_data/polsek/Add";
import Polsek_Edit from "./master_data/polsek/Edit";
import RumahSakit_List from "./master_data/rumah_sakit/List";
import RumahSakit_Add from "./master_data/rumah_sakit/Add";
import RumahSakit_Edit from "./master_data/rumah_sakit/Edit";
import SPBU_List from "./master_data/spbu/List";
import SPBU_Add from "./master_data/spbu/Add";
import SPBU_Edit from "./master_data/spbu/Edit";
import TeleponDarurat_List from "./master_data/telepon_darurat/List";
import TeleponDarurat_Add from "./master_data/telepon_darurat/Add";
import TeleponDarurat_Edit from "./master_data/telepon_darurat/Edit";
import WarungKopi_List from "./master_data/warung_kopi/List";
import WarungKopi_Add from "./master_data/warung_kopi/Add";
import WarungKopi_Edit from "./master_data/warung_kopi/Edit";
import TempatWisata_List from "./master_data/wisata/List";
import TempatWisata_Add from "./master_data/wisata/Add";
import TempatWisata_Edit from "./master_data/wisata/Edit";
import Survei_List from "./manajemen_survei/List";
import Survei_Add from "./manajemen_survei/Add";
import Survei_Edit from "./manajemen_survei/Edit";
import Survei_Detail from "./manajemen_survei/Detail";
import Survei_Responden from "./manajemen_survei/Responden";
import Survei_Print from "./manajemen_survei/Print";
import SKPA_List from "./data_opd/skpa/List";
import SKPA_Add from "./data_opd/skpa/Add";
import SKPA_Edit from "./data_opd/skpa/Edit";
import OPDKabupaten_List from "./data_opd/kabupaten/List";
import OPDKabupaten_Add from "./data_opd/kabupaten/Add";
import OPDKabupaten_Edit from "./data_opd/kabupaten/Edit";

import Kapal_list from "./master_data/kapal/List";
import Kapal_add from "./master_data/kapal/Add";
import Kapal_edit from "./master_data/kapal/Edit";
import HargaPangan_List from "./master_data/harga_pangan/List";
import HargaPangan_Add from "./master_data/harga_pangan/Add";
import HargaPangan_Edit from "./master_data/harga_pangan/Edit";
import HargaPangan_Detail from "./master_data/harga_pangan/Detail";

import Bandara_List from "./master_data/bandara/List";
import Bandara_Add from "./master_data/bandara/Add";
import Bandara_Edit from "./master_data/bandara/Edit";
import Maskapai_List from "./master_data/maskapai/List";
import Maskapai_Add from "./master_data/maskapai/Add";
import Maskapai_Edit from "./master_data/maskapai/Edit";
import JadwalPenerbangan_List from "./master_data/jadwal_penerbangan/List";
import JadwalPenerbangan_Add from "./master_data/jadwal_penerbangan/Add";
import JadwalPenerbangan_Edit from "./master_data/jadwal_penerbangan/Edit";
import JadwalPenerbangan_Detail from "./master_data/jadwal_penerbangan/Detail";

import Kapal_List from "./master_data/kapal/List";
import Kapal_Add from "./master_data/kapal/Add";
import Kapal_Edit from "./master_data/kapal/Edit";

import Pelabuhan_List from "./master_data/pelabuhan/List";
import Pelabuhan_Add from "./master_data/pelabuhan/Add";
import Pelabuhan_Edit from "./master_data/pelabuhan/Edit";

import JadwalKapalLaut_List from "./master_data/jadwal_kapal/List";
import JadwalKapalLaut_Add from "./master_data/jadwal_kapal/Add";
import JadwalKapalLaut_Edit from "./master_data/jadwal_kapal/Edit";
import JadwalKapalLaut_Detail from "./master_data/jadwal_kapal/Detail";

import HabaAPP_List from "./aplikasi/haba_app/List";
import HabaAPP_Add from "./aplikasi/haba_app/Add";
import HabaAPP_Edit from "./aplikasi/haba_app/Edit";

import HabaCollaboration_List from "./aplikasi/haba_collaboration/List";
import HabaCollaboration_Add from "./aplikasi/haba_collaboration/Add";
import HabaCollaboration_Edit from "./aplikasi/haba_collaboration/Edit";

import HabaExtra_List from "./aplikasi/haba_extra/List";
import HabaExtra_Add from "./aplikasi/haba_extra/Add";
import HabaExtra_Edit from "./aplikasi/haba_extra/Edit";

import Notifikasi_List from "./aplikasi/notifikasi/List";
import Notifikasi_Add from "./aplikasi/notifikasi/Add";
import Notifikasi_Edit from "./aplikasi/notifikasi/Edit";
//##ImportComponent##//


class Admin extends Component {

  constructor(props) {
    super(props);

    if( !localStorage.getItem(process.env.REACT_APP_TOKEN) ){
      this.props.history.push("/login");
    }

    // token expired check
    const date    = new Date();
    const now     = date.getTime();
    const expired = Moment( localStorage.getItem('token_expired') ).toDate().getTime();


    if( now > expired ){
      this.props.history.push("/logout");
      message.error( "Token expired" );
    }

  }

  render() {
    return (
      <div>
        <Header />

        {/* container-fluid */}
        <div  className="container-fluid page-body-wrapper">
          <Sidebar />

          {/* main-panel */}
          <div className="main-panel">

            <div className="content-wrapper">
              <Route exact path="/admin/dashboard" component={Dashboard} />
              <Route exact path="/admin/edit_profil" component={Edit_profil} />

              {/* Modules */}
              <Route exact path="/admin/acl/modules" component={Modules} />
              <Route exact path="/admin/acl/modules/add_module" component={Add_module} />
              <Route exact path="/admin/acl/modules/detail_module/:id" component={Detail_module} />
              <Route exact path="/admin/acl/modules/edit_module/:id" component={Edit_module} />
              <Route exact path="/admin/acl/modules/edit_submodule/:id/:submodule_id" component={Edit_submodule} />

              {/* Group */}
              <Route exact path="/admin/acl/group" component={Group} />
              <Route exact path="/admin/acl/group/add" component={Add} />
              <Route exact path="/admin/acl/group/edit/:id" component={Edit} />

              {/* Level */}
              <Route exact path="/admin/acl/level" component={Level} />
              <Route exact path="/admin/acl/level/add_level" component={Add_level} />
              <Route exact path="/admin/acl/level/edit_level/:id" component={Edit_level} />
              <Route exact path="/admin/acl/level/hak_akses/:id" component={Hak_Akses} />

              {/* Users */}
              <Route exact path="/admin/acl/users" component={Users} />
              <Route exact path="/admin/acl/users/add_users" component={Add_users} />
              <Route exact path="/admin/acl/users/edit_users/:id" component={Edit_users} />
              <Route exact path="/admin/acl/users/password/:id" component={Password} />

              {/* Form */}
              {/*

              <Route exact path="/admin/form/edit/:id" component={Edit} />
              <Route exact path="/admin/form/detail:id" component={Detail} />
              */}
              <Route exact path="/admin/form/list/:submodule_id" component={List_form} />
              <Route exact path="/admin/form/add/:submodule_id" component={Add_form} />
              <Route exact path="/admin/form/edit/:submodule_id/:item_id" component={Edit_form} />


							<Route exact path="/admin/data_wilayah/provinsi/List" component={Provinsi_List} />
							<Route exact path="/admin/data_wilayah/provinsi/add" component={Provinsi_Add} />
							<Route exact path="/admin/data_wilayah/provinsi/edit/:id" component={Provinsi_Edit} />
							<Route exact path="/admin/data_wilayah/kabupaten/List" component={Kabupaten_List} />
							<Route exact path="/admin/data_wilayah/kabupaten/add" component={Kabupaten_Add} />
							<Route exact path="/admin/data_wilayah/kabupaten/edit/:id" component={Kabupaten_Edit} />
							<Route exact path="/admin/data_wilayah/kecamatan/List" component={Kecamatan_List} />
							<Route exact path="/admin/data_wilayah/kecamatan/add" component={Kecamatan_Add} />
							<Route exact path="/admin/data_wilayah/kecamatan/edit/:id" component={Kecamatan_Edit} />
							<Route exact path="/admin/master_data/masjid/List" component={Masjid_List} />
							<Route exact path="/admin/master_data/masjid/add" component={Masjid_Add} />
							<Route exact path="/admin/master_data/masjid/edit/:id" component={Masjid_Edit} />
							<Route exact path="/admin/master_data/pemadam/List" component={PemadamKebakaran_List} />
							<Route exact path="/admin/master_data/pemadam/add" component={PemadamKebakaran_Add} />
							<Route exact path="/admin/master_data/pemadam/edit/:id" component={PemadamKebakaran_Edit} />
							<Route exact path="/admin/master_data/polres/List" component={Polres_List} />
							<Route exact path="/admin/master_data/polres/add" component={Polres_Add} />
							<Route exact path="/admin/master_data/polres/edit/:id" component={Polres_Edit} />
							<Route exact path="/admin/master_data/polsek/List" component={Polsek_List} />
							<Route exact path="/admin/master_data/polsek/add" component={Polsek_Add} />
							<Route exact path="/admin/master_data/polsek/edit/:id" component={Polsek_Edit} />
							<Route exact path="/admin/master_data/rumah_sakit/List" component={RumahSakit_List} />
							<Route exact path="/admin/master_data/rumah_sakit/add" component={RumahSakit_Add} />
							<Route exact path="/admin/master_data/rumah_sakit/edit/:id" component={RumahSakit_Edit} />
							<Route exact path="/admin/master_data/spbu/List" component={SPBU_List} />
							<Route exact path="/admin/master_data/spbu/add" component={SPBU_Add} />
							<Route exact path="/admin/master_data/spbu/edit/:id" component={SPBU_Edit} />
							<Route exact path="/admin/master_data/telepon_darurat/List" component={TeleponDarurat_List} />
							<Route exact path="/admin/master_data/telepon_darurat/add" component={TeleponDarurat_Add} />
							<Route exact path="/admin/master_data/telepon_darurat/edit/:id" component={TeleponDarurat_Edit} />
							<Route exact path="/admin/master_data/warung_kopi/List" component={WarungKopi_List} />
							<Route exact path="/admin/master_data/warung_kopi/add" component={WarungKopi_Add} />
							<Route exact path="/admin/master_data/warung_kopi/edit/:id" component={WarungKopi_Edit} />
							<Route exact path="/admin/master_data/wisata/List" component={TempatWisata_List} />
							<Route exact path="/admin/master_data/wisata/add" component={TempatWisata_Add} />
							<Route exact path="/admin/master_data/wisata/edit/:id" component={TempatWisata_Edit} />


							<Route exact path="/admin/manajemen_survei/List" component={Survei_List} />
							<Route exact path="/admin/manajemen_survei/add" component={Survei_Add} />
							<Route exact path="/admin/manajemen_survei/edit/:id" component={Survei_Edit} />
              <Route exact path="/admin/manajemen_survei/detail/:id" component={Survei_Detail} />
              <Route exact path="/admin/manajemen_survei/responden/:id" component={Survei_Responden} />
              <Route exact path="/admin/manajemen_survei/print/:id" component={Survei_Print} />
							<Route exact path="/admin/data_opd/skpa/List" component={SKPA_List} />
							<Route exact path="/admin/data_opd/skpa/add" component={SKPA_Add} />
							<Route exact path="/admin/data_opd/skpa/edit/:id" component={SKPA_Edit} />
							<Route exact path="/admin/data_opd/kabupaten/List" component={OPDKabupaten_List} />
							<Route exact path="/admin/data_opd/kabupaten/add" component={OPDKabupaten_Add} />
							<Route exact path="/admin/data_opd/kabupaten/edit/:id" component={OPDKabupaten_Edit} />
							<Route exact path="/admin/master_data/harga_pangan/List" component={HargaPangan_List} />
							<Route exact path="/admin/master_data/harga_pangan/add" component={HargaPangan_Add} />
							<Route exact path="/admin/master_data/harga_pangan/edit/:id" component={HargaPangan_Edit} />
              <Route exact path="/admin/master_data/harga_pangan/detail/:id" component={HargaPangan_Detail} />
							<Route exact path="/admin/master_data/bandara/List" component={Bandara_List} />
							<Route exact path="/admin/master_data/bandara/add" component={Bandara_Add} />
							<Route exact path="/admin/master_data/bandara/edit/:id" component={Bandara_Edit} />
							<Route exact path="/admin/master_data/maskapai/List" component={Maskapai_List} />
							<Route exact path="/admin/master_data/maskapai/add" component={Maskapai_Add} />
							<Route exact path="/admin/master_data/maskapai/edit/:id" component={Maskapai_Edit} />
							<Route exact path="/admin/master_data/jadwal_penerbangan/List" component={JadwalPenerbangan_List} />
							<Route exact path="/admin/master_data/jadwal_penerbangan/add" component={JadwalPenerbangan_Add} />
							<Route exact path="/admin/master_data/jadwal_penerbangan/edit/:bandara_id/:jadwal_id" component={JadwalPenerbangan_Edit} />
              <Route exact path="/admin/master_data/jadwal_penerbangan/detail/:id" component={JadwalPenerbangan_Detail} />

							<Route exact path="/admin/master_data/kapal/List" component={Kapal_List} />
							<Route exact path="/admin/master_data/kapal/add" component={Kapal_Add} />
							<Route exact path="/admin/master_data/kapal/edit/:id" component={Kapal_Edit} />

							<Route exact path="/admin/master_data/pelabuhan/List" component={Pelabuhan_List} />
							<Route exact path="/admin/master_data/pelabuhan/add" component={Pelabuhan_Add} />
							<Route exact path="/admin/master_data/pelabuhan/edit/:id" component={Pelabuhan_Edit} />
							<Route exact path="/admin/master_data/jadwal_kapal/List" component={JadwalKapalLaut_List} />
							<Route exact path="/admin/master_data/jadwal_kapal/add" component={JadwalKapalLaut_Add} />
							<Route exact path="/admin/master_data/jadwal_kapal/edit/:id" component={JadwalKapalLaut_Edit} />
              <Route exact path="/admin/master_data/jadwal_kapal/detail/:id" component={JadwalKapalLaut_Detail} />

              <Route exact path="/admin/aplikasi/haba_app/List" component={HabaAPP_List} />
							<Route exact path="/admin/aplikasi/haba_app/add" component={HabaAPP_Add} />
							<Route exact path="/admin/aplikasi/haba_app/edit/:id" component={HabaAPP_Edit} />

              <Route exact path="/admin/aplikasi/haba_collaboration/List" component={HabaCollaboration_List} />
							<Route exact path="/admin/aplikasi/haba_collaboration/add" component={HabaCollaboration_Add} />
							<Route exact path="/admin/aplikasi/haba_collaboration/edit/:id" component={HabaCollaboration_Edit} />

              <Route exact path="/admin/aplikasi/haba_extra/List" component={HabaExtra_List} />
							<Route exact path="/admin/aplikasi/haba_extra/add" component={HabaExtra_Add} />
							<Route exact path="/admin/aplikasi/haba_extra/edit/:id" component={HabaExtra_Edit} />

							<Route exact path="/admin/aplikasi/notifikasi/List" component={Notifikasi_List} />
							<Route exact path="/admin/aplikasi/notifikasi/add" component={Notifikasi_Add} />
							<Route exact path="/admin/aplikasi/notifikasi/edit/:id" component={Notifikasi_Edit} />
							{/*##route##*/}

            </div>

            { /* Footer component */ }
            <Footer />

          </div>
          {/* ./main-panel */}

        </div>
        {/* ./container-fluid */}

      </div>

    )
  }
}

export default Admin;
