import axios from "axios";

const api_url = process.env.REACT_APP_API_URL;

function load_data( path ){
  const header = {
    headers: {
      'api_token': localStorage.getItem('token') }
  };

  return axios.get(api_url+'acl/modules/get_submodules_by_group_id/1', header).then(response => {
    if( typeof response == 'object' ){
      const response_data = response.data;

      if( response_data.status === "success"){
        const find = response_data.data.find( sm => sm.submodule_url === path );
        if( typeof find === 'object' ){
          return find;
        }else{
          return false;
        }

      }
    }
  })
}



const auth = {
    is_access: function( path ){
      // split Path URL ke path submodule
      const path_arr      = path.split("/");
      const path_split    = path_arr[1]+"/"+path_arr[2]+"/"+path_arr[3];

      const res = load_data( path_split );

      res.then(function(result) {
        localStorage.setItem("CanRead", result.read);
        localStorage.setItem("CanCreate", result.create);
        localStorage.setItem("CanUpdate", result.update);
        localStorage.setItem("CanDelete", result.delete);
      })
    }

}

export default auth;
