import React from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import {Skeleton, message, notification, Icon} from 'antd';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Editor } from '@tinymce/tinymce-react';

const api_url = process.env.REACT_APP_API_URL;

export default class Add extends React.Component {
    constructor(props) {
        super(props);

        this.state = { judul:'',tanggal_mulai:new Date(), tanggal_selesai: new Date(),deskripsi:'', opd_id: "", opd_list: null, loading: false };

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDesc = this.handleChangeDesc.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {

        document.title = process.env.REACT_APP_APP_NAME + "::Survei";
        this.get_opd_list()
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        const name = target.name;

        this.setState({[name]: value});
    }

    handleChangeDesc( e ){
        this.setState({ deskripsi: e.target.getContent()});
    }

    tanggal_mulai = date => {
      this.setState({
        tanggal_mulai: date
      });
    };

    tanggal_selesai = date => {
      this.setState({
        tanggal_selesai: date
      });
    };


    handleSubmit(event) {
        event.preventDefault();

        this.setState({loading: true});

        let form_data =
        {
          judul:this.state.judul,
 					tanggal_mulai:this.state.tanggal_mulai,
 					tanggal_selesai:this.state.tanggal_selesai,
          deskripsi:this.state.deskripsi,
          opd_id: this.state.opd_id
        };

        var header = {
          headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };

        axios.post( api_url+'survei/add', form_data, header
        ).then(
          response => response.data,
          error => {
            alert(error)
          }
        )
        .then((data) => {
          if( typeof data == 'object' ){
            if( data.status === 'success' ){

              notification.open({
                message: 'Berhasil',
                description:'Data telah disimpan ke database!',
              });

              this.setState({loading: false});

              this.props.history.push("/admin/manajemen_survei/list");

            }else{
              notification.open({
                  message: 'Error',
                  description: data.data,
                  icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
                });
                this.setState({loading: false});
            }
          }

        })
      }

      get_opd_list(){
        axios.get(api_url+"opd/list_skpa").then(
            response => {
              this.setState({ opd_list: response.data.data});
          },
          error => {
            alert(error)
          }

        );
      }

      select_option_for_opd(){
        if( this.state.opd_list != undefined && typeof this.state.opd_list == "object"){
          if( this.state.opd_list.length > 0 ){
            const res = (
              <select name="opd_id" className="form-control" onChange={this.handleChange} required>
                <option value="" key={ Math.random() }>Pilih</option>
                {this.state.opd_list.map(( value ) =>
                  <option value={value._id} key={ Math.random() } selected= {(value._id == this.state.opd_id)?true:false}>{value.nama_opd}</option>
                )}
              </select>
            );

            return res;

          }
        }
      }


//##cst_function##/

    render() {

        return (
        <div className="row">
            <div className="col-12 grid-margin">

              <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                      <li className="breadcrumb-item">Manajemen Survei</li>
                          <Link className="breadcrumb-item" to="/admin/manajemen_survei/list">Daftar Survei</Link>
                      <li className="breadcrumb-item active">Buat Survei</li>
                  </ol>
              </nav>

            </div>


            <Skeleton loading={this.state.loading} active>
                <div className="col-md-8 grid-margin">
                    <div className="card">
                        <div className="card-body">
                          <h4 className="display-4 primary-color">Buat  Survei Baru</h4>
                          <br />

                            {/* Form Input */}
                            <form onSubmit={this.handleSubmit}>
                                <div className="row">
                                  {/** Judul Survei **/}
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <label>Judul Survei<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control" placeholder="Judul Survei" name="judul" value={this.state.judul} onChange={this.handleChange} required/>
                                    </div>
                                  </div>


                                  {/** Tanggal Mulai Survei **/}
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Tanggal Mulai Survei<span className="text-danger">*</span></label><br/>
                                      <div className="datepicker-container">
                                        <DatePicker className="react-datepicker-input form-control" dateFormat="dd-MM-yyyy" selected={this.state.tanggal_mulai} onChange={date => this.tanggal_mulai(date)} />
                                      </div>
                                    </div>
                                  </div>


                                  {/** Tanggal Berakhir Survei **/}
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Tanggal Berakhir Survei<span className="text-danger">*</span></label><br/>
                                      <div className="datepicker-container">
                                        <DatePicker className="react-datepicker-input form-control" dateFormat="dd-MM-yyyy" selected={this.state.tanggal_selesai} onChange={date => this.tanggal_selesai(date)} />
                                      </div>
                                    </div>
                                  </div>

                                  {/* OPD */}
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <label>Pemilik Survei<span className="text-danger">*</span></label>
                        								{ this.select_option_for_opd() }
                        						</div>
                                  </div>


                                  {/** Deskripsi **/}
                                  <div className="col-md-12">
                                    <div className="form-group">
                                      <label>Deskripsi</label>
                                      <Editor
                                        initialValue=""
                                        apiKey={process.env.REACT_APP_TINYMCE}
                                        init={{
                                          height: 400,
                                          menubar: false,
                                          plugins: [
                                            'advlist autolink lists link image charmap print preview anchor',
                                            'searchreplace visualblocks code fullscreen',
                                            'insertdatetime media table paste code help wordcount'
                                          ],
                                          toolbar:
                                            'undo redo | formatselect | bold italic backcolor | \
                                            alignleft aligncenter alignright alignjustify | \
                                            bullist numlist outdent indent | removeformat | help'
                                        }}
                                        onChange={this.handleChangeDesc}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-12">
                                    <button type="submit" className="btn btn-lg primary-button">Simpan</button>
                                  </div>

                                </div>
                              </form>
                        </div>
                    </div>

                </div>

                <div className="col-md-4 grid-margin">
                  <div className="card">
                    <div className="card-body">

                    </div>
                    </div>
                </div>
                </Skeleton>
        </div>
        );
    }

}
