import React from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import Atable from "../../../lib/Atable";
import { message, notification, Icon, Modal, Tooltip, Skeleton } from 'antd';

const api_url = process.env.REACT_APP_API_URL;
const confirm = Modal.confirm;

export default class Detail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bandara_id: this.props.match.params.id,
      kode: "",
      kabupaten: "",
      maskapai_list: null,
      maskapai_id: null,
      hari          : "",
      rute_from     : "",
      rute_to       : "",
      jam           : '',
      total_record  : 0,
      total_filter  : 0,
      limit         : 10,
      order         : "ASC",
      order_column  : 0,
      active_page   : 0,
      search        : "",
      table_data    : "",
      loading       : true,
      jadwal_loader: true
    };

    this.handleChange   = this.handleChange.bind(this);
    this.handleSubmit   = this.handleSubmit.bind(this);
    // this.hapusJadwal     = this.hapusJadwal.bind(this);
    // this.hapusSubmodul  = this.hapusSubmodul.bind(this);
  }

  componentDidMount(){
     document.title = process.env.REACT_APP_APP_NAME+"::Master Data";
     this.get_data();
     this.get_maskapai_list();
     this.load_jadwal();
  }



  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

     this.setState({
       [name]: value
     });

  }

  reset_input(){
    this.setState({
      bandara_id: null,
      maskapai_id: null,
      hari: null,
      jam: null,
      rute_from: "",
      rute_to: "",
      jam: ""
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    var form_data =
    {
      bandara_id: this.state.bandara_id,
      maskapai_id: this.state.maskapai_id,
      hari: this.state.hari,
      asal: this.state.rute_from,
      tujuan: this.state.rute_to,
      jam: this.state.jam

    };

    var header = {
      headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
    };

    axios.post( api_url+'bandara/jadwal_penerbangan/add', form_data, header
    ).then(response => response.data)
    .then((data) => {

      if( data['status'] === 'success' ){

        notification.open({
          message: 'Berhasil',
          description:'Data telah disimpan ke database!',
        });

        this.reset_input();
        this.load_jadwal();


        // this.componentDidMount();

      }else{
        message.error( data['data'] );
      }

    })
  }

  get_data(){
    let header = {
      headers: {
        'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
    };

    axios.get( api_url+'bandara/get/'+this.state.bandara_id, header)
    .then(
      response => response.data,
      error    => { alert(error) }
    )
    .then((data) => {
      if( typeof data == 'object' ){
        if( data['status'] === 'success' ){

          // console.log( data.data[0].kabupaten[0] )
          this.setState({
            nama_bandara: data.data[0].nama,
            kode: data.data[0].kode,
            kabupaten: data.data[0].kabupaten[0].kabupaten,
            loading: false
          });
        }else{
          notification.open({
            message: 'API Error',
            description: data.data,
            icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
          });
        }
      }

    })
  }

  head_jadwal(){
    return ["Maskapai",  "Hari", "Jam", "Rute", "Aksi"];
  }

  load_jadwal(){
    let form_data = {
      params: { search: this.state.search, limit: this.state.limit, offset: this.state.active_page, order: this.state.order, order_column: this.state.order_column },
      headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
    };

    axios.get(api_url+"bandara/jadwal_penerbangan/list/"+this.props.match.params.id, form_data)
    .then(

      response => {
       this.setState({
         table_data: response.data.data,
         total_record: response.data.recordsTotal,
         total_filter: response.data.recordsFiltered,
         loading_jadwal: false
       });

     },
     error => {
       alert(error)
     }
   );

  }

  generate_rows(){
    const data = this.state.table_data;

    if( typeof data == "object" && data !== null){
      const res = data.map((value, index) =>
          <tr key={ Math.random() }>
          <td><img src={api_url+value.maskapai[0].logo} className="table-img"/> <br/>{value.maskapai[0].nama}</td>
           <td>{value.hari}</td>
           <td>{value.jam}</td>
           <td>{value.asal} <i class="fas fa-long-arrow-alt-right"></i> {value.tujuan}</td>

            <td>
              <Tooltip title="Edit Jadwal">
                <Link className="btn secondary-button btn-sm" to={`/admin/master_data/jadwal_penerbangan/edit/${this.state.bandara_id}/${value._id}`}>
                  <i className="fas fa-edit"></i>
                </Link>
             </Tooltip>

             <Tooltip title="Hapus">
               <button onClick={() => this.delete(value._id)} className="btn btn-danger btn-sm" title="Hapus">
                 <i className="fas fa-trash"></i>
               </button>
             </Tooltip>


            </td>
          </tr>
      );

        return res;
    }
  }

  get_maskapai_list(){
    axios.get(api_url+"bandara/get_maskapai_list").then(
        response => {
          this.setState({ maskapai_list: response.data.data });

        },
      error => {
        alert(error)
      }

    );
  }

  select_option_for_maskapai(){
    if( this.state.maskapai_list !== null && typeof this.state.maskapai_list == "object"){
      if( this.state.maskapai_list.length > 0 ){
        const res = (
          <select name="maskapai_id" className="form-control" onChange={this.handleChange} required>
            <option value="">Pilih</option>
            {this.state.maskapai_list.map(( value ) =>
              <option value={value._id} key={ Math.random() } selected= {(value._id == this.state.maskapai_id)?true:false}>{value.nama}</option>
            )}
          </select>
        );

        return res;

      }
    }
  }


     delete(id){
       const e     = this;

       const header = {
         headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
       };
       confirm({
         title: "Apakah anda ingin menghapus data?",
         content: "Data akan terhapus secara permanen",
         onOk() {
           axios.delete(api_url+"bandara/jadwal_penerbangan/delete/"+id, header)
            .then(
              response => {
                notification.open({
                  message: 'Berhasil',
                  description:'Data telah berhasil dihapus!'
                });
                return e.componentDidMount()
             },
             error => {
               alert(error)
             }
           )

           ;
         }
       });
     }

  render() {
    return (
      <div>
      <div className="col-12 grid-margin">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item primary-color">Master Data</li>
            <Link className="breadcrumb-item" to="/admin/master_data/jadwal_penerbangan/list">Jadwal Penerbangan</Link>
            <li className="breadcrumb-item active">Kelola Jadwal</li>
          </ol>
        </nav>
      </div>

        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">

            <div className="row">
              <div className="col-md-6">
                <h4 className="display-4 primary-color">Bandara</h4>

                <Skeleton loading={this.state.loading} active>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Nama Bandara<span className="text-danger">*</span></label>
                      <input type="text" className="form-control" value={this.state.nama_bandara} readOnly/>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Kabupaten/Kota<span className="text-danger">*</span></label>
                      <input type="text" className="form-control" value={this.state.kabupaten} readOnly/>
                    </div>
                  </div>

                </Skeleton>

              </div>

              <div className="col-md-6">
                <h4 className="display-4 primary-color">Tambah Jadwal</h4>

                <form onSubmit={this.handleSubmit}>
                <div className="row">
                  {/** Maskapai **/}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Maskapai<span className="text-danger">*</span></label>
                        { this.select_option_for_maskapai() }
                    </div>
                  </div>

                    {/* Input Hari */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Hari<span className="text-danger">*</span></label>
                        <select name="hari" className="form-control" onChange={this.handleChange} required>
                          <option value="">Pilih</option>
                          <option value="Senin" key={ Math.random() } selected= {("Senin" == this.state.hari)?true:false}>Senin</option>
                          <option value="Selasa" key={ Math.random() } selected= {("Selasa" == this.state.hari)?true:false}>Selasa</option>
                          <option value="Rabu" key={ Math.random() } selected= {("Rabu" == this.state.hari)?true:false}>Rabu</option>
                          <option value="Kamis" key={ Math.random() } selected= {("Kamis" == this.state.hari)?true:false}>Kamis</option>
                          <option value="Jumat" key={ Math.random() } selected= {("Jumat" == this.state.hari)?true:false}>Jumat</option>
                          <option value="Sabtu" key={ Math.random() } selected= {("Sabtu" == this.state.hari)?true:false}>Sabtu</option>
                          <option value="Minggu" key={ Math.random() } selected= {("Minggu" == this.state.hari)?true:false}>Minggu</option>
                        </select>
                      </div>
                    </div>

                    {/* Input Rute */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Asal<span className="text-danger">*</span></label>
                        <input type="text" className="form-control" placeholder="Asal" name="rute_from" value={this.state.rute_from} onChange={this.handleChange} required/>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Tujuan<span className="text-danger">*</span></label>
                        <input type="text" className="form-control" placeholder="Tujuan" name="rute_to" value={this.state.rute_to} onChange={this.handleChange} required/>
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="form-group">
                        <label>Jam<span className="text-danger">*</span></label>
                        <input type="text" className="form-control" placeholder="Jam" name="jam" value={this.state.jam} onChange={this.handleChange} required/>
                      </div>
                    </div>

                    <button stype="submit" className="btn btn-full primary-button">Tambah</button>
                  </div>
                </form>

              </div>

              {/* Jadwal list */}
              <div className="col-md-12">
                <hr className="divider soft"/>
                <h4 className="display-4 primary-color">Daftar Jadwal</h4>

                <Skeleton loading={this.state.loading_jadwal} active>

                  <Atable
                    head={ this.head_jadwal() }
                    data={ this.generate_rows() }
                    total_filter={ this.state.total_filter }
                    total_record={ this.state.total_record }
                    current_page={this.state.active_page}
                    search={this.state.search}
                    handleChange={this.handleChange}
                    limit={this.state.limit}
                  />

                </Skeleton>

              </div>

            </div>

            </div>
          </div>
        </div>

      </div>
    );
  }

}
