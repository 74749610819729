import React, { Component } from "react";
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import Admin from "./components/admin/Admin";
import Login from "./components/public/Login";
import Logout from "./components/public/Logout";
import Homepage from "./components/public/Homepage";
import Tentang from "./components/public/Tentang";
import Kontak from "./components/public/Kontak";
import Page from "./components/public/Page";
import Notfound from "./components/public/Notfound";
import IOS_info from "./components/public/Ios_info";
import News from "./components/public/News";


class App extends Component {
  render() {

    return (
      <Router basename ="/">
        <Switch>
          <Route path="/" exact component={Homepage} />
          <Route path="/tentang"  component={Tentang} />
          <Route path="/kontak"  component={Kontak} />
          <Route path="/page/:id"  exact component={Page} />
          <Route path="/ios"  exact component={IOS_info} />
          <Route path="/login"  exact component={Login} />
          <Route path="/logout" exact component={Logout} />
          <Route path="/admin" component={Admin} />
          <Route path="/news" component={News} />
          <Route path='*' component={Notfound} />
        </Switch>
      </Router>

    );
  }
}
export default App;
