import React from 'react';
import {Link} from "react-router-dom";
import axios, {post} from "axios";
import {Skeleton, message, notification, Icon} from 'antd';

const api_url = process.env.REACT_APP_API_URL;

export default class Add extends React.Component {
    constructor(props) {
        super(props);

        this.state = { kabupaten_id:'',nama_warkop:'',alamat_warkop:'',latitude:'',longitude:'', image: null, loading: false };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        // this.fileUpload   = this.fileUpload.bind(this);
        this.onChangeFile = this.onChangeFile.bind(this);
    }

    componentDidMount() {

        document.title = process.env.REACT_APP_APP_NAME + "::Warung Kopi";

        this.get_kabupaten_id_list();
					//##componentDidMount##//

    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        const name = target.name;

        this.setState({[name]: value});
    }

    onChangeFile(e) {
      this.setState({image:e.target.files[0]})
    }


    handleSubmit(event) {
        event.preventDefault();
        this.setState({loading: true});

        const header = {
            headers: {
                'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN),
                'content-type': 'multipart/form-data'
            }
        }

        const formData = new FormData();

        formData.append('kabupaten_id',this.state.kabupaten_id);
        formData.append('nama_warkop',this.state.nama_warkop);
        formData.append('alamat_warkop',this.state.alamat_warkop);
        formData.append('latitude',this.state.latitude);
        formData.append('longitude',this.state.longitude);
        formData.append('image',this.state.image);

        post(api_url+'warkop/add', formData, header).then((data)=>{
            if( typeof data == 'object' ){
              if( data.data.status == 'success' ){

                notification.open({
                  message: 'Berhasil',
                  description:'Data telah disimpan ke database!',
                });

                this.setState({loading: false});

                this.props.history.push("/admin/master_data/warung_kopi/list");

              }else{
                notification.open({
                    message: 'Error',
                    description: data.data.data,
                    icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
                  });
                  this.setState({loading: false});
              }
            }
        })

      }


					get_kabupaten_id_list(){
		        axios.get(api_url+"wilayah/kabupaten/get_by_provinsi/11").then(
		            response => {
		              this.setState({ kabupaten_id_list: response.data.data});
		          },
		          error => {
		            alert(error)
		          }

		        );
		      }

					select_option_for_kabupaten_id(){
		        if( this.state.kabupaten_id_list !== undefined && typeof this.state.kabupaten_id_list == "object"){
		          if( this.state.kabupaten_id_list.length > 0 ){
		            const res = (
		              <select name="kabupaten_id" className="form-control" onChange={this.handleChange} required>
                    <option value="0" key={ Math.random() }>Pilih</option>
                    {this.state.kabupaten_id_list.map(( value ) =>
		                  <option value={value.kabupaten_id} key={ Math.random() } selected= {(value.kabupaten_id == this.state.kabupaten_id)?true:false}>{value.kabupaten}</option>
		                )}
		              </select>
		            );

		            return res;

		          }
		        }
		      }


//##cst_function##/

    render() {

        return (
            <div>
                <div className="col-12 grid-margin">

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Master Data</li>
                            <Link className="breadcrumb-item" to="/admin/master_data/warung_kopi/list">Warung Kopi</Link>
                            <li className="breadcrumb-item active">Tambah</li>
                        </ol>
                    </nav>

                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="display-4 primary-color text-center">Tambah Warung Kopi</h4>
                            <br />

                            {/* Form Input */}
                            <Skeleton loading={this.state.loading} active>
                            <form onSubmit={this.handleSubmit}>
                                <div className="row">


					{/** Kabupaten **/}
					<div className="col-md-12">
            <div className="form-group">
              <label>Kabupaten/Kota<span className="text-danger">*</span></label>
								{ this.select_option_for_kabupaten_id() }
						</div>
          </div>


				{/** Nama Warung Kopi **/}
				<div className="col-md-12">
                  <div className="form-group">
                    <label>Nama Warung Kopi<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Nama Warung Kopi" name="nama_warkop" value={this.state.nama_warkop} onChange={this.handleChange} required/>
                  </div>
                </div>


				{/** Alamat **/}
				<div className="col-md-12">
                  <div className="form-group">
                    <label>Alamat<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Alamat" name="alamat_warkop" value={this.state.alamat_warkop} onChange={this.handleChange} required/>
                  </div>
                </div>


				{/** Latitude **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Latitude<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Latitude" name="latitude" value={this.state.latitude} onChange={this.handleChange} required/>
                  </div>
                </div>


				{/** Longitude **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Longitude<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Longitude" name="longitude" value={this.state.longitude} onChange={this.handleChange} required/>
                  </div>
                </div>

      {/** Upload File  **/}
      <div className="col-md-6">
        <div className="form-group">
          <label>Upload Gambar</label><br/>
          <input type="file" className="form-control-file" onChange={this.onChangeFile} />
        </div>
      </div>

			{/*##form_input##*/}

                                </div>
                                <button stype="submit" className="btn btn-full primary-button">Simpan</button>
                            </form>
                          </Skeleton>


                        </div>
                    </div>



                </div>

            </div>
        );
    }

}
