import React from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import {Skeleton, message, notification, Icon, Modal} from 'antd';
import moment from 'moment';
import 'moment/locale/id';

const api_url = process.env.REACT_APP_API_URL;

export default class Responden extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.id,
            judul:'',
            tanggal_mulai:'',
            tanggal_selesai:'',
            deskripsi:'',
            status:'',
            pertanyaan: '',
            pertanyaan_input: '',
            jawaban: [],
            created_at: '',
            updated_at: '',
            jumlah_responden: 0,
            jawaban_responden: '',
            all_data: '',
            loading: true
        };

    }

    componentDidMount() {

        document.title = process.env.REACT_APP_APP_NAME + "::Survei";
        this.get_data();

					//##componentDidMount##//

    }

  tanggal_mulai = date => {
    this.setState({
      tanggal_mulai: date
    });
  };

  tanggal_selesai = date => {
    this.setState({
      tanggal_selesai: date
    });
  };

  get_data(){
      var header = {
        headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
      };
      axios.get( api_url+'survei/responden/'+this.state.id, header)
      .then(
        response => response.data,
        error => { alert( error ) }
      )
      .then((data) => {

        if( typeof data == 'object' ){
          if( data.status === 'success' ){
            this.setState({
              judul: data.data[0].judul,
              tanggal_mulai: new Date(data.data[0].tanggal_mulai),
      tanggal_selesai:new Date(data.data[0].tanggal_selesai),
      deskripsi: data.data[0].deskripsi,
              status: data.data[0].status,
              pertanyaan: data.data[0].pertanyaan,
                created_at: new Date( data.data[0].created_at ),
                updated_at: new Date(data.data[0].updated_at),
                jawaban_responden: data.data.jawaban,
                jumlah_responden: data.data.responden,
                all_data: data.data,
              loading: false
          });
          }else{
            message.error( data.data[0].data );
          }

        }
      })
    }


    list_pertanyaan(){
      if( this.state.pertanyaan.length > 0 ){

        let list = this.state.pertanyaan.map(( value ) =>
          <li className="soal-suvei-list" key={ Math.random() } >
            {value.pertanyaan}
            {this.list_pilihan_jawaban( value.pilihan_jawaban , value._id)}
          </li>
        );

        return (<ol className="list-pertanyaan-survey">{list}</ol>);
      }
    }

    list_pilihan_jawaban( jawaban, pertanyaan_id ){
      let res = [];
      let jawaban_r = this.state.jawaban_responden;

      var value = 0;
      for( let j in jawaban ){
        var jumlah = 0;
        var persen = 0;
        for( let k in jawaban[j] ){
          for( let l in jawaban_r){
            for( let m in jawaban_r[l] ){
              if( jawaban_r[l].pertanyaan_id == pertanyaan_id && m == k ){
                persen = (100 * parseFloat( jawaban_r[l][k]) ) / parseInt( this.state.jumlah_responden );
              }
            }
          }
          res.push( <li key={ Math.random() } >{jawaban[j][k]}
          <div className="progress" style={{height: "15px"}} >
            <div className="progress-bar progress-bar-striped bg-danger" role="progressbar" style={{width: persen+"%"}} aria-valuenow="10" aria-valuemin="0" aria-valuemax="100">{ persen.toFixed(2) }%</div>
          </div>
          </li>);
        }
      }
      return (<ol type="a" className="list-jawaban-survey">{res}</ol>);
    }

    render() {

      let status = [];
      if( this.state.status == 'draft' ){
        status.push(<span key={ Math.random() } className="badge badge-warning">DRAFT</span>);
      }else{
        status.push(<span key={ Math.random() } className="badge badge-info">PUBLISH</span>);
      }


        return (
          <div className="row">
          <div className="col-12 grid-margin">

            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item">Manajemen Survei</li>
                    <Link className="breadcrumb-item" to="/admin/manajemen_survei/list">Daftar Survei</Link>
                    <Link className="breadcrumb-item" to={`/admin/manajemen_survei/detail/${this.state.id}`}>Detail Survei</Link>
                    <li className="breadcrumb-item active">Hasil</li>
                </ol>
            </nav>

          </div>


          <Skeleton loading={this.state.loading} active>
              <div className="col-md-8 grid-margin">
                  <div className="card">
                      <div className="card-body">
                        <h4 className="display-4 primary-color">{this.state.judul}</h4>
                        <br />

                        <div className="paragraph" dangerouslySetInnerHTML={{ __html: this.state.deskripsi }} />

                        <br/>
                        <p className="text-right primary-color">Jumlah pertanyaan: {this.state.pertanyaan.length}</p>

                        {this.list_pertanyaan()}

                      </div>
                  </div>

              </div>

              <div className="col-md-4 grid-margin">
                <div className="card">
                  <div className="card-body">
                    {/* Tanggal */}

                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Tanggal Mulai Survei</label>
                            <input type="text" className="form-control" value={ moment(this.state.tanggal_mulai).format("D MMMM YYYY") } disabled/>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Tanggal Berakhir Survei</label>
                            <input type="text" className="form-control" value={ moment(this.state.tanggal_selesai).format("D MMMM YYYY") } disabled/>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Status</label><br/>
                            {status}
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Dibuat { moment(this.state.created_at, "YYYYMMDD").fromNow() }</label> <br/>
                            <label>Diperbaharui { moment(this.state.updated_at, "YYYYMMDD").fromNow() }</label>
                        </div>

                    </div>

                    <div className="col-md-12 admin-panel-primary">
                      <h4 className="head">Jumlah Responden</h4>
                      <h1 className="text-center text-white">{this.state.jumlah_responden}</h1>
                    </div>

                    {/*
                    <Link className="btn btn-warning btn-block" to={`/admin/manajemen_survei/print/${this.state.id}`}><i className="fas fa-file-pdf"></i> Cetak</Link>
                    */}
                    
                  </div>
                  </div>
              </div>
              </Skeleton>
      </div>
        );
    }

}
