import React from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import {Skeleton, message, notification, Icon} from 'antd';

const api_url = process.env.REACT_APP_API_URL;

export default class Add extends React.Component {
    constructor(props) {
        super(props);

        this.state = { kode:'',maskapai_id:'',hari:'',rute:'', };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {

        document.title = process.env.REACT_APP_APP_NAME + "::Jadwal Penerbangan";

    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        const name = target.name;

        this.setState({[name]: value});
    }


    handleSubmit(event) {
        event.preventDefault();

        message.loading('Loading...', 2.5);

        var form_data =
        {
          kode:this.state.kode,
 					maskapai_id:this.state.maskapai_id,
 					hari:this.state.hari,
 					rute:this.state.rute,
 					//##field##:##value##//
        };

        var header = {
          headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };

        axios.post( api_url+'bandara/jadwal_penerbangan/add', form_data, header
        ).then(
          response => response.data,
          error => {
            alert(error)
          }
        )
        .then((data) => {
          if( data.status === 'success' ){

            notification.open({
              message: 'Berhasil',
              description:'Data telah disimpan ke database!',
            });

            this.props.history.push("/admin/master_data/jadwal_penerbangan/list");

          }else{
            notification.open({
                message: 'Error',
                description: data.data,
                icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
              });
          }

        })
      }

    render() {

        return (
            <div>
                <div className="col-12 grid-margin">

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Master Data</li>
                            <Link className="breadcrumb-item" to="/admin/master_data/jadwal_penerbangan/list">Jadwal Penerbangan</Link>
                            <li className="breadcrumb-item active">Tambah</li>
                        </ol>
                    </nav>

                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="display-4 primary-color text-center">Tambah Jadwal Penerbangan</h4>
                            <br />

                            {/* Form Input */}
                            <form onSubmit={this.handleSubmit}>
                                <div className="row">


				{/** bandara_id **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>bandara_id<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="bandara_id" name="kode" value={this.state.kode} onChange={this.handleChange} required/>
                  </div>
                </div>


				{/** Maskapai **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Maskapai<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Maskapai" name="maskapai_id" value={this.state.maskapai_id} onChange={this.handleChange} required/>
                  </div>
                </div>


				{/** Hari **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Hari</label>
                    <input type="text" className="form-control" placeholder="Hari" name="hari" value={this.state.hari} onChange={this.handleChange} />
                  </div>
                </div>


				{/** Rute **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Rute<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Rute" name="rute" value={this.state.rute} onChange={this.handleChange} required/>
                  </div>
                </div>

			{/*##form_input##*/}

                                </div>
                                <button stype="submit" className="btn btn-full primary-button">Simpan</button>
                            </form>


                        </div>
                    </div>



                </div>

            </div>
        );
    }

}
