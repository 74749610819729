import React from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import {Skeleton, Icon, message, notification} from 'antd';

const api_url = process.env.REACT_APP_API_URL;

export default class Password_users extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user_id: this.props.match.params.id,
            password: '',
            verify: '',
            email: '',
            loading: true
        };

        this.handleChange = this
            .handleChange
            .bind(this);
        this.handleSubmit = this
            .handleSubmit
            .bind(this);
    }

    componentDidMount() {
        document.title = process.env.REACT_APP_APP_NAME + "::Users Password";

        this.get_users();
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        const name = target.name;

        this.setState({[name]: value});
    }

    get_users() {
        let header = {
            headers: {
                'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN)
            }
        };
        axios
            .get(api_url + 'acl/users/get_users/' + this.state.user_id, header)
            .then(response => response.data, error => {
                alert(error)
            })
            .then((data) => {
                if (typeof data == 'object') {
                    if (data['status'] === 'success') {
                        this.setState({email: data.data.email, loading: false});
                    } else {
                        message.error(data['data']);
                    }

                }
            })
    }

    handleSubmit(event) {
        event.preventDefault();

        if( this.state.password !== this.state.verify ){
          notification.open({
              message: 'Error',
              description: "Password dengan verifikasi password tidak sama!",
              icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
            });
        }else{
          let form_data = {
              email: this.state.email,
              password: this.state.password,
              password_verify: this.state.verify
          };

          let header = {
              headers: {
                   'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN)
              }
          };

          axios
              .put(api_url + 'acl/users/change_password/' + this.state.user_id, form_data, header)
              .then(response => response.data)
              .then((data) => {

                  if (data['status'] === 'success') {

                      notification.open({message: 'Berhasil', description: 'Data telah disimpan ke database!'});

                      this
                          .props
                          .history
                          .push("/admin/acl/users");
                      message.destroy();
                  } else {
                      message.error(data['data']);
                  }

              })
        }
    }

    render() {
        return (
            <div>
                <div className="col-12 grid-margin">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">ACL</li>
                            <Link className="breadcrumb-item" to="/admin/acl/users">Pengelola Aplikasi</Link>
                            <li className="breadcrumb-item active">Edit Password</li>
                        </ol>
                    </nav>
                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="display-4 primary-color text-center">Edit Password</h4>
                            <br/>

                            <Skeleton loading={this.state.loading} active>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="row">

                                        <div className="col-md-12">
                                            {/* Input Email */}
                                            <div className="form-group">
                                                <label>Email<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Email"
                                                    name="email"
                                                    value={this.state.email }
                                                    onChange={this.handleChange}/>
                                            </div>


                                        </div>
                                        <div className="col-md-12">
                                            {/* Input New Password */}
                                            <div className="form-group">
                                                <label>Password Baru<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Password"
                                                    name="password"
                                                    value={this.state.password}
                                                    onChange={this.handleChange}
                                                    required/>
                                            </div>

                                            {/* Input Verifikasi Password */}
                                            <div className="form-group">
                                                <label>Verifikasi Password<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Verifikasi Password"
                                                    name="verify"
                                                    value={this.state.verify}
                                                    onChange={this.handleChange}
                                                    required/>
                                            </div>
                                        </div>

                                    </div>
                                    <button stype="submit" className="btn btn-full primary-button">Simpan</button>
                                </form>
                            </Skeleton>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

}
