import React from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import Atable from "../../../lib/Atable";
import { message, notification, Icon, Modal, Tooltip, Skeleton } from 'antd';

import moment from 'moment';
import 'moment/locale/id';
import Auth from "../../../helper/Authentication";
const api_url = process.env.REACT_APP_API_URL;
const confirm = Modal.confirm;

export default class Detail extends React.Component {
  constructor(props) {
    super(props);
    Auth.is_access( window.location.pathname )
    this.state = {
      id: this.props.match.params.id,
      pasar:'',
      kabupaten:'',
      tanggal: new Date(),

      nama: "",
      satuan: "",
      harga: "",

      daftar_harga: null
    };

    this.handleChange   = this.handleChange.bind(this);
    this.handleSubmit   = this.handleSubmit.bind(this);
  }

  componentDidMount(){
     document.title = process.env.REACT_APP_APP_NAME+"::Master Data";
     this.get_data();

  }



  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

     this.setState({
       [name]: value
     });

  }

  reset_input(){
    this.setState({
      nama: "",
      satuan: "",
      harga: ""
    });
  }

  handleSubmit(event) {
    event.preventDefault();

    let form_data =
    {
      nama: this.state.nama,
      satuan: this.state.satuan,
      harga: this.state.harga

    };

    let header = {
      headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
    };

    axios.post( api_url+'harga_pangan/daftar_harga/add/'+this.state.id, form_data, header
    ).then(response => response.data)
    .then((data) => {

      if( data['status'] === 'success' ){

        notification.open({
          message: 'Berhasil',
          description:'Data telah disimpan ke database!',
        });

        this.reset_input();
        this.componentDidMount();

      }else{
        message.error( data['data'] );
      }

    })
  }

  get_data(){
    var header = {
      headers: {
        'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
    };

    axios.get( api_url+'harga_pangan/get/'+this.state.id, header)
    .then(
      response => response.data,
      error    => { alert(error) }
    )
    .then((data) => {
      if( typeof data == 'object' ){
        if( data['status'] === 'success' ){

          this.setState({
            pasar: data.data[0].pasar,
            tanggal: data.data[0].tanggal,
            kabupaten: data.data[0].kabupaten[0].kabupaten,
            daftar_harga: data.data[0].daftar_harga,

          });

        }else{
          notification.open({
            message: 'API Error',
            description: data.data,
            icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
          });
        }
      }

    })
  }

  list_bahan_pokok(){
    const data = this.state.daftar_harga;

    if( typeof data == "object" && data !== null){

      const res = data.map((value, index) =>
          <tr key={ Math.random() }>
            <td>{value.nama}</td>
            <td>{value.satuan}</td>
            <td>{value.harga}</td>
            <td><a href="javascript:undefined" className="text-danger" onClick={() => this.hapusBahanPokok(value._id, value.nama)}>Hapus</a></td>
          </tr>
      );

      return (
        <table className="table table-sm">
          <thead>
            <tr>
              <th scope="col">Nama</th>
              <th scope="col">Satuan</th>
              <th scope="col">Harga</th>
              <th scope="col">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {res}
          </tbody>
        </table>
      );
    }
  }

  hapusBahanPokok(bahan_id, nama){
    let id = this.state.id;
    let t = this;

    const header = {
      headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
    };
    confirm({
      title: "Hapus "+nama+"?",
      content: "Data akan terhapus secara permanen",
      onOk() {
        axios.delete(api_url+"harga_pangan/daftar_harga/delete/"+id+"/"+bahan_id, header)
         .then(
           response => {
             console.log( response );

             notification.open({
               message: 'Berhasil',
               description:'Data telah terhapus!'
             });
              return t.componentDidMount()
          },
          error => {
            alert(error)
          }
        )

        ;
      }
    });
  }

  delete(id){
       const e     = this;

       const header = {
         headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
       };
       confirm({
         title: "Apakah anda ingin menghapus data?",
         content: "Akan menghapus semua data pada "+this.state.pasar+" tanggal "+moment(this.state.tanggal).format("D MMMM YYYY"),
         onOk() {
           axios.delete(api_url+"harga_pangan/delete/"+id, header)
            .then(
              response => {
                notification.open({
                  message: 'Berhasil',
                  description:'Data telah berhasil dihapus!'
                });
                e.props.history.push("/admin/master_data/harga_pangan/list/");
             },
             error => {
               alert(error)
             }
           )

           ;
         }
       });
     }

  render() {
    return (
      <div>
      <div className="col-12 grid-margin">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item primary-color">Master Data</li>
            <Link className="breadcrumb-item" to="/admin/master_data/harga_pangan/list">Harga Pangan</Link>
            <li className="breadcrumb-item active">Detail</li>
          </ol>
        </nav>
      </div>

        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">

            <div className="row">
              <div className="col-md-6">
                <h4 className="display-4 primary-color">Informasi Pasar</h4>

                <Skeleton loading={this.state.loading} active>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Nama Pasar<span className="text-danger">*</span></label>
                      <input type="text" className="form-control" value={this.state.pasar} readOnly/>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Kabupaten/Kota<span className="text-danger">*</span></label>
                      <input type="text" className="form-control" value={this.state.kabupaten} readOnly/>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Tanggal<span className="text-danger">*</span></label>
                      <input type="text" className="form-control" value={moment(this.state.tanggal).format("D MMMM YYYY")} readOnly/>
                    </div>
                  </div>

                </Skeleton>

              </div>

              <div className="col-md-6">
                <h4 className="display-4 primary-color">Tambah</h4>

                <form onSubmit={this.handleSubmit}>
                <div className="row">
                  {/* Nama */}
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Nama<span className="text-danger">*</span></label>
                      <input type="text" className="form-control" placeholder="Nama" name="nama" value={this.state.nama} onChange={this.handleChange} required/>
                    </div>
                  </div>

                  {/* Satuan */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Satuan<span className="text-danger">*</span></label>
                      <input type="text" className="form-control" placeholder="Satuan" name="satuan" value={this.state.satuan} onChange={this.handleChange} required/>
                    </div>
                  </div>

                  {/* Harga */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Harga<span className="text-danger">*</span></label>
                      <input type="number" className="form-control" placeholder="Harga" name="harga" value={this.state.harga} onChange={this.handleChange} required/>
                    </div>
                  </div>



                    <button stype="submit" className="btn btn-full primary-button">Tambah</button>
                  </div>
                </form>

              </div>

              {/* Jadwal list */}
              <div className="col-md-12">
                <hr className="divider soft"/>
                <h4 className="display-4 primary-color">Bahan Pokok</h4>

                {this.list_bahan_pokok()}

                <hr/>
                <a href="javascript:undefined" className="btn btn-danger btn-sm" onClick={() => this.delete(this.state.id)}>Hapus Semua Data</a>

              </div>

            </div>

            </div>
          </div>
        </div>

      </div>
    );
  }

}
