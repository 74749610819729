import React from 'react'

class Aform extends React.Component {


  render() {
    var col         = "col-md-"+this.props.col;
    const type      = this.props.type;

    var input       = [];
    if( type === "text" || type === "email" || type === "date"){
      if( this.props.required === true ){
        input.push(
          [
              <div className="form-group" key={ this.props.name.replace(/ /g, " ")+"29819763457" }>
                <label>{this.props.label}<span className="text-danger">*</span></label>
                <input type={type} className="form-control" placeholder={this.props.placeholder} name={this.props.name} value={this.props.value} onChange={this.props.handleChange}  required/>
              </div>
          ]
        )
      }else{
        input.push(
          [
              <div className="form-group" key={ this.props.name.replace(/ /g, " ")+"298197636457" }>
                <label>{this.props.label}</label>
                <input type={type} className="form-control" placeholder={this.props.placeholder} name={this.props.name} value={this.props.value} onChange={this.props.handleChange} />
              </div>
          ]
        )
      }

    }


    return(
      <div className={col} key={ this.props.name.replace(/ /g, " ")+"2981976368756" }>
          {input}
      </div>
    )
  }

}

export default Aform
