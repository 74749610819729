import React from 'react';
import axios from "axios";
import { message, notification, Skeleton } from 'antd';
import Aform from "../../helper/Aform";

const api_url = process.env.REACT_APP_API_URL;

export default class Edit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading       : true,
      inputs        : "",
      field_name    : "",
      submit        : "",
      FormUrl       : "",
      name          : ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submodule_id = this.props.match.params.submodule_id;
    this.item_id      = this.props.match.params.item_id;
  }

  componentDidMount(){
     document.title = process.env.REACT_APP_APP_NAME+"::Edit";
     this.load_form();
     this.get_item();
   }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

     this.setState({
       [name]: value
     });
  }

  handleSubmit(event) {
    event.preventDefault();

    var form_data = {};
    for( var x in this.state ){
      if(
        x !== "field_name"
        && x !== "inputs"
        && x !== "loading"
        && x !== "submit"
        && x !== "FormUrl"
        && x !== "name"
      ){
        form_data[x] = this.state[x]
      }
    }

    var header = {
      headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
    };

    axios.put( api_url+'form/edit_submodule_item/'+this.submodule_id+"/"+this.item_id, form_data, header
    ).then(
      response => response.data,
      error => {
        alert(error)
      }
    )
    .then((data) => {
      if( data['status'] === 'success' ){

        notification.open({
          message: 'Berhasil',
          description:'Data telah diperbaharui!',
        });

        this.props.history.push("/admin/form/list/"+this.submodule_id);

      }else{
        message.error( data['data'] );
      }

    })

  }

  load_form(){
    var header = {
      headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
    };
    axios.get( api_url+'form/get_submodule_form/'+this.submodule_id, header)
    .then(
      response => response.data,
      error => { alert( error ) }
    )
    .then((data) => {
      if( typeof data == 'object' ){
        if( data['status'] === 'success' ){
          this.setState({
            name          : data.data.name,
            field_name    : data.data.name,
            inputs        : data.data.inputs,
            loading       : false,
            submit        : data.data.submit,
            FormUrl       : data.data.url
          });
        }else{
          message.error( data['data'] );
        }

      }
    })
  }

  get_item(){
    var header = {
      headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
    };
    axios.get( api_url+'form/get_submodule_item/'+this.submodule_id+'/'+this.item_id, header)
    .then(
      response => response.data,
      error => { alert( error ) }
    )
    .then((data) => {
      if( typeof data == 'object' ){
        if( data['status'] === 'success' ){
          for( var x in data.data ){
            this.setState(
              data.data
            );
            console.log(x)
          }
        }else{
          message.error( data['data'] );
        }

      }
    })
  }

  generate_form(){
    const data = this.state.inputs;

    if( data.length > 0 ){
      const res = data.map((value, index) =>
        <Aform
          type={value.type}
          name={value.field_name}
          label={value.label}
          placeholder={value.placeholder}
          value={this.state[value.field_name]}
          col={value.col}
          required={value.required}
          handleChange={this.handleChange}
          key={ value.field_name }
          />
      );
      return res;
    }
  }


  render() {
    return (
      <div>
      <Skeleton loading={this.state.loading} active>
      <div className="col-12 grid-margin">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active">Form</li>
            <li className="breadcrumb-item active">Edit {this.state.name}</li>
          </ol>
        </nav>
      </div>

        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
            <h4 className="display-4 primary-color text-center">Edit {this.state.name}</h4>
            <br />

            <form onSubmit={this.handleSubmit}>
              <div className="row">
              { this.generate_form() }
              </div>
              <button type="submit" className="btn btn-full primary-button">{this.state.submit}</button>
            </form>


            </div>
          </div>
        </div>
        </Skeleton>
      </div>
    );
  }

}
