import React from 'react';
import {Link} from "react-router-dom";
import axios, {post} from "axios";
import {Skeleton, message, notification, Icon} from 'antd';

const api_url = process.env.REACT_APP_API_URL;

export default class Add extends React.Component {
    constructor(props) {
        super(props);

        this.state = { kabupaten_id:0,kecamatan_id:'',nama_rumah_sakit:'',alamat:'',telepon:'',akreditasi:'',kelas:'',jam_buka:'',jam_tutup:'',latitude:'',longitude:'', jenis: '', image: "", loading: false };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeFile = this.onChangeFile.bind(this);
    }

    componentDidMount() {

        document.title = process.env.REACT_APP_APP_NAME + "::Rumah Sakit";

        this.get_kabupaten_id_list();
					this.get_kecamatan_id_list();
					//##componentDidMount##//

    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        const name = target.name;

        this.setState({[name]: value}, () => {
          this.get_kecamatan_id_list();
        });
    }

    onChangeFile(e) {
      this.setState({image:e.target.files[0]})
    }


    handleSubmit(event) {
        event.preventDefault();

        this.setState({loading: true});


        const header = {
            headers: {
                'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN),
                'content-type': 'multipart/form-data'
            }
        }

        const formData = new FormData();

        formData.append('kabupaten_id',this.state.kabupaten_id);
        formData.append('kecamatan_id',this.state.kecamatan_id);
        formData.append('nama_rumah_sakit',this.state.nama_rumah_sakit);
        formData.append('alamat',this.state.alamat);
        formData.append('telepon',this.state.telepon);
        formData.append('akreditasi',this.state.akreditasi);
        formData.append('kelas',this.state.kelas);
        formData.append('jenis',this.state.jenis);
        formData.append('jam_buka',this.state.jam_buka);
        formData.append('jam_tutup',this.state.jam_tutup);
        formData.append('latitude',this.state.latitude);
        formData.append('longitude',this.state.longitude);
        formData.append('image',this.state.image);

        post(api_url+'rumah_sakit/add', formData, header).then((data)=>{
            if( typeof data == 'object' ){
              if( data.data.status == 'success' ){

                notification.open({
                  message: 'Berhasil',
                  description:'Data telah disimpan ke database!',
                });

                this.setState({loading: false});

                this.props.history.push("/admin/master_data/rumah_sakit/list");

              }else{
                notification.open({
                    message: 'Error',
                    description: data.data.data,
                    icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
                  });
                  this.setState({loading: false});
              }
            }
        })

        // var header = {
        //   headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        // };
        //
        // axios.post( api_url+'rumah_sakit/add', form_data, header
        // ).then(
        //   response => response.data,
        //   error => {
        //     alert(error)
        //   }
        // )
        // .then((data) => {
        //   if( typeof data == 'object' ){
        //     if( data.status === 'success' ){
        //
        //       notification.open({
        //         message: 'Berhasil',
        //         description:'Data telah disimpan ke database!',
        //       });
        //
        //       this.setState({loading: false});
        //
        //       this.props.history.push("/admin/master_data/rumah_sakit/list");
        //
        //     }else{
        //       notification.open({
        //           message: 'Error',
        //           description: data.data,
        //           icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
        //         });
        //         this.setState({loading: false});
        //     }
        //   }
        //
        // })
      }


					get_kabupaten_id_list(){
		        axios.get(api_url+"wilayah/kabupaten/get_by_provinsi/11").then(
		            response => {
		              this.setState({ kabupaten_id_list: response.data.data});
		          },
		          error => {
		            alert(error)
		          }

		        );
		      }

					select_option_for_kabupaten_id(){
		        if( this.state.kabupaten_id_list !== undefined && typeof this.state.kabupaten_id_list == "object"){
		          if( this.state.kabupaten_id_list.length > 0 ){
		            const res = (
		              <select name="kabupaten_id" className="form-control" onChange={this.handleChange} required>
                    <option value="0" key={ Math.random() }>Pilih</option>
                    {this.state.kabupaten_id_list.map(( value ) =>
		                  <option value={value.kabupaten_id} key={ Math.random() } selected= {(value.kabupaten_id == this.state.kabupaten_id)?true:false}>{value.kabupaten}</option>
		                )}
		              </select>
		            );

		            return res;

		          }
		        }
		      }



					get_kecamatan_id_list(){
		        axios.get(api_url+"wilayah/kecamatan/get_by_kabupaten/"+this.state.kabupaten_id).then(
		            response => {
		              this.setState({ kecamatan_id_list: response.data.data});
		          },
		          error => {
		            alert(error)
		          }

		        );
		      }

					select_option_for_kecamatan_id(){
		        if( this.state.kecamatan_id_list !== undefined && typeof this.state.kecamatan_id_list == "object"){
		          if( this.state.kecamatan_id_list.length > 0 ){
		            const res = (
		              <select name="kecamatan_id" className="form-control" onChange={this.handleChange} required>
		                {this.state.kecamatan_id_list.map(( value ) =>
		                  <option value={value.kecamatan_id} key={ Math.random() } selected= {(value.kecamatan_id == this.state.kecamatan_id)?true:false}>{value.kecamatan}</option>
		                )}
		              </select>
		            );

		            return res;

		          }
		        }
		      }


//##cst_function##/

    render() {

        return (
            <div>
                <div className="col-12 grid-margin">

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Master Data</li>
                            <Link className="breadcrumb-item" to="/admin/master_data/rumah_sakit/list">Faskes</Link>
                            <li className="breadcrumb-item active">Tambah</li>
                        </ol>
                    </nav>

                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="display-4 primary-color text-center">Tambah Fasilitas Kesehatan</h4>
                            <br />

                            {/* Form Input */}
                            <Skeleton loading={this.state.loading} active>
                            <form onSubmit={this.handleSubmit}>
                                <div className="row">


					{/** Kabupaten **/}
					<div className="col-md-6">
            <div className="form-group">
              <label>Kabupaten/Kota<span className="text-danger">*</span></label>
								{ this.select_option_for_kabupaten_id() }
						</div>
          </div>


					{/** Kecamatan **/}
					<div className="col-md-6">
            <div className="form-group">
              <label>Kecamatan<span className="text-danger">*</span></label>
								{ this.select_option_for_kecamatan_id() }
						</div>
          </div>


				{/** Nama Rumah Sakit **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Nama<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Nama Rumah Sakit" name="nama_rumah_sakit" value={this.state.nama_rumah_sakit} onChange={this.handleChange} required/>
                  </div>
                </div>

        {/** Jenis **/}
        <div className="col-md-6">
          <div className="form-group">
            <label>Jenis<span className="text-danger">*</span></label>
              <select name="jenis" className="form-control" onChange={this.handleChange} required>
                <option value="" key={ Math.random() } selected= {(this.state.jenis == "")?true:false}>Pilih</option>
                <option value="rs" key={ Math.random() } selected= {(this.state.jenis == "rs")?true:false}>Rumah Sakit</option>
                <option value="puskesmas" key={ Math.random() } selected= {(this.state.jenis == "puskesmas")?true:false}>Puskesmas</option>
                <option value="apotik" key={ Math.random() } selected= {(this.state.jenis == "apotik")?true:false}>Apotik</option>
              </select>
          </div>
        </div>


				{/** Alamat **/}
				<div className="col-md-12">
                  <div className="form-group">
                    <label>Alamat<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Alamat" name="alamat" value={this.state.alamat} onChange={this.handleChange} required/>
                  </div>
                </div>


				{/** Telepon **/}
				<div className="col-md-12">
                  <div className="form-group">
                    <label>Telepon</label>
                    <input type="text" className="form-control" placeholder="Telepon" name="telepon" value={this.state.telepon} onChange={this.handleChange}/>
                  </div>
                </div>


				{/** Akreditasi **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Akreditasi</label>
                    <input type="text" className="form-control" placeholder="Akreditasi" name="akreditasi" value={this.state.akreditasi} onChange={this.handleChange} />
                  </div>
                </div>


				{/** Kelas **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Kelas</label>
                    <input type="text" className="form-control" placeholder="Kelas" name="kelas" value={this.state.kelas} onChange={this.handleChange} />
                  </div>
                </div>


				{/** Jam Buka **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Jam Buka</label>
                    <input type="text" className="form-control" placeholder="Jam Buka" name="jam_buka" value={this.state.jam_buka} onChange={this.handleChange} />
                  </div>
                </div>


				{/** Jam Tutup **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Jam Tutup</label>
                    <input type="text" className="form-control" placeholder="Jam Tutup" name="jam_tutup" value={this.state.jam_tutup} onChange={this.handleChange} />
                  </div>
                </div>


				{/** Latitude **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Latitude<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Latitude" name="latitude" value={this.state.latitude} onChange={this.handleChange} required/>
                  </div>
                </div>


				{/** Longitude **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Longitude<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Longitude" name="longitude" value={this.state.longitude} onChange={this.handleChange} required/>
                  </div>
                </div>

        {/** Upload File  **/}
        <div className="col-md-6">
          <div className="form-group">
            <label>Upload Gambar</label><br/>
            <input type="file" className="form-control-file" onChange={this.onChangeFile} />
          </div>
        </div>

			{/*##form_input##*/}

                                </div>
                                <button stype="submit" className="btn btn-full primary-button">Simpan</button>
                            </form>
                          </Skeleton>


                        </div>
                    </div>



                </div>

            </div>
        );
    }

}
