import React from 'react'
import axios, { post } from 'axios';
import {Link} from "react-router-dom";
import {Skeleton, message, notification, Icon} from 'antd';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const api_url = process.env.REACT_APP_API_URL;

class Add extends React.Component {

  constructor(props) {
    super(props);

    this.state = { nama: "", logo :null, loading: false };

    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onChangeFile = this.onChangeFile.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.fileUpload = this.fileUpload.bind(this)
  }

  componentDidMount() {
      document.title = process.env.REACT_APP_APP_NAME + "::Maskapai";
  }

  tanggal = date => {
    this.setState({
      tanggal: date
    });
  };

  onFormSubmit(e){
    e.preventDefault();
    this.fileUpload(this.state.logo).then((data)=>{

      if( typeof data == 'object' ){
        if( data.data.status === 'success' ){

          notification.open({
            message: 'Berhasil',
            description:'Data telah disimpan ke database!',
          });

          this.setState({loading: false});

          this.componentDidMount();
          this.props.history.push("/admin/master_data/maskapai/list");

        }else{
          notification.open({
              message: 'Error',
              description: data.data.data,
              icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
            });
            this.setState({loading: false});
        }
      }
    })
  }

  handleChange(event) {
      const target = event.target;
      const value = target.type === 'checkbox'
          ? target.checked
          : target.value;
      const name = target.name;

      this.setState({[name]: value});
  }

  onChangeFile(e) {
    this.setState({logo:e.target.files[0]})
  }

  fileUpload(file){
    const url = api_url+'bandara/maskapai/add';
    const formData = new FormData();
    formData.append('logo',file);
    formData.append('nama',this.state.nama);

    const config = {
        headers: {
            'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN),
            'content-type': 'multipart/form-data'
        }
    }
    return  post(url, formData,config)
  }


  render() {
    return (
      <div>
          <div className="col-12 grid-margin">
              <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                      <li className="breadcrumb-item">Master Data</li>
                      <Link className="breadcrumb-item" to="/admin/master_data/maskapai/list">Maskapai</Link>
                      <li className="breadcrumb-item active">Tambah</li>
                  </ol>
              </nav>

          </div>

          <div className="col-12 grid-margin">
              <div className="card">
                  <div className="card-body">
                      <h4 className="display-4 primary-color text-center">Tambah Maskapai</h4>
                      <br />

                      {/* Form Input */}
                      <Skeleton loading={this.state.loading} active>
                      <form onSubmit={this.onFormSubmit}>
                          <div className="row">

                      {/** Nama Maskapai **/}
                      <div className="col-md-6">
                                <div className="form-group">
                                  <label>Nama Maskapai<span className="text-danger">*</span></label>
                                  <input type="text" className="form-control" placeholder="Nama Maskapai" name="nama" value={this.state.nama} onChange={this.handleChange} required/>
                                </div>
                              </div>



    {/** Upload File  **/}
    <div className="col-md-6">
      <div className="form-group">
        <label>Upload Logo<span className="text-danger">*</span></label><br/>
        <input type="file" className="form-control-file" onChange={this.onChangeFile} />
      </div>
    </div>



                          </div>
                          <button stype="submit" className="btn btn-full primary-button">Simpan</button>
                      </form>
                    </Skeleton>


                  </div>
              </div>



          </div>

      </div>
   )
  }
}



export default Add
