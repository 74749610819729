import React from 'react';
import axios from "axios";
import { message, Icon, notification, Skeleton } from 'antd';

const api_url = process.env.REACT_APP_API_URL;

export default class Edit_profil extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user_id: localStorage.getItem('id'),
      first_name: "",
      last_name: "",
      email: "",
      username: "",
      new_password: "",
      new_password_verify: "",
      phone: "",
      loading: true
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(){
     document.title = process.env.REACT_APP_APP_NAME+"::Edit Profil";
     this.get_user();
  }

  get_user(){
    var header = {
      headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN)  }
    };
    axios.get( api_url+'acl/users/get_users/'+this.state.user_id, header)
    .then(
      response => response.data,
      error => { alert( error ) }
    )
    .then((data) => {
      if( typeof data == 'object' ){
        if( data['status'] === 'success' ){
          this.setState({
            first_name: data.data.first_name,
            last_name: data.data.last_name,
            email: data.data.email,
            username: data.data.username,
            phone: data.data.phone,
            loading: false
          });

        }else{
          message.error( data['data'] );
        }

      }
    })
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

     this.setState({
       [name]: value
     });

  }

  handleSubmit(event) {
    event.preventDefault();

    let form_data =
    {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      new_password: this.state.new_password,
      phone: this.state.phone,
    };

    let header = {
      headers: {
        'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN)  }
    };

    if( this.state.new_password != ""){
      let msg = "";

      if( this.state.new_password != this.state.new_password_verify  ){
        msg = "Password baru tidak sama dengan verifikasi password";
        notification.open({
          message: 'Error',
          description: msg,
          icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />
        });
      }

      else if( this.state.new_password.length < 6 ){
        msg = "Panjang password minimal 6 karakter";
        notification.open({
          message: 'Error',
          description: msg,
          icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />
        });
      }
      else{
        axios.put( api_url+'acl/users/edit/'+this.state.user_id, form_data, header)
        .then(response => response.data)
        .then((data) => {

          if( data['status'] === 'success' ){

            notification.open({
              message: 'Berhasil',
              description:'Profil sudah berubah!',
            });

            this.props.history.push("/admin/dashboard/");
          }else{
            message.error( data['data'] );
          }

        })
      }

    }else{

      axios.put( api_url+'acl/users/edit/'+this.state.user_id, form_data, header)
      .then(response => response.data)
      .then((data) => {

        if( data['status'] === 'success' ){

          notification.open({
            message: 'Berhasil',
            description:'Profil sudah berubah!',
          });

          this.props.history.push("/admin/dashboard/");
        }else{
          message.error( data['data'] );
        }

      })

    }
  }


  render() {
    return (
      <div>
        <div className="col-12 grid-margin">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item primary-color">Edit Profil</li>
            </ol>
          </nav>
        </div>

        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
            <h4 className="display-4 primary-color text-center">Edit Profil</h4>
            <br />

            <Skeleton loading={this.state.loading} active>
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  {/* Input Nama Depan */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Nama Depan<span className="text-danger">*</span></label>
                      <input type="text" className="form-control" placeholder="Nama Depan" name="first_name" value={this.state.first_name} onChange={this.handleChange} required/>
                    </div>
                  </div>

                  {/* Input Nama Belakang */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Nama Belakang<span className="text-danger">*</span></label>
                      <input type="text" className="form-control" placeholder="Nama Belakang" name="last_name" value={this.state.last_name} onChange={this.handleChange} required/>
                    </div>
                  </div>

                  {/* Username */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Username<span className="text-danger">*</span></label>
                      <input type="text" className="form-control" placeholder="Username" name="username" value={this.state.username} disabled required/>
                    </div>
                  </div>

                  {/* Input Email */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Email<span className="text-danger">*</span></label>
                      <input type="text" className="form-control" placeholder="Email" name="email" value={this.state.email} onChange={this.handleChange} disabled required/>
                    </div>
                  </div>

                  {/* Input Phone */}
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Telepon</label>
                      <input type="text" className="form-control" placeholder="Telepon" name="phone" value={this.state.phone} onChange={this.handleChange} />
                    </div>
                  </div>

                  {/* Ganti Password */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Password Baru</label>
                      <input type="password" className="form-control" placeholder="Password Baru" name="new_password" value={this.state.new_password} onChange={this.handleChange} />
                      <small class="form-text text-muted">Diisi jika ingin mengganti password</small>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Verifikasi Password Baru</label>
                      <input type="password" className="form-control" placeholder="Password Baru" name="new_password_verify" value={this.state.new_password_verify} onChange={this.handleChange} />
                    </div>
                  </div>

                </div>
                <button stype="submit" className="btn primary-button">Simpan</button>
              </form>
            </Skeleton>

            </div>
          </div>
        </div>

      </div>
    );
  }

}
