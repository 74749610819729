import React from 'react';
import { Link } from "react-router-dom";
import Atable from "../../../lib/Atable";
import axios from "axios";
import { message, Tooltip, Skeleton } from 'antd';

const api_url = process.env.REACT_APP_API_URL;


export default class Group extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      total_record  : "",
      total_filter  : "",
      limit         : 10,
      active_page   : 1,
      search        : "",
      table_data    : "",
      loading       : true
    };

    this.handleChange = this.handleChange.bind(this);

  }

  componentDidMount(){
     document.title = process.env.REACT_APP_APP_NAME+"::Group";
     this.load_data();
   }

   head(){
     return ["Nama Grup", "Deskripsi", "Aksi"];
   }

   handleChange(event) {
     const target = event.target;
     const value = target.type === 'checkbox' ? target.checked : target.value;
     const name = target.name;

      this.setState(
        { [name]: value }, () => {
          this.load_data();
      })

   }

   load_data(){
     message.loading('Loading...', 3.5);
     var form_data = {
       headers: { 'api_token': localStorage.getItem('token') }
     };

     axios.get(api_url+"acl/groups/get_groups", form_data).then(
       response => {
          this.setState({
            table_data: response.data.data,
            total_record: response.data.recordsTotal,
            total_filter: response.data.recordsFiltered,
            loading: false
          });
          message.destroy();
      },
      error => {
        alert(error)
      }

    );
   }

   submodule_list( submodules ){
     const res = submodules.map((value, index) =>
       <p>{value.submodule_name}</p>
     );
     return res;
   }

   generate_rows(){
     const data = this.state.table_data;

     if( typeof data == "object" && data !== null){
       const res = data.map((value, index) =>
           <tr key={ Math.random() }>
             <td>{value.group_name}</td>
             <td>{value.description}</td>
             <td>
               <Tooltip title="Edit group">
                 <Link className="btn secondary-button btn-sm" to={`group/edit/${value.id}`}>
                   <i className="fas fa-edit"></i>
                 </Link>
              </Tooltip>

              <Tooltip title="Detail modul">
                 <Link className="btn primary-button btn-sm" to={`group/detail/${value.id}`}>
                   <i className="fas fa-eye"></i>
                 </Link>
              </Tooltip>
             </td>
           </tr>
       );

         return res;
     }
   }




  render() {
    return (
      <div>
        {/* Breadcrumb */}
        <div className="col-12 grid-margin">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item primary-color">ACL</li>
              <li className="breadcrumb-item active">Group</li>
            </ol>
          </nav>
        </div>

        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <h4 className="display-4 primary-color text-center">Grup Pengguna Aplikasi</h4>
              <br />
              <Link className="btn primary-button" to="/admin/acl/group/add">
                Tambah Grup
              </Link>
              <hr className="divider soft" />

              <Skeleton loading={this.state.loading} active>
                <Atable
                  head={ this.head() }
                  data={ this.generate_rows() }
                  total_filter={ this.state.total_filter }
                  total_record={ this.state.total_record }
                  current_page={this.state.active_page}
                  search={this.state.search}
                  handleChange={this.handleChange}
                  limit={this.state.limit}
                />
              </Skeleton>






            </div>
          </div>
        </div>

      </div>
    );
  }

}
