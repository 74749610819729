import React, { Component } from "react";

class Footer extends Component {
  render() {
    return (
      <footer className="homepage-footer">

          <div className="col-md-12">
            <img src="/assets/images/pancacita.png" width="80" className="mx-auto d-block"/>
            <br/>
            <p className="text-center text-white">
              VERSI DEMO - Developed by <br/>
              <a href="http://diskominfo.acehprov.go.id/" className="text-center text-white" target="_blank">DINAS KOMUNIKASI, INFORMATIKA DAN PERSANDIAN ACEH</a>
            </p>


          </div>

{/*
          <span className="text-muted d-block text-center text-sm-left d-sm-inline-block">Developed By SIAT-Dev 2019 &nbsp;
            <a href="http://diskominfo.acehprov.go.id/" target="_blank" rel="noopener noreferrer">KOMINSA ACEH</a></span>

          <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Bravo Team &nbsp;
            <i className="mdi mdi-heart text-danger"></i>
          </span> */}

      </footer>
  );
  }
}

export default Footer;
