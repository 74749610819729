import React from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import {Skeleton, message, notification} from 'antd';

const api_url = process.env.REACT_APP_API_URL;

export default class Add_users extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            first_name: '',
            last_name: '',
            password: '',
            verify: '',
            email: '',
            phone: '',
            is_active: '',
            group_id: '',
            loading: true,
            group: []
        };

        this.handleChange = this
            .handleChange
            .bind(this);
        this.handleSubmit = this
            .handleSubmit
            .bind(this);
    }

    componentDidMount() {

        document.title = process.env.REACT_APP_APP_NAME + "::Users";

        var header = {
            headers: {
                'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN)
            }
        };

        axios
            .get(api_url + "acl/groups/get_groups", header)
            .then(response => {
                this.setState({group: response.data.data, loading: false});

            })
            .catch(function (err) {
                console.log(err);
            })

    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        const name = target.name;

        this.setState({[name]: value});
    }

    handleListGroup() {
        const group_id = this.state.group_id;
        const Group = props => (
            <div className="col-lg-3">
                <div className="form-radio mt-2">
                    <label className="form-check-label">
                        <input
                            type="radio"
                            className="form-check-input"
                            name="group_id"
                            value={props.group._id}
                            checked={group_id === props.group._id}
                            onChange={this.handleChange}/> {props.group.group_name}
                        <i className="input-helper"></i>
                    </label>
                </div>
            </div>
        );

        return this
            .state
            .group
            .map(function (result, i) {
                if( result.group_name != "Developer"){
                  return <Group group={result} key={i}/>;
                }
            });
    }

    handleSubmit(event) {

        event.preventDefault();

        message.loading('Loading...', 2.5);

        let form_data = {
            username: this.state.username,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            password: this.state.password,
            email: this.state.email,
            phone: this.state.phone,
            is_active: this.state.is_active,
            group_id: this.state.group_id
        };

        let header = {
            headers: {
                'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN)
            }
        };

        console.log(form_data);
        axios
            .post(api_url + 'acl/users/add', form_data, header)
            .then(response => response.data, error => {
                alert(error)
            })
            .then((data) => {
                if (data['status'] === 'success') {

                    notification.open({message: 'Berhasil', description: 'Data telah disimpan ke database!'});

                    this
                        .props
                        .history
                        .push("/admin/acl/users");

                } else {
                    message.error(data['data']);

                }

            })
    }

    render() {

        return (
            <div>
                <div className="col-12 grid-margin">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">ACL</li>
                            <Link className="breadcrumb-item" to="/admin/acl/users">Pengelola Aplikasi</Link>
                            <li className="breadcrumb-item active">Tambah Pengelola</li>
                        </ol>
                    </nav>
                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="display-4 primary-color text-center">Tambah Pengelola Aplikasi</h4>
                            <br/>
                            <Skeleton loading={this.state.loading} active>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">

                                            {/* Input Username */}
                                            <div className="form-group">
                                                <label htmlFor='username'>Username<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Username"
                                                    name="username"
                                                    value={this.state.username}
                                                    onChange={this.handleChange}/>

                                            </div>

                                            {/* Input Firstname */}
                                            <div className="form-group">
                                                <label htmlFor='first_name'>Nama Depan<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Nama Depan"
                                                    name="first_name"
                                                    value={this.state.first_name}
                                                    onChange={this.handleChange}/>

                                            </div>

                                            {/* Input Lastname */}
                                            <div className="form-group">
                                                <label htmlFor='last_name'>Nama Belakang<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="nama Belakang"
                                                    name="last_name"
                                                    value={this.state.module_name}
                                                    onChange={this.handleChange}/>

                                            </div>

                                            {/* Input Password */}
                                            <div className="form-group">
                                                <label htmlFor='password'>Password<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Password"
                                                    name="password"
                                                    value={this.state.module_name}
                                                    onChange={this.handleChange}/>

                                            </div>

                                            {/* Input Verifikasi Password */}
                                            <div className="form-group">
                                                <label name="verify">Verifikasi Password<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Verifikasi Password"
                                                    name="verify"
                                                    value={this.state.module_name}
                                                    onChange={this.handleChange}/>

                                            </div>

                                        </div>

                                        <div className="col-md-6">

                                            {/* Input Email */}
                                            <div className="form-group">
                                                <label htmlFor='email'>Email<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Email Pengguna Aplikasi"
                                                    name="email"
                                                    value={this.state.module_url}
                                                    onChange={this.handleChange}/>

                                            </div>

                                            {/* Input Telepon */}
                                            <div className="form-group">
                                                <label htmlFor='telepon'>Telepon</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Telepon"
                                                    name="phone"
                                                    value={this.state.phone}
                                                    onChange={this.handleChange}/>

                                            </div>

                                            {/* Input Active user */}
                                            <div className="form-group">
                                                <label htmlFor='Status Aktif'>Status Aktif<span className="text-danger">*</span>
                                                </label>
                                                <div className="form-group row">
                                                    <div className="col-sm-3">
                                                        <div className="form-radio mt-2">
                                                            <label className="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="is_active"
                                                                    value="true"
                                                                    onChange={this.handleChange}/>
                                                                Aktif
                                                                <i className="input-helper"></i>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-3">
                                                        <div className="form-radio mt-2">
                                                            <label className="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="is_active"
                                                                    value="false"
                                                                    onChange={this.handleChange}/>
                                                                Tidak Aktif
                                                                <i className="input-helper"></i>
                                                            </label>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            {/* Input Pengguna Aplikasi */}
                                            <div className="form-group">
                                                <label>Status Pengguna<span className="text-danger">*</span>
                                                </label>
                                                <div className="form-group row">
                                                    {this.handleListGroup()}
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="form-group">
                                        <button type="submit" className="btn btn-full primary-button">Simpan</button>
                                    </div>
                                </form>
                            </Skeleton>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}
