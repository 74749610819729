import React from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import {Skeleton, message, notification, Icon} from 'antd';

const api_url = process.env.REACT_APP_API_URL;

export default class Edit extends React.Component {
    constructor(props) {
        super(props);

        this.state = { id: this.props.match.params.id, provinsi_id:'',kabupaten_id:'',kabupaten:'', };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {

        document.title = process.env.REACT_APP_APP_NAME + "::Kabupaten";
        this.get_data();

        this.get_provinsi_id_list();
					//##componentDidMount##//

    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        const name = target.name;

        this.setState({[name]: value});
    }

    get_data(){
        var header = {
          headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };
        axios.get( api_url+'wilayah/kabupaten/get/'+this.state.id, header)
        .then(
          response => response.data,
          error => { alert( error ) }
        )
        .then((data) => {

          if( typeof data == 'object' ){
            if( data.status === 'success' ){
              this.setState({
                provinsi_id: data.data.provinsi_id, 
 					kabupaten_id: data.data.kabupaten_id, 
 					kabupaten: data.data.kabupaten, 
 					//##state_field##:##state_value##//   
                loading: false
            });
            }else{
              message.error( data.data.data );
            }

          }
        })
      }


    handleSubmit(event) {
        event.preventDefault();

        message.loading('Loading...', 2.5);

        var form_data =
        {
          provinsi_id:this.state.provinsi_id,
 					kabupaten_id:this.state.kabupaten_id,
 					kabupaten:this.state.kabupaten,
 					//##field##:##value##//
        };

        var header = {
          headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };

        axios.put( api_url+'wilayah/kabupaten/edit/'+this.state.id, form_data, header
        ).then(
          response => response.data,
          error => {
            alert(error)
          }
        )
        .then((data) => {
            if( typeof data == 'object' ){
                  if( data.status === 'success' ){

                    notification.open({
                      message: 'Berhasil',
                      description:'Data telah disimpan ke database!',
                    });

                    this.props.history.push("/admin/data_wilayah/kabupaten/list");

                  }else{
                    notification.open({
                        message: 'Error',
                        description: data.data,
                        icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
                      });
                  }
            }

        })
      }

    
					get_provinsi_id_list(){
		        axios.get(api_url+"wilayah/provinsi/get").then(
		            response => {
		              this.setState({ provinsi_id_list: response.data.data});
		          },
		          error => {
		            alert(error)
		          }

		        );
		      }

					select_option_for_provinsi_id(){
		        if( this.state.provinsi_id_list !== undefined && typeof this.state.provinsi_id_list == "object"){
		          if( this.state.provinsi_id_list.length > 0 ){
		            const res = (
		              <select name="provinsi_id" className="form-control" onChange={this.handleChange} required>
		                {this.state.provinsi_id_list.map(( value ) =>
		                  <option value={value.provinsi_id} key={ Math.random() } selected= {(value.provinsi_id == this.state.provinsi_id)?true:false}>{value.provinsi}</option>
		                )}
		              </select>
		            );

		            return res;

		          }
		        }
		      }

					
//##cst_function##/


    render() {

        return (
            <div>
                <div className="col-12 grid-margin">

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Data Wilayah</li>
                            <Link className="breadcrumb-item" to="/admin/data_wilayah/kabupaten/list">Kabupaten</Link>
                            <li className="breadcrumb-item active">Edit</li>
                        </ol>
                    </nav>

                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="display-4 primary-color text-center">Edit Kabupaten</h4>
                            <br />

                            {/* Form Input */}
                            <form onSubmit={this.handleSubmit}>
                                <div className="row">

                                  
					{/** Provinsi **/}
					<div className="col-md-6">
            <div className="form-group">
              <label>Provinsi<span className="text-danger">*</span></label>
								{ this.select_option_for_provinsi_id() }
						</div>
          </div>

			
				{/** Kode Kabupaten **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Kode Kabupaten<span className="text-danger">*</span></label>
                    <input type="number" className="form-control" placeholder="Kode Kabupaten" name="kabupaten_id" value={this.state.kabupaten_id} onChange={this.handleChange} required/>
                  </div>
                </div>

			
				{/** Nama Kabupaten **/}
				<div className="col-md-12">
                  <div className="form-group">
                    <label>Nama Kabupaten<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Nama Kabupaten" name="kabupaten" value={this.state.kabupaten} onChange={this.handleChange} required/>
                  </div>
                </div>

			{/*##form_input##*/}

                                </div>
                                <button stype="submit" className="btn btn-full primary-button">Simpan</button>
                            </form>


                        </div>
                    </div>



                </div>

            </div>
        );
    }

}
