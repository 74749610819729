import React from 'react';
import {Link} from "react-router-dom";
import Atable from "../../../lib/Atable";
import axios from "axios";
import {
    message,
    notification,
    Modal,
    Tooltip,
    Skeleton
} from 'antd';

const confirm = Modal.confirm;

const api_url = process.env.REACT_APP_API_URL;

export default class List_users extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          total_record  : 0,
          total_filter  : 0,
          limit         : 10,
          order         : "ASC",
          order_column  : 0,
          active_page   : 0,
          search        : "",
          table_data    : "",
          loading       : true
        };

        this.handleChange = this
            .handleChange
            .bind(this);

    }

    componentDidMount() {
        document.title = process.env.REACT_APP_APP_NAME + "::Users";
        this.load_data();
    }

    head() {
        return ["Nama", "Level", "Username", "Email", "Aksi"];
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        }, () => {
            this.load_data();
        })
    }

    load_data() {
        message.loading('Loading...', 3.5);
        var header = {
            params: { search: this.state.search, limit: this.state.limit, offset: this.state.active_page, order: this.state.order, order_column: this.state.order_column },
            headers: {
                'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN)
            }
        };

        axios
            .get(api_url + "acl/users/get_users", header)
            .then(response => {
                this.setState({table_data: response.data.data, total_record: response.data.recordsTotal, total_filter: response.data.recordsFiltered, loading: false});
                message.destroy();
            }, error => {
                alert(error)
            });
    }

    deleteUser(id) {

        const user_id = id;
        const e = this;

        const header = {
            headers: {
                'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN)
            }
        };

        confirm({
            title: "Apakah anda ingin menghapus Pengguna Aplikasi?",
            content: "",
            onOk() {
                axios
                    .delete(api_url + "acl/users/delete/" + user_id, header)
                    .then(response => {
                        notification.open({message: 'Berhasil', description: 'Data telah berhasil dihapus!'});
                        return e.componentDidMount()
                    }, error => {
                        alert(error)
                    });
            }
        });
    }

    generate_rows() {
        const data = this.state.table_data;
        if (typeof data == "object" && data !== null) {
            // console.log( data )
            const res = data.map((value) =>{
              if(value.email != "global@email.com"){
                return(
                  <tr key={Math.random()}>
                      <td>{value.first_name+" "+value.last_name}</td>
                      <td>{ (value.group.length > 0 )?value.group[0].group_name:null }</td>
                      <td>{value.username}</td>
                      <td>{value.email}</td>

                      <td>
                          <Tooltip title="Edit User">
                              <Link
                                  className="btn secondary-button btn-sm mr-2"
                                  to={`users/edit_users/${value._id}`}>
                                  <i className="fas fa-edit"></i>
                              </Link>
                          </Tooltip>

                          <Tooltip title="Ganti Password">
                              <Link
                                  className="btn primary-button btn-sm mr-2"
                                  to={`users/password/${value._id}`}>
                                  <i className="fas fa-key"></i>
                              </Link>
                          </Tooltip>

                          <Tooltip title="Hapus User">
                              <button
                                  onClick={() => this.deleteUser(value._id)}
                                  id={value._id}
                                  value={value.username}
                                  className="btn btn-danger btn-sm"
                                  title="Hapus User">
                                  <i className="fas fa-trash"></i>
                              </button>
                          </Tooltip>

                      </td>

                  </tr>
                )
              }

            });

            return res;
        }
    }

    render() {
        return (
            <div>
                {/* Breadcrumb */}
                <div className="col-12 grid-margin">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">ACL</li>
                            <li className="breadcrumb-item active">Pengelola Aplikasi</li>
                        </ol>
                    </nav>
                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="display-4 primary-color text-center">Pengelola Aplikasi</h4>
                            <br/>
                            <Link className="btn primary-button" to="users/add_users">
                                Tambah
                            </Link>
                            <hr className="divider soft"/>

                            <Skeleton loading={this.state.loading} active>
                                <Atable
                                    head={this.head()}
                                    data={this.generate_rows()}
                                    total_filter={this.state.total_filter}
                                    total_record={this.state.total_record}
                                    current_page={this.state.active_page}
                                    search={this.state.search}
                                    handleChange={this.handleChange}
                                    limit={this.state.limit}/>
                            </Skeleton>

                        </div>
                    </div>
                </div>

            </div>
        );
    }

}
