import React from 'react'
import axios, { put } from 'axios';
import {Link} from "react-router-dom";
import {Skeleton, message, notification, Icon} from 'antd';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const api_url = process.env.REACT_APP_API_URL;

class Edit extends React.Component {

  constructor(props) {
    super(props);

    this.state = { id: this.props.match.params.id, nama: "", logo :null, new_logo: null, loading: false };

    this.onFormSubmit = this.onFormSubmit.bind(this)
    this.onChangeFile = this.onChangeFile.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.fileUpload = this.fileUpload.bind(this)
  }

  componentDidMount() {
      document.title = process.env.REACT_APP_APP_NAME + "::Maskapai";
      this.get_data();
  }
  get_data(){
      var header = {
        headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
      };
      axios.get( api_url+'bandara/maskapai/get/'+this.state.id, header)
      .then(
        response => response.data,
        error => { alert( error ) }
      )
      .then((data) => {

        if( typeof data == 'object' ){
          if( data.status === 'success' ){

            this.setState({
              nama: data.data.nama,
              logo: data.data.logo,
              loading: false
          });
          }else{
            message.error( data.data[0].data );
          }

        }
      })
    }

  onFormSubmit(e){
    e.preventDefault();
    this.setState({loading: true});
    this.fileUpload(this.state.new_logo).then((data)=>{

      if( typeof data == 'object' ){
        if( data.data.status === 'success' ){

          notification.open({
            message: 'Berhasil',
            description:'Data telah disimpan ke database!',
            icon: <Icon type="check-circle" className="primary-color" />,
          });

          this.setState({loading: false});

          this.componentDidMount();


        }else{
          notification.open({
              message: 'Error',
              description: data.data.data,
              icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
            });
            this.setState({loading: false});
        }
      }
    })
  }

  handleChange(event) {
      const target = event.target;
      const value = target.type === 'checkbox'
          ? target.checked
          : target.value;
      const name = target.name;

      this.setState({[name]: value});
  }

  onChangeFile(e) {
    this.setState({new_logo:e.target.files[0]})
  }

  fileUpload(file){
    const url = api_url+'bandara/maskapai/edit/'+this.state.id;
    const formData = new FormData();
    formData.append('logo',file);
    formData.append('nama',this.state.nama);

    const config = {
        headers: {
            'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN),
            'content-type': 'multipart/form-data'
        }
    }
    return  put(url, formData,config)
  }


  render() {
    return (
      <div>
          <div className="col-12 grid-margin">
              <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                      <li className="breadcrumb-item">Master Data</li>
                      <Link className="breadcrumb-item" to="/admin/master_data/maskapai/list">Maskapai</Link>
                      <li className="breadcrumb-item active">Edit</li>
                  </ol>
              </nav>

          </div>

          <div className="col-12 grid-margin">
              <div className="card">
                  <div className="card-body">
                      <h4 className="display-4 primary-color text-center">Edit Maskapai</h4>
                      <br />

                      {/* Form Input */}
                      <Skeleton loading={this.state.loading} active>
                      <form onSubmit={this.onFormSubmit}>
                          <div className="row">
                            <div className="col-md-8">
                              {/** Nama Maskapai **/}
                              <div className="col-md-12">
                                        <div className="form-group">
                                          <label>Nama Maskapai<span className="text-danger">*</span></label>
                                          <input type="text" className="form-control" placeholder="Nama Maskapai" name="nama" value={this.state.nama} onChange={this.handleChange} required/>
                                        </div>
                                      </div>



                                      {/** Logo  **/}
                                      <div className="col-md-12">
                                        <div className="form-group">
                                          <label>Ganti Logo</label><br/>
                                          <input type="file" className="form-control-file" onChange={this.onChangeFile} />
                                        </div>
                                      </div>

                                      <div className="col-md-12">
                                        <button stype="submit" className="btn primary-button">Simpan</button>
                                      </div>

                            </div>

                            <div className="col-md-4">
                                <img src={api_url+this.state.logo} className="w-100"/>
                            </div>

                          </div>

                      </form>
                    </Skeleton>


                  </div>
              </div>



          </div>

      </div>
   )
  }
}



export default Edit
