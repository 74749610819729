import React from 'react';
import { Link } from "react-router-dom";
import Atable from "../../../lib/Atable";
import axios from "axios";
import { message, notification, Icon, Modal, Tooltip, Skeleton } from 'antd';
import moment from 'moment';
import 'moment/locale/id';
import Auth from "../../../helper/Authentication";

const confirm = Modal.confirm;

const api_url = process.env.REACT_APP_API_URL;


export default class List extends React.Component {
  constructor(props) {
    super(props);
    Auth.is_access( window.location.pathname )
    this.state = {
      total_record  : 0,
      total_filter  : 0,
      limit         : 10,
      order         : "ASC",
      order_column  : 0,
      active_page   : 0,
      search        : "",
      table_data    : "",
      loading       : true
    };

    this.handleChange = this.handleChange.bind(this);

  }

  componentDidMount(){
     document.title = process.env.REACT_APP_APP_NAME+"::Harga Pangan";
     this.load_data();
   }

   head(){
     return ["Tanggal", "Pasar", /*##header_data##/*/ ""];
   }

   handleChange(event) {
     const target = event.target;
     const value = target.type === 'checkbox' ? target.checked : target.value;
     const name = target.name;

      this.setState(
        { [name]: value }, () => {
          this.load_data();
      })

   }

   load_data(){
     var form_data = {
       params: { search: this.state.search, limit: this.state.limit, offset: this.state.active_page, order: this.state.order, order_column: this.state.order_column },
       headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
     };

     axios.get(api_url+"harga_pangan/list", form_data).then(
       response => {
          this.setState({
            table_data: response.data.data,
            total_record: response.data.recordsTotal,
            total_filter: response.data.recordsFiltered,
            loading: false
          });
      },
      error => {
        alert(error)
      }

    );
   }

   generate_rows(){
     const data = this.state.table_data;

     if( typeof data == "object" && data !== null){
       const res = data.map((value, index) =>
           <tr key={ Math.random() }>
            <td>{moment(value.tanggal).format("D MMMM YYYY")}</td>
            <td>{value.pasar}</td>
						{/*##table_rows##*/}

             <td>
               <Tooltip title="Detail">
                 <Link className="btn primary-button btn-sm" to={`/admin/master_data/harga_pangan/detail/${value._id}`}>
                   Kelola Harga
                 </Link>
              </Tooltip>


             </td>
           </tr>
       );

         return res;
     }
   }



  render() {
    return (
      <div>
        {/* Breadcrumb */}
        <div className="col-12 grid-margin">

          <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                  <li className="breadcrumb-item">Master Data</li>
                  <li className="breadcrumb-item active">Harga Pangan</li>
              </ol>
          </nav>


        </div>

        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <h4 className="display-4 primary-color text-center">Harga Pangan</h4>
              <br />
              <Link className="btn primary-button" to="/admin/master_data/harga_pangan/add">
                Tambah Harga Pangan
              </Link>
              <hr className="divider soft" />

              <Skeleton loading={this.state.loading} active>
                <Atable
                  head={ this.head() }
                  data={ this.generate_rows() }
                  total_filter={ this.state.total_filter }
                  total_record={ this.state.total_record }
                  current_page={this.state.active_page}
                  search={this.state.search}
                  handleChange={this.handleChange}
                  limit={this.state.limit}
                />
              </Skeleton>
            </div>
          </div>
        </div>

      </div>
    );
  }

}
