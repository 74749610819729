import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { message, Skeleton } from 'antd';

const api_url = process.env.REACT_APP_API_URL;

class Sidebar extends Component {
    constructor(props) {
      super(props);

      this.state = {
        table_data    : "",
        loading       : true
      };

    }

    componentDidMount(){
       this.load_data();
    }

    load_data(){
      let form_data = {
        params: { user_id: localStorage.getItem('id') },
        headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
      };
      axios.get( api_url+'acl/modules/get_modules_by_group_id/'+localStorage.getItem("group_id"), form_data)
      .then(
        response => response.data,
        error => { alert( error ) }
      )
      .then((data) => {

        if( typeof data == 'object' ){
          if( data.status == "success"  ){
            this.setState({
                table_data: data.data,
                loading: false
            })
          }else{
            window.location.replace("/logout");
          }



        }
      })
    }

    generate_rows(){
      const data = this.state.table_data;

      if( typeof data == "object" && data !== null){
        var icon          = "menu-icon";
        var current_path  = window.location.pathname;

        const res = data.map((value, index) =>
          <li className="nav-item" key={ Math.random() }>
            <a className="nav-link" data-toggle="collapse" href={"#"+value.module_name.split(' ').join('')} aria-expanded="false" aria-controls="ui-basic">
                <i className={icon+" "+value.module_icon}> </i>
                <span className="menu-title">{value.module_name}</span>
                <i className="menu-arrow"></i>
            </a>
            <div className={(current_path.split("/")[2] === value.module_url.split("/")[1])?"collapse show":"collapse"} id={value.module_name.split(' ').join('')}>
              <ul className="nav flex-column sub-menu" key={ Math.random() }>
                  {this.submodules( value.submodules )}
              </ul>
            </div>
          </li>
        );

        return res;

      }
    }

    submodules( submodules_obj ){

      if( typeof submodules_obj == 'object' && submodules_obj.length > 0 ){
        var path          = window.location.pathname;
        var current_path  = path.split("/")[0]+"/"+path.split("/")[1]+"/"+path.split("/")[2]+"/"+path.split("/")[3];

        const res = submodules_obj.map((value, index) =>
          <Link className={ (current_path === "/"+value.submodule_url)?"nav-link active":"nav-link"} to={"/"+value.submodule_url} key={ Math.random() }>
              <i className="fas fa-circle">&nbsp;&nbsp;</i> {value.submodule_name}
          </Link>
        );

        return res;
      }
    }


    render() {
        return (
                <nav className="sidebar sidebar-offcanvas" id="sidebar">
                    <ul className="nav">
                        <li className="nav-item nav-profile">
                            <div className="nav-link">
                                <div className="user-wrapper">
                                    <div className="text-wrapper">
                                        <p className="profile-name">{localStorage.getItem('first_name')} {localStorage.getItem('last_name')}</p>
                                        <div>
                                            <small className="designation text-muted">{localStorage.getItem('email')}</small>
                                            <span className="status-indicator online"></span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </li>

                        <Skeleton loading={this.state.loading} active paragraph={{ rows: 8 }}>
                          <li className="nav-item">
                              <Link className="nav-link" to="/admin/dashboard">
                                  <i className="menu-icon fas fa-tachometer-alt"></i>
                                  Dashboard
                              </Link>
                          </li>
                          {this.generate_rows()}
                        </Skeleton>

                    </ul>

                </nav>
    );
    }
}

export default Sidebar;
