import React from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import {message, Skeleton} from 'antd';

import Header from "./include/Header";
import Footer from "./include/Footer";

import moment from 'moment';
import 'moment/locale/id';

import './Public.css';

const api_url = process.env.REACT_APP_API_URL;

export default class News extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      content: "",
      error_content: null,
      loading: true
    };

  }

  componentDidMount(){
     document.title = process.env.REACT_APP_APP_NAME;
     this.get_page();
  }

  get_page(){
    axios.get( api_url+'berita/get_berita_list')
    .then(
      
      response => response.data,
      error => { this.setState({ error_content: "Halaman Tidak Ditemukan" , loading: false }) }
    )
    .then((data) => {

      if( typeof data == 'object' ){
        if( data.status === 'success' ){
          this.setState({ content: data.data, loading: false });
        }else{
          message.error( data.data );
        }
      }
    })
  }

  generate_rows(){
    const data = this.state.content;

    if( typeof data == "object" && data !== null){
      const res = data.map((value, index) =>
          <div className="col-md-4 mb-4" key={ Math.random() }>

           <h1>{value.title}</h1>
           <img src={api_url+value.thumbnail} className="w-100"/>
           <span>{moment(value.publish_date).format("D MMMM YYYY h:m:s")}</span>
           <br/>
           <span>{value._id+" "+value.org}</span>
           {/*<p>{value.content}</p> */}

          </div>

      );

        return res;
    }
  }

  render() {
    return (
      <div>
        <Header />

        <div className="container">

            <div className="row">
              {this.generate_rows()}
            </div>

            <div className='box'>
              <div className='wave -one'></div>
              <div className='wave -two'></div>
              <div className='wave -three'></div>
            </div>

        <Footer />
        </div>
    </div>
    );
  }

}
