import React from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import {message, Skeleton} from 'antd';

import Header from "./include/Header";
import Footer from "./include/Footer";

import './Public.css';

const api_url = process.env.REACT_APP_API_URL;

const android_download_link = "https://play.google.com/store/apps/details?id=aceh.diskominsa.haba";

export default class Homepage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { dashboard_data: "", loading: true };

  }

  componentDidMount(){
     document.title = process.env.REACT_APP_APP_NAME;
  }


  render() {
    let menu_list = [
      {
        id  : "smsPengaduan",
        name: "SMS Pengaduan",
        desc: "Buat laporan pengaduan terhadap berbagai permasalahan dan layanan Pemerintah",
        icon: "/assets/images/icons/sms.png"
      },
      {
        id  : "panggilanDarurat",
        name: "Panggilan Darurat",
        desc: "Membantu anda menemukan informasi panggilan darurat",
        icon: "/assets/images/icons/call.png"
      },
      {
        id  : "survei",
        name: "Survei",
        desc: "Mari berpartisipasi mengikuti survei untuk perubahan yang lebih baik",
        icon: "/assets/images/icons/survei.png"
      },
      {
        id  : "ISPU",
        name: "ISPU",
        desc: "Dapatkan update informasi Indeks Standar Pencemaran Udara di sekitar",
        icon: "/assets/images/icons/ispu.png"
      },
      {
        id  : "faskes",
        name: "Fasilitas Kesehatan",
        desc: "Dapatkan informasi fasilitas kesehatan seperti rumah sakit, puskesmas dan apotik terdekat di sekitar anda",
        icon: "/assets/images/icons/rumkit.png"
      },
      {
        id  : "polisi",
        name: "Kantor Polisi",
        desc: "Temukan lokasi kantor polisi terdekat",
        icon: "/assets/images/icons/polisi.png"
      },
      {
        id  : "trankutaraja",
        name: "Trans Kutaraja",
        desc: "Dapat informasi trans kutaraja setiap saat",
        icon: "/assets/images/icons/transkutaraja.png"
      },
      {
        id  : "damkar",
        name: "BPBD dan DAMKAR",
        desc: "Temukan informasi pemadam kebakaran di sekitar anda",
        icon: "/assets/images/icons/damkar.png"
      },
      {
        id  : "masjid",
        name: "Masjid",
        desc: "Temukan masjid terdekat beserta informasi jadwal shalat dan arah kiblat",
        icon: "/assets/images/icons/masjid.png"
      },
      {
        id  : "warkop",
        name: "Warung Kopi",
        desc: "Menyediakan informasi warung kopi di sekitar anda",
        icon: "/assets/images/icons/kopi.png"
      },
      {
        id  : "wisata",
        name: "Wisata",
        desc: "Temukan lokasi wisata terdekat dan di berbagai daerah di Aceh",
        icon: "/assets/images/icons/wisata.png"
      },
      {
        id  : "kantor",
        name: "Kantor",
        desc: "Informasi kantor pemerintah beserta alamat",
        icon: "/assets/images/icons/kantor.png"
      },
      {
        id  : "spbu",
        name: "SPBU",
        desc: "Temukan SPBU terdekat di sekitar anda",
        icon: "/assets/images/icons/spbu.png"
      },
      {
        id  : "pangan",
        name: "Harga Pangan",
        desc: "Update informasi harga pangan di sekitar anda setiap hari",
        icon: "/assets/images/icons/pangan.png"
      },
      {
        id  : "airport",
        name: "Airport",
        desc: "Dapatkan informasi bandar udara dan jadwal penerbangan",
        icon: "/assets/images/icons/pesawat.png"
      },
      {
        id  : "kapal",
        name: "Jadwal Kapal",
        desc: "Update informasi jadwal kapal laut setiap hari",
        icon: "/assets/images/icons/kapal.png"
      },
      {
        id  : "haba_apps",
        name: "HABA APPS",
        desc: "Daftar Aplikasi Pemerintah Aceh",
        icon: "/assets/images/icons/haba_apps.png"
      },
      {
        id  : "haba_collaboration",
        name: "HABA COLLABORATION",
        desc: "Daftar Aplikasi Eksternal",
        icon: "/assets/images/icons/haba_collaboration.png"
      },
      {
        id  : "haba_extra",
        name: "HABA EXTRA",
        desc: "Informasi pendukung lainnya",
        icon: "/assets/images/icons/haba_extra.png"
      },
      {
        id  : "covid",
        name: "Covid 19",
        desc: "Informasi Covid 19 Wilayah Aceh, Update Setiap Hari.",
        icon: "/assets/images/icons/covid19.png"
      },
      {
        id  : "multi_language",
        name: "Multi Bahasa",
        desc: "Support dalam bahasa Indonesia dan Inggris.",
        icon: "/assets/images/icons/multi_language.png"
      }
    ];

    let menu_info = [];
    let menus     = [];
    for( let x in menu_list ){
      menu_info.push([

      <div className="col-12 home-service-info collapse" id={menu_list[x].id}>
        <div className="d-flex flex-row mb-3">

          <div className="p-2">
            <button className="home-service-close-btn" data-toggle="collapse" data-target={"#"+menu_list[x].id}><i class="far fa-times-circle fa-4x"></i></button>
          </div>

          <div className="p-2">
            <h3>{menu_list[x].name}</h3>
            <p>{menu_list[x].desc}</p>
          </div>

        </div>
      </div>]);

      menus.push([
      <div className="col-md-2 col-4 feature" data-toggle="collapse" data-target={"#"+menu_list[x].id} aria-expanded="false" aria-controls="menuInfo">
        <img src={menu_list[x].icon} />
        <h5 className="text-center">{menu_list[x].name}</h5>
      </div>])
      // console.log( menus_list[x] )
    }


    return (
      <div>
        <Header />

        <div className="container">
        <section>
          <div className="row homepage-head">
            {/** Background animated **/}
            <ul className="circles">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
            </ul>

            {/* Left header */}
            <div className="col-md-6 left">
              <h1>Dapatkan Informasi Aceh Terkini</h1>
              <h2>Layanan Informasi Resmi Pemerintah Aceh</h2>

              <div className="row">
                <div className="col-md-6">
                  <a href="https://play.google.com/store/apps/details?id=aceh.diskominsa.haba" target="_BLANK">
                    <img className="download-app" src="/assets/images/google_play.png" width="100%"/>
                  </a>
                </div>
                <div className="col-md-6">
                  <Link className="breadcrumb-item" to="/ios">
                    <img className="download-app" src="/assets/images/app_store.png" width="100%"/>
                  </Link>
                </div>
              </div>

            </div>

            {/* Right Header */}
            <div className="col-md-6 right">
              <img className="mockup-head" src="/assets/images/mockup.png" width="100%" />
            </div>

          </div>
        </section>


      {/* Feature Section */}
      <section className="fitur-section">
          <div className="col-md-12">
            <h1 className="homepage-title secondary-color text-center-mobile">Fitur</h1>
            <div className="row justify-content-md-center">
              {/*
              <div class="col-12 home-service-info collapse" id="panggilanDarurat">
                <h3>Panggilan Darurat</h3>
                <p>Membantu anda menemukan informasi panggilan darurat</p>
              </div>

              <div class="col-12 home-service-info collapse" id="smsPengaduan">
                <h3>SMS Pengaduan</h3>
                <p>Buat laporan pengaduan terhadap berbagai permasalahan dan layanan Pemerintah</p>
              </div>

              <div class="col-12 home-service-info collapse" id="surveiMenu">
                <h3>Survei</h3>
                <p>Mari berpartisipasi mengikuti survei untuk perubahan yang lebih baik</p>
              </div>

              <div class="col-12 home-service-info collapse" id="ISPUMenu">
                <h3>Indek Standar Pencemaran Udara</h3>
                <p>Dapatkan update informasi kualitas udara di sekitar</p>
              </div>

              <div class="col-12 home-service-info collapse" id="faskes">
                <h3>Fasilitas Kesehatan</h3>
                <p>Dapatkan informasi fasilitas kesehatan seperti rumah sakit, puskesmas dan apotik terdekat di sekitar anda</p>
              </div>

              <div class="col-12 home-service-info collapse" id="polisi">
                <h3>Kantor Polisi</h3>
                <p>Temukan lokasi kantor polisi terdekat</p>
              </div>

              <div class="col-12 home-service-info collapse" id="transkutaraja">
                <h3>Trans Kutaraja</h3>
                <p>Dapat informasi trans kutaraja setiap saat</p>
              </div>

              <div class="col-12 home-service-info collapse" id="damkar">
                <h3>Pemadam Kebakaran</h3>
                <p>Temukan informasi pemadam kebakaran di sekitar anda</p>
              </div>

              <div class="col-12 home-service-info collapse" id="masjid">
                <h3>Masjid</h3>
                <p>Temukan masjid terdekat beserta informasi jadwal shalat dan arah kiblat</p>
              </div>

              <div class="col-12 home-service-info collapse" id="warkop">
                <h3>Warung Kopi</h3>
                <p>Menyediakan informasi warung kopi di sekitar anda</p>
              </div>

              <div class="col-12 home-service-info collapse" id="wisata">
                <h3>Wisata</h3>
                <p>Temukan lokasi wisata terdekat dan di berbagai daerah di Aceh</p>
              </div>

              <div class="col-12 home-service-info collapse" id="kantor">
                <h3>Kantor Pemerintah</h3>
                <p>Informasi kantor pemerintah beserta alamat</p>
              </div>

              <div class="col-12 home-service-info collapse" id="spbu">
                <h3>SPBU</h3>
                <p>Temukan SPBU terdekat di sekitar anda</p>
              </div>

              <div class="col-12 home-service-info collapse" id="hargaPangan">
                <h3>Harga Pangan</h3>
                <p>Update informasi harga pangan di sekitar anda setiap hari</p>
              </div>

              <div class="col-12 home-service-info collapse" id="pesawat">
                <h3>Jadwal Pesawat</h3>
                <p>Update informasi jadwal penebangan dari dan ke Aceh setiap saat</p>
              </div>

              <div class="col-12 home-service-info collapse" id="kapal">
                <h3>Jadwal Kapal</h3>
                <p>Update informasi jadwal kapal laut setiap hari</p>
              </div>
              */}

              {menu_info}
              {menus}

              {/*
              <div className="col-md-2 col-4 feature" data-toggle="collapse" data-target="#panggilanDarurat" aria-expanded="false" aria-controls="panggilanDarurat">
                <img src="/assets/images/icons/call.png" />
                <h5 className="text-center">Panggilan Darurat</h5>
              </div>

              <div className="col-md-2 col-4  feature" data-toggle="collapse" data-target="#smsPengaduan" aria-expanded="false" aria-controls="smsPengaduan">
                <img src="/assets/images/icons/sms.png" />
                <h5 className="text-center">SMS Pengaduan</h5>
              </div>

              <div className="col-md-2 col-4  feature" data-toggle="collapse" data-target="#surveiMenu" aria-expanded="false" aria-controls="surveiMenu">
                <img src="/assets/images/icons/survei.png"/>
                <h5 className="text-center">Survei</h5>
              </div>

              <div className="col-md-2 col-4 feature" data-toggle="collapse" data-target="#ISPUMenu" aria-expanded="false" aria-controls="ISPUMenu">
                <img src="/assets/images/icons/paru1.png"/>
                <h5 className="text-center">Indeks Standar Pencemaran Udara</h5>
              </div>

              <div className="col-md-2 col-4 feature" data-toggle="collapse" data-target="#faskes" aria-expanded="false" aria-controls="faskes">
                <img src="/assets/images/icons/rumkit.png" />
                <h5 className="text-center">Fasilitas Kesehatan</h5>
              </div>

              <div className="col-md-2 col-4 feature" data-toggle="collapse" data-target="#polisi" aria-expanded="false" aria-controls="polisi">
                <img src="/assets/images/icons/polisi.png"/>
                <h5 className="text-center">Kantor Polisi</h5>
              </div>

              <div className="col-md-2 col-4  feature" data-toggle="collapse" data-target="#transkutaraja" aria-expanded="false" aria-controls="transkutaraja">
                <img src="/assets/images/icons/transkutaraja.png"/>
                <h5 className="text-center">Trans Kutaraja</h5>
              </div>

              <div className="col-md-2 col-4 feature" data-toggle="collapse" data-target="#damkar" aria-expanded="false" aria-controls="damkar">
                <img src="/assets/images/icons/damkar.png"/>
                <h5 className="text-center">Pemadam Kebakaran</h5>
              </div>

              <div className="col-md-2 col-4 feature" data-toggle="collapse" data-target="#masjid" aria-expanded="false" aria-controls="masjid">
                <img src="/assets/images/icons/masjid2.png" />
                <h5 className="text-center">Masjid</h5>
              </div>

              <div className="col-md-2 col-4 feature" data-toggle="collapse" data-target="#warkop" aria-expanded="false" aria-controls="warkop">
                <img src="/assets/images/icons/kopi.png"/>
                <h5 className="text-center">Warung Kopi</h5>
              </div>

              <div className="col-md-2 col-4 feature" data-toggle="collapse" data-target="#wisata" aria-expanded="false" aria-controls="wisata">
                <img src="/assets/images/icons/wisata.png" />
                <h5 className="text-center">Wisata</h5>
              </div>

              <div className="col-md-2 col-4 feature" data-toggle="collapse" data-target="#kantor" aria-expanded="false" aria-controls="kantor">
                <img src="/assets/images/icons/kantor.png" />
                <h5 className="text-center">Kantor Pemerintah</h5>
              </div>

              <div className="col-md-2 col-4 feature" data-toggle="collapse" data-target="#spbu" aria-expanded="false" aria-controls="spbu">
                <img src="/assets/images/icons/spbu.png"/>
                <h5 className="text-center">SPBU</h5>
              </div>

              <div className="col-md-2 col-4 feature"  data-toggle="collapse" data-target="#hargaPangan" aria-expanded="false" aria-controls="hargaPangan">
                <img src="/assets/images/icons/pangan.png" />
                <h5 className="text-center">Harga Pangan</h5>
              </div>

              <div className="col-md-2 col-4 feature" data-toggle="collapse" data-target="#pesawat" aria-expanded="false" aria-controls="pesawat">
                <img src="/assets/images/icons/pesawat.png"/>
                <h5 className="text-center">Jadwal Pesawat</h5>
              </div>

              <div className="col-md-2 col-4 feature" data-toggle="collapse" data-target="#kapal" aria-expanded="false" aria-controls="kapal">
                <img src="/assets/images/icons/kapal.png" />
                <h5 className="text-center">Jadwal Kapal</h5>
              </div>
              */}

            </div>

          </div>
      </section>

      {/* Warta Aceh Section */}
      <section className="warta-section">
          <div className="row h-100 primary-bg">
            <div className="col-md-6">
            <img src="/assets/images/warta.png" className="w-100"/>
            </div>
            <div className="col-md-6 my-auto">
              <h1 className="homepage-title text-white text-center-mobile">Warta Aceh</h1>
              <p className="secondary-color text-justify text-white text-center-mobile">Menyajikan daftar berita terkini dari berbagai sumber.</p>
            </div>
          </div>
        </section>


      {/* Ibadah Section */}
      <section className="ibadah-section">
        <div className="row h-100">
          <div className="col-md-4">
            <img src="/assets/images/masjid.png" className="w-100" />
          </div>

          <div className="col-md-8 my-auto">

            <h1 className="homepage-title ibadah-title text-center-mobile">Jadwal Shalat</h1>
            <p className="text-center-mobile">Informasi jadwal shalat dan temukan masjid terdekat.</p>
          </div>

        </div>
      </section>

      {/* Lokasi Terdekat Section */}
      <section className="lokasi-terdekat-section white-bg-trasparent">
          <div className="row h-100">

            <div className="col-md-6 my-auto text-center">
              <img src="/logo.png" className="w-50"/>
              <br/>
              <h1 className="homepage-title text-center primary-color">Berdasarkan Lokasi Terdekat</h1>
              <p className="text-center">Menggunakan sistem navigasi GPS sehingga informasi disajikan berdasarkan lokasi terdekat</p>
            </div>

            <div className="col-md-6">
              <img src="/assets/images/spbu.png" className="w-100" />
            </div>

          </div>
        </section>


      {/* Info Pangan Section */}
      <section className="pangan-section">
        <div className="row h-100">

          <div className="col-md-4">
            <img src="/assets/images/vegetable.png" className="w-100" />
          </div>

          <div className="col-md-8 my-auto">
            <h1 className="homepage-title secondary-color text-center-mobile">Informasi Harga Pangan</h1>
            <p className="text-center-mobile">Dapatkan update informasi harga pangan secara berkala</p>
          </div>

        </div>
      </section>


      {/* Warkop Section */}
      <section className="warkop-section">
          <div className="row h-100">
            <div className="col-md-8">
              <img src="/assets/images/warkop.png" className="w-100" />
            </div>
            <div className="col-md-4 my-auto">
              <h1 className="warkop-title secondary-color text-center">Temukan Warkop Terdekat</h1>


            </div>
            </div>
        </section>
      </div>

      <div className="fluid-container">
        {/* Download Section */}
        <section className="download-section">

            <div className="row h-100">
              <div className='box'>
                <div className='wave -one'></div>
                <div className='wave -two'></div>
                <div className='wave -three'></div>
              </div>


              <div className="col-md-2">
              </div>
              <div className="col-md-4">
                <img src="/assets/images/mockup2.png" className="mockup2 w-100" />
              </div>
              <div className="col-md-6 my-auto text-center">
                <br/> <br/>
                <img src="/logo_white.png" width="200" />
                <h1 className="homepage-title white-color text-center">Download Sekarang</h1>
                <h3 className="white-color text-center">Tersedia di</h3>

                  <div className="row justify-content-md-center">
                  <div className="col-md-4">
                    <a href="https://play.google.com/store/apps/details?id=aceh.diskominsa.haba" target="_BLANK">
                      <img src="/assets/images/google_play.png" className="download-app" width="100%"/>
                    </a>
                  </div>
                  <div className="col-md-4">
                    <Link className="breadcrumb-item" to="/ios">
                      <img src="/assets/images/app_store.png" className="download-app" width="100%"/>
                    </Link>
                  </div>
                </div>

                {/* footer */}
                <footer className="home-footer">
                  <p className="text-center white-color">

                    Aplikasi Versi Demo - Developed by <br/>
                    <a href="http://diskominfo.acehprov.go.id/" className="text-center text-white" target="_blank">DINAS KOMUNIKASI, INFORMATIKA DAN PERSANDIAN ACEH</a>

                  </p>
                </footer>


              </div>
              </div>
          </section>




      </div>
    </div>
    );
  }

}
