import React from 'react';
import axios from "axios";
import { Link } from "react-router-dom";
import { message, Skeleton, Icon, notification } from 'antd';
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'

import './Login.css';

const api_url = process.env.REACT_APP_API_URL;

export default class Login extends React.Component {
  constructor(props) {
    super(props);

    if( localStorage.getItem(process.env.REACT_APP_TOKEN) ){
      window.location.replace("/admin/dashboard");
    }

    this.state = {
      email: '',
      password: '',
      recaptcha_token: "",
      disable_submit: true,
      loading: false
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(){
     document.title = process.env.REACT_APP_APP_NAME+"::Login"
     loadReCaptcha(process.env.REACT_APP_RECAPTCHA_SITE_KEY);

  }


  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

     this.setState({
       [name]: value
     });

  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({loading: true});

    axios.post( api_url+'admin_login',
      {
        email: this.state.email,
        password: this.state.password,
        "g-recaptcha-response": this.state.recaptcha_token
      }
    ).then(
      response => response.data,
      error => {
        alert( error )

      }
    )
    .then((data) => {
      if( typeof data == 'object' && data !== null ){
        if( data['status'] === 'success'){
          this.set_user_data( data['data'] );
          // window.location.reload();
          this.props.history.push("/admin/dashboard/");
        }else{

          notification.open({
            message: 'Error',
            description: data['data'],
            icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
          })
          // window.location.reload();

        }
      }
      this.setState({loading: false});
    })
  }


  set_user_data(user_data){
    if( typeof user_data !== 'undefined'){
      for (var i in user_data) {
        localStorage.setItem(process.env.REACT_APP_TOKEN, this.get_token( user_data ) );
        localStorage.setItem('token_expired', this.token_expired( user_data ) );
        localStorage.setItem('id', user_data['_id'] );
        localStorage.setItem('email', user_data['email'] );
        localStorage.setItem('username', user_data['username'] );
        localStorage.setItem('first_name', user_data['first_name'] );
        localStorage.setItem('last_name', user_data['last_name'] );
        localStorage.setItem('phone', user_data['phone'] );
        localStorage.setItem('is_active', user_data['is_active'] );
        localStorage.setItem('last_login', user_data['last_login'] );
        localStorage.setItem('group_id', user_data['group_id'] );
        localStorage.setItem('created_at', user_data['created_at'] );
        localStorage.setItem('updated_at', user_data['updated_at'] );


      }
      return true;
    }
  }

  get_token(user_data){
    var token = '';
    var data = user_data['sessions'];

    if( typeof data !== 'undefined'){
      for (var i in data) {
        var session = data[i];
        token += session['token'];
      }

      return token;
    }
  }

  token_expired(user_data){
    var expired = '';
    var data = user_data['sessions'];

    if( typeof data !== 'undefined'){
      for (var i in data) {
        var session = data[i];
        expired += session['expired_date'];
      }

      return expired;
    }
  }

  verifyCallback = (recaptchaToken) => {
    // document.getElementById("recaptcha_token").value = recaptchaToken;

    this.setState({
      recaptcha_token: recaptchaToken
    }, () =>{

      this.setState({
        loading: false,
        disable_submit: false
      });
    });

  }

  render() {
    let disabled;
    let submit_label = "Submit";
    if( this.state.disable_submit ){
      disabled = true;
      submit_label = "Mohon tunggu...";
    }

    return (
      <div className="col-md-8 mx-auto my-auto">
        <div className="row h-100 login-page">
          {/* Left side */}
          <div className="col-md-8 d-none d-sm-block left">
            <img src="/logo_white.png" width="150"/>
            <br/>
            <p className="text-white"><small><br/>Aplikasi versi demo</small></p>

            <Link className="btn btn-menu" to="/">
                <i className="fas fa-home"></i> Beranda
            </Link>
          </div>

          {/* Right side */}
          <div className="col-md-4 mx-auto my-auto right">
            <h1 className="display-4 text-center primary-color">LOGIN</h1>

            <Skeleton loading={this.state.loading} active>
              <form onSubmit={this.handleSubmit}>

              <div className="form-row">

                <input type="hidden" name="_csrf" value={this.state.csrf_token} />

                <div className="form-group col-md-12">
                  <label className="primary-color">Email/Username<span className="text-danger">*</span></label>
                  <input type="text" name="email" className="form-control flat-input" placeholder="Email atau Username" value={this.state.email} onChange={this.handleChange} required disabled={disabled}/>
                </div>

                <div className="form-group col-md-12">
                  <label className="primary-color">Password<span className="text-danger">*</span></label>
                  <input type="password" name="password" className="form-control flat-input" placeholder="Password" value={this.state.password} onChange={this.handleChange} required disabled={disabled}/>
                </div>

                <small id="error" className="text-danger text-center"></small>

                {/* <div className="col-md-6">
                  <div className="form-group">
                    <div className="form-check">
                      <label className="form-check-label primary-color">
                      <input type="checkbox" className="form-check-input"/>Ingatkan Saya  <i className="input-helper"></i></label>
                    </div>
                  </div>
                </div> */}


                <ReCaptcha
                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                    action='login'
                    verifyCallback={this.verifyCallback}
                />

                <button stype="submit" className="btn login-button" disabled={disabled}>{submit_label}</button>


              </div>
              </form>
            </Skeleton>
          </div>

        </div>
        <br /> <br />
        <p className="text-center"><small>Developed by<br/>
        DINAS KOMUNIKASI, INFORMATIKA DAN PERSANDIAN ACEH</small></p>
      </div>
    );
  }

}
