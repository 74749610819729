import React from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import {Skeleton, message, notification} from 'antd';

const api_url = process.env.REACT_APP_API_URL;

export default class Edit_users extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            user_id: this.props.match.params.id,
            username: '',
            first_name: '',
            last_name: '',
            password: '',
            verify: '',
            email: '',
            phone: '',
            is_active: '',
            group_id: '',
            loading: true,
            group: []
        };

        this.handleChange = this
            .handleChange
            .bind(this);
        this.handleSubmit = this
            .handleSubmit
            .bind(this);

    }

    componentDidMount() {
        document.title = process.env.REACT_APP_APP_NAME + "::Users";

        var header = {
            headers: {
                'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN)
            }
        };

        this.get_users();

        axios
            .get(api_url + "acl/groups/get_groups", header)
            .then(response => {
                this.setState({group: response.data.data, loading: false});
            })
            .catch(function (err) {
                console.log(err);
            })

    }

    get_users() {
        var header = {
            headers: {
                'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN)
            }
        };
        axios
            .get(api_url + 'acl/users/get_users/' + this.state.user_id, header)
            .then(response => response.data, error => {
                alert(error)
            })
            .then((data) => {
                if (typeof data == 'object') {
                    if (data['status'] === 'success') {
                        let is_active = data.data.is_active === true ? "yes":"no";
                        this.setState({
                            username: data.data.username,
                            email: data.data.email,
                            first_name: data.data.first_name,
                            last_name: data.data.last_name,
                            phone: data.data.phone,
                            is_active: is_active,
                            group_id: data.data.group_id
                        });
                    } else {
                        message.error(data['data']);
                    }

                }
            })
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        const name = target.name;


        this.setState({[name]: value});
    }

    handleListGroup() {
		const group_id = String(this.state.group_id);

        const Group = props => (
            <div className="col-sm-3">
                <div className="form-radio mt-2">
                    <label className="form-check-label">
                        <input
                            type="radio"
                            className="form-check-input"
                            name="group_id"
                            value={props.group._id}
                            checked={group_id === props.group._id}
                            onChange={this.handleChange}/> {props.group.group_name}
                        <i className="input-helper"></i>
                    </label>
                </div>
            </div>
        );

        return this
            .state
            .group
            .map(function (result, i) {
              if( result.group_name != "Developer"){
                return <Group group={result} key={i}/>;
              }
              
            });

    }

    handleSubmit(event) {
        event.preventDefault();

        message.loading('Loading...', 2.5);
        var is_active = (this.state.is_active === "yes")?1:0;
        var form_data = {
            username: this.state.username,
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
            phone: this.state.phone,
            is_active: is_active,
            group_id: this.state.group_id
        };

        var header = {
            headers: {
                'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN)
            }
        };

        axios
            .put(api_url + 'acl/users/edit/' + this.state.user_id, form_data, header)
            .then(response => response.data)
            .then((data) => {

                if (data['status'] === 'success') {

                    notification.open({message: 'Berhasil', description: 'Data telah disimpan ke database!'});

                    this
                        .props
                        .history
                        .push("/admin/acl/users");
                    message.destroy();
                } else {
                    message.error(data['data']);
                }

            })
    }

    render() {
        return (
            <div>
                <div className="col-12 grid-margin">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">ACL</li>
                            <Link className="breadcrumb-item" to="/admin/acl/users">Pengelola Aplikasi</Link>
                            <li className="breadcrumb-item active">Edit Pengelola</li>
                        </ol>
                    </nav>
                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="display-4 primary-color text-center">Edit Pengelola Aplikasi</h4>
                            <br/>

                            <Skeleton loading={this.state.loading} active>
                                <form onSubmit={this.handleSubmit}>
                                    <div className="row">
                                        <div className="col-md-6">

                                            {/* Input Username */}
                                            <div className="form-group">
                                                <label>Username<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Username"
                                                    name="username"
                                                    value={this.state.username !== null ? this.state.username : ''}
                                                    onChange={this.handleChange}
                                                    required/>
                                            </div>

                                            {/* Input Firstname */}
                                            <div className="form-group">
                                                <label>Nama Depan<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Nama Depan"
                                                    name="first_name"
                                                    value={this.state.first_name !== null ? this.state.first_name : ''}
                                                    onChange={this.handleChange}
                                                    required/>
                                            </div>

                                            {/* Input Lastname */}
                                            <div className="form-group">
                                                <label>Nama Belakang<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="nama Belakang"
                                                    name="last_name"
                                                    value={this.state.last_name !== null ? this.state.last_name : ''}
                                                    onChange={this.handleChange}
                                                    required/>
                                            </div>

                                            {/* Input Telepon */}
                                            <div className="form-group">
                                                <label>Telepon
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="phone"
                                                    name="phone"
                                                    value={this.state.phone !== null ? this.state.phone : ''}
                                                    onChange={this.handleChange} />
                                            </div>

                                        </div>

                                        <div className="col-md-6">

                                            {/* Input Email */}
                                            <div className="form-group">
                                                <label>Email<span className="text-danger">*</span>
                                                </label>
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    placeholder="Email Pengguna Aplikasi"
                                                    name="email"
                                                    value={this.state.email !== null ? this.state.email : ''}
                                                    onChange={this.handleChange}
                                                    required/>
                                            </div>

                                            {/* Input Active user */}
                                            <div className="form-group">
                                                <label>Status Aktif<span className="text-danger">*</span>
                                                </label>
                                                <div className="form-group row">
                                                    <div className="col-sm-3">
                                                        <div className="form-radio mt-2">
                                                            <label className="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="is_active"
                                                                    value="yes"
                                                                    onChange={this.handleChange}
                                                                    checked={this.state.is_active === "yes"}/>
                                                                Aktif
                                                                <i className="input-helper"></i>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <div className="col-sm-3">
                                                        <div className="form-radio mt-2">
                                                            <label className="form-check-label">
                                                                <input
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="is_active"
                                                                    value="no"
                                                                    onChange={this.handleChange}
                                                                    checked={this.state.is_active === "no"}/>
                                                                Tidak Aktif
                                                                <i className="input-helper"></i>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Input Pengguna Aplikasi */}
                                            <div className="form-group">
                                                <label>Level Pengguna<span className="text-danger">*</span>
                                                </label>
                                                <div className="form-group row">
                                                    {this.handleListGroup()}

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <button stype="submit" className="btn btn-full primary-button">Simpan</button>
                                </form>
                            </Skeleton>
                        </div>
                    </div>
                </div>

            </div>
        );
    }

}
