import React from 'react';
import {Link} from "react-router-dom";
import axios from "axios";
import {Skeleton, message, notification, Icon} from 'antd';

const api_url = process.env.REACT_APP_API_URL;

export default class Edit extends React.Component {
    constructor(props) {
        super(props);

        this.state = { id: this.props.match.params.id, nama:'',telepon:'', kabupaten_id:'',alamat:'',latitude:'',longitude:'', };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {

        document.title = process.env.REACT_APP_APP_NAME + "::Pelabuhan";
        this.get_data();


    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        const name = target.name;

        this.setState({[name]: value});
    }

    get_data(){
        let header = {
          headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };
        axios.get( api_url+'pelabuhan/get/'+this.state.id, header)
        .then(
          response => response.data,
          error => { alert( error ) }
        )
        .then((data) => {

          if( typeof data == 'object' ){
            if( data.status === 'success' ){
              this.setState({
                nama: data.data.nama,
       					telepon: data.data.telepon,
       					kabupaten_id: data.data.kabupaten_id,
       					alamat: data.data.alamat,
                latitude: data.data.location.coordinates[1],
       					longitude: data.data.location.coordinates[0],
 					//##state_field##:##state_value##//
                loading: false
            }, () => {
              this.get_kab_kota_list()
            });
            }else{
              message.error( data.data.data );
            }

          }
        })
      }


    handleSubmit(event) {
        event.preventDefault();

        message.loading('Loading...', 2.5);

        var form_data =
        {
          nama:this.state.nama,
 					telepon:this.state.telepon,
 					kabupaten_d:this.state.kabupaten_id,
 					alamat:this.state.alamat,
 					latitude:this.state.latitude,
 					longitude:this.state.longitude,
 					//##field##:##value##//
        };

        var header = {
          headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };

        axios.put( api_url+'pelabuhan/edit/'+this.state.id, form_data, header
        ).then(
          response => response.data,
          error => {
            alert(error)
          }
        )
        .then((data) => {
            if( typeof data == 'object' ){
                  if( data.status === 'success' ){

                    notification.open({
                      message: 'Berhasil',
                      description:'Data telah disimpan ke database!',
                    });

                    this.props.history.push("/admin/master_data/pelabuhan/list");

                  }else{
                    notification.open({
                        message: 'Error',
                        description: data.data,
                        icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
                      });
                  }
            }

        })
      }

      get_kab_kota_list(){
        axios.get(api_url+"wilayah/kabupaten/get_by_provinsi/11").then(
            response => {
              this.setState({ kab_kota_list: response.data.data});
          },
          error => {
            alert(error)
          }

        );
      }

      select_option_for_kab_kota(){
        if( this.state.kab_kota_list !== undefined && typeof this.state.kab_kota_list == "object"){
          if( this.state.kab_kota_list.length > 0 ){
            console.log( this.state.kabupaten_id )
            const res = (
              <select name="kabupaten_id" className="form-control" onChange={this.handleChange} required>
                <option value="" key={ Math.random() } >Pilih</option>
                {this.state.kab_kota_list.map(( value ) =>
                  <option value={value.kabupaten_id} key={ Math.random() } selected= {(value.kabupaten_id == this.state.kabupaten_id)?true:false}>{value.kabupaten}</option>
                )}
              </select>
            );

            return res;

          }
        }
      }

    render() {

        return (
            <div>
                <div className="col-12 grid-margin">

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Master Data</li>
                            <Link className="breadcrumb-item" to="/admin/master_data/pelabuhan/list">Pelabuhan</Link>
                            <li className="breadcrumb-item active">Edit</li>
                        </ol>
                    </nav>

                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="display-4 primary-color text-center">Edit Pelabuhan</h4>
                            <br />

                            {/* Form Input */}
                            <form onSubmit={this.handleSubmit}>
                                <div className="row">


				{/** Nama Pelabuhan **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Nama Pelabuhan<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Nama Pelabuhan" name="nama" value={this.state.nama} onChange={this.handleChange} required/>
                  </div>
                </div>


				{/** Nomor Telepon **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Nomor Telepon</label>
                    <input type="text" className="form-control" placeholder="Nomor Telepon" name="telepon" value={this.state.telepon} onChange={this.handleChange} />
                  </div>
                </div>


        {/** Kabupaten **/}
        <div className="col-md-6">
          <div className="form-group">
            <label>Kabupaten/Kota<span className="text-danger">*</span></label>
              { this.select_option_for_kab_kota() }
          </div>
        </div>


				{/** Alamat **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Alamat</label>
                    <input type="text" className="form-control" placeholder="Alamat" name="alamat" value={this.state.alamat} onChange={this.handleChange} />
                  </div>
                </div>


				{/** Latitude **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Latitude</label>
                    <input type="text" className="form-control" placeholder="Latitude" name="latitude" value={this.state.latitude} onChange={this.handleChange}/>
                  </div>
                </div>


				{/** Longitude **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Longitude</label>
                    <input type="text" className="form-control" placeholder="Longitude" name="longitude" value={this.state.longitude} onChange={this.handleChange}/>
                  </div>
                </div>

			{/*##form_input##*/}

                                </div>
                                <button stype="submit" className="btn btn-full primary-button">Simpan</button>
                            </form>


                        </div>
                    </div>



                </div>

            </div>
        );
    }

}
