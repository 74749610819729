import React from 'react';
import {Link} from "react-router-dom";
import axios, {put} from "axios";
import {Skeleton, message, notification, Icon} from 'antd';
import DatePicker from "react-datepicker";
import moment from 'moment';
import 'moment/locale/id';

const api_url = process.env.REACT_APP_API_URL;

export default class Edit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
          id: this.props.match.params.id,
          kabupaten   : "",
          pelabuhan_id: "",
          kapal_list  : null,
          kapal_id    : null,
          asal        : "",
          tujuan      : "",
          jam         : null,
          tanggal     : new Date(),
          image       : "",
          new_image   : ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeFile = this.onChangeFile.bind(this);
    }

    componentDidMount() {

        document.title = process.env.REACT_APP_APP_NAME + "::Jadwal Kapal Laut";
        this.get_data();
        this.get_kapal_list();
    }

    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        const name = target.name;

        this.setState({[name]: value});
    }

    onChangeFile(e) {
      this.setState({new_image:e.target.files[0]})
    }

    tanggal = date => {
      this.setState({
        tanggal: date
      });
    };

    get_data(){
        var header = {
          headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
        };
        axios.get( api_url+'pelabuhan/jadwal_kapal/get/'+this.state.id, header)
        .then(
          response => response.data,
          error => { alert( error ) }
        )
        .then((data) => {

          if( typeof data == 'object' ){
            if( data.status === 'success' ){
              this.setState({
                pelabuhan_id: data.data.pelabuhan_id,
                pelabuhan: data.data.pelabuhan,
 					kapal_id: data.data.kapal_id,
 					tujuan: data.data.tujuan,
          asal: data.data.asal,
          tanggal: new Date(data.data.tanggal),
          jam: data.data.jam,
          image: data.data.image,
 					//##state_field##:##state_value##//
                loading: false
            });
            }else{
              message.error( data.data.data );
            }

          }
        })
      }


      handleSubmit(event) {
          event.preventDefault();

          message.loading('Loading...', 2.5);

          let form_data =
          {
            asal:this.state.asal,
            tujuan:this.state.tujuan,
            kapal_id:this.state.kapal_id,
            pelabuhan_id: this.state.pelabuhan_id,
            jam:this.state.jam,
            tanggal:this.state.tanggal
          };

          let header = {
            headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
          };

          axios.put( api_url+'pelabuhan/jadwal_kapal/edit/'+this.state.id, form_data, header
          ).then(
            response => response.data,
            error => {
              alert(error)
            }
          )
          .then((data) => {
            if( data.status === 'success' ){

              notification.open({
                message: 'Berhasil',
                description:'Data telah disimpan ke database!',
              });

              this.componentDidMount()

            }else{
              notification.open({
                  message: 'Error',
                  description: data.data,
                  icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
                });
            }

          })
        }



          // fileUpload(file){
          //   const url = api_url+'pelabuhan/jadwal_kapal/edit/'+this.state.id;
          //   const formData = new FormData();
          //   formData.append('image',file);
          //   formData.append('asal',this.state.asal);
          //   formData.append('tujuan',this.state.tujuan);
          //   formData.append('kapal_id',this.state.kapal_id);
          //   formData.append('pelabuhan_id',this.state.pelabuhan_id);
          //   formData.append('jam',this.state.jam);
          //   formData.append('tanggal',this.state.tanggal);
          //
          //   const config = {
          //       headers: {
          //           'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN),
          //           'content-type': 'multipart/form-data'
          //       }
          //   }
          //   return  put(url, formData,config)
          // }


      get_kapal_list(){
        axios.get(api_url+"pelabuhan/get_kapal_list").then(
            response => {
              this.setState({ kapal_list: response.data.data });

            },
          error => {
            alert(error)
          }

        );
      }

        select_option_for_kapal(){

          if( this.state.kapal_list !== null && typeof this.state.kapal_list == "object"){


            if( this.state.kapal_list.length > 0 ){
              const res = (
                <select name="kapal_id" className="form-control" onChange={this.handleChange} required>
                  <option value="">Pilih</option>
                  {this.state.kapal_list.map(( value ) =>
                    <option value={value._id} key={ Math.random() } selected= {(value._id == this.state.kapal_id)?true:false}>{value.nama}</option>
                  )}
                </select>
              );

              return res;

            }
          }
        }


    render() {

        return (
            <div>
                <div className="col-12 grid-margin">

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Master Data</li>
                            <Link className="breadcrumb-item" to="/admin/master_data/jadwal_kapal/list">Jadwal Kapal Laut</Link>
                            <li className="breadcrumb-item active">Edit</li>
                        </ol>
                    </nav>

                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="display-4 primary-color text-center">Edit Jadwal Kapal Laut</h4>
                            <br />

                            {/* Form Input */}
                            <form onSubmit={this.handleSubmit}>
                            <div className="row">
                              {/** Maskapai **/}
                              <div className="col-md-12">
                                <div className="form-group">
                                  <label>Kapal<span className="text-danger">*</span></label>
                                    { this.select_option_for_kapal() }
                                </div>
                              </div>

                              {/* Input Asal */}
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Asal<span className="text-danger">*</span></label>
                                  <input type="text" className="form-control" placeholder="Asal" name="asal" value={this.state.asal} onChange={this.handleChange} required/>
                                </div>
                              </div>


                                {/* Input Tujuan */}
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Tujuan<span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" placeholder="Tujuan" name="tujuan" value={this.state.tujuan} onChange={this.handleChange} required/>
                                  </div>
                                </div>

                                {/* Image */}
                                {/*
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <img src={api_url+this.state.image} className="w-100"/>
                                  </div>
                                </div>
                                */}

                                {/* Tanggal */}
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Tanggal<span className="text-danger">*</span></label>

                                    {/* <input type="text" className="form-control" placeholder="Tanggal" name="tanggal" value={this.state.tanggal} onChange={this.handleChange} required/> */}
                                    <div className="datepicker-container">
                                      <DatePicker className=" form-control" dateFormat="dd-MM-yyyy" selected={this.state.tanggal} onChange={date => this.tanggal(date)} />
                                    </div>

                                  </div>
                                </div>

                                {/* Jam */}
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Jam<span className="text-danger">*</span></label>
                                    <input type="text" className="form-control" placeholder="Jam" name="jam" value={this.state.jam} onChange={this.handleChange} required/>
                                  </div>
                                </div>

                                {/** Upload Jadwal  **/}
                                {/*
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Ganti Gambar</label><br/>
                                    <input type="file" className="form-control-file" onChange={this.onChangeFile} />
                                    <small className="form-text text-muted">Gambar dengan format JPG atau PNG</small>
                                  </div>
                                </div>
                                */}


                                <button stype="submit" className="btn  primary-button">Simpan</button>
                                <Link className="btn secondary-button" to={`/admin/master_data/jadwal_kapal/detail/${this.state.pelabuhan_id}`}>Kembali</Link>
                              </div>
                            </form>


                        </div>
                    </div>



                </div>

            </div>
        );
    }

}
