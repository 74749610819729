import React from 'react';
import {Link} from "react-router-dom";
import axios, {post} from "axios";
import {Skeleton, Modal, message, notification, Icon} from 'antd';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Auth from "../../../helper/Authentication";

const api_url = process.env.REACT_APP_API_URL;
const confirm = Modal.confirm;

export default class Add extends React.Component {
    constructor(props) {
        super(props);
        Auth.is_access( window.location.pathname )
        this.state = {
          pasar:'',
          kabupaten_id:'',
          tanggal: new Date(),

          nama: "",
          satuan: "",
          harga: "",

          list_bahan_pokok: [],
          
          input_bahan_pokok_error: ""


        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        // this.handleTambahBahanPokok = this.handleTambahBahanPokok.bind(this);

    }

    componentDidMount() {

        document.title = process.env.REACT_APP_APP_NAME + "::Harga Pangan";
        this.get_kab_kota_list();
    }



    handleChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value;
        const name = target.name;

        this.setState({[name]: value});
    }


    tanggal = date => {
      this.setState({
        tanggal: date
      });
    };

    handleTambahBahanPokok( event ){
        let formElements = document.getElementById("formBahanPokok").elements;

        let input = {
          _id               : Math.random(),
          nama  : this.state.nama,
          satuan      : this.state.satuan,
          harga       : this.state.harga
        }

        if( input.nama !== "" && input.satuan !== "" && input.harga !== "" ){

          let list = this.state.list_bahan_pokok;
          list.push( input )

          this.setState({
            list_bahan_pokok: list,
            nama: "",
            satuan: "",
            harga: "",
            input_bahan_pokok_error: ""
          });
        }else{
          this.setState({
            input_bahan_pokok_error: "Mohon Lengkapi Form Input"
          });
        }

    }

    handleSubmit(event) {
        event.preventDefault();

        let bodyFormData = new FormData();

        bodyFormData.append('kabupaten_id', this.state.kabupaten_id);
        bodyFormData.append('pasar', this.state.pasar);
        bodyFormData.append('tanggal', this.state.tanggal);

        this.state.list_bahan_pokok.forEach((item) => {
            bodyFormData.append('bahan_pokok[]', JSON.stringify(item) );
        });


        let header = {
          headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) },
          'content-type': 'multipart/form-data'
        };

        axios.post( api_url+'harga_pangan/add', bodyFormData, header
        ).then(
          response => response.data,
          error => {
            alert(error)
          }
        )
        .then((data) => {
          if( data.status === 'success' ){

            notification.open({
              message: 'Berhasil',
              description:'Data telah disimpan ke database!',
            });

            this.props.history.push("/admin/master_data/harga_pangan/detail/"+data.data._id);

          }else{
            notification.open({
                message: 'Error',
                description: data.data,
                icon: <Icon type="close-circle" style={{ color: '#ff0000' }} />,
              });
          }

        })
      }

      get_kab_kota_list(){
        axios.get(api_url+"wilayah/kabupaten/get_by_provinsi/11").then(
            response => {
              this.setState({ kab_kota_list: response.data.data});
          },
          error => {
            alert(error)
          }

        );
      }

      select_option_for_kabupaten_id(){
        if( this.state.kab_kota_list !== undefined && typeof this.state.kab_kota_list == "object"){
          if( this.state.kab_kota_list.length > 0 ){

            const res = (
              <select name="kabupaten_id" className="form-control" onChange={this.handleChange} required>
                <option value="" key={ Math.random() }>Pilih</option>
                {this.state.kab_kota_list.map(( value ) =>
                  <option value={value.kabupaten_id} key={ Math.random() } selected= {(value.kabupaten_id == this.state.kabupaten_id)?true:false}>{value.kabupaten}</option>
                )}
              </select>
            );

            return res;

          }
        }
      }

      form_tambah_bahan_pokok(){
          let input_bahan_pokok_error = [];
          if( this.state.input_bahan_pokok_error ){
            input_bahan_pokok_error.push(
              <div className="alert alert-danger">
                {this.state.input_bahan_pokok_error}
              </div>
            )
          }

          return(
              <div>
                  <form onSubmit={this.handleTambahBahanPokok} id="formBahanPokok">
                    <div className="row">
                      <div className="col-md-4">
                          <div className="form-group">
                              <label>Nama Bahan Pokok<span className="text-danger">*</span></label>
                              <input type="text" className="form-control" placeholder="Nama Bahan Pokok" name="nama" value={this.state.nama} onChange={this.handleChange} required/>
                          </div>
                      </div>

                      <div className="col-md-4">
                          <div className="form-group">
                              <label>Satuan<span className="text-danger">*</span></label>
                              <input type="text" className="form-control" placeholder="Satuan" name="satuan" value={this.state.satuan} onChange={this.handleChange} required/>
                              <small className="form-text text-muted">Contoh: Kilogram (Kg)</small>
                          </div>
                      </div>

                      <div className="col-md-4">
                          <div className="form-group">
                              <label>Harga<span className="text-danger">*</span></label>
                              <input type="number" className="form-control" placeholder="Cth: 10000" name="harga" value={this.state.harga} onChange={this.handleChange} required/>
                              <small className="form-text text-muted">Hanya angka, tanpa tanda titik (.) atau koma (,)</small>
                          </div>
                      </div>

                      <div className="col-md-12">
                        {input_bahan_pokok_error}
                      </div>

                      <div className="col-md-12 text-right">
                          <button type="button" className="btn btn-sm btn-danger btn-sm" onClick={() => this.handleTambahBahanPokok()}><i className="fas fa-plus" /> Tambah</button>
                      </div>


                    </div>
                  </form>
              </div>
          );
      }

      list_bahan_pokok(){
        let list = this.state.list_bahan_pokok;

        if( list.length > 0 ){
          let res = list.map(( value ) =>
          <tr key={ Math.random() }>
            <td>{value.nama}</td>
            <td>{value.satuan}</td>
            <td>{value.harga}</td>
            <td>
              <a href="javascript:undefined" className="btn btn-sm btn-danger" onClick={() => this.hapusList(value._id, value.nama)}><i className="fas fa-trash"></i></a> &nbsp;
            </td>
          </tr>
          );

          return (
            <table className="table table-sm">
              <thead>
                <tr>
                  <th scope="col">Nama</th>
                  <th scope="col">Satuan</th>
                  <th scope="col">Harga</th>
                  <th scope="col">&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {res}
              </tbody>
            </table>
          );
        }



      }

    hapusList( id, nama ){
      let list = this.state.list_bahan_pokok;
      let t = this;

      confirm({
        title: "Hapus "+nama+" dari daftar?",
        content: "Data akan terhapus",
        onOk() {

          for( let x in list ){
            let data = list[x];
            if( data._id == id ){
              list.splice(x, 1)
            }
          }

          t.setState({
            list_bahan_pokok: list
          });

          notification.open({
            message: 'Berhasil',
            description:'Data telah terhapus!'
          });
        }
      });
    }

    render() {

        return (
            <div>
                <div className="col-12 grid-margin">

                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Master Data</li>
                            <Link className="breadcrumb-item" to="/admin/master_data/harga_pangan/list">Harga Pangan</Link>
                            <li className="breadcrumb-item active">Tambah</li>
                        </ol>
                    </nav>

                </div>

                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="display-4 primary-color text-center">Tambah Harga Pangan</h4>
                            <br />

                            {/* Form Input */}
                            <form onSubmit={this.handleSubmit}>
                                <div className="row">

          {/** Kabupaten **/}
          <div className="col-md-12">
            <div className="form-group">
              <label>Kabupaten/Kota<span className="text-danger">*</span></label>
                { this.select_option_for_kabupaten_id() }
            </div>
          </div>

				{/** Nama Pasar **/}
				<div className="col-md-6">
                  <div className="form-group">
                    <label>Nama Pasar<span className="text-danger">*</span></label>
                    <input type="text" className="form-control" placeholder="Nama Pasar" name="pasar" value={this.state.pasar} onChange={this.handleChange} required/>
                  </div>
                </div>


				{/** Tanggal **/}
				<div className="col-md-6">
          <div className="form-group">
            <label>Tanggal<span className="text-danger">*</span></label>
            <div className="datepicker-container">
              <DatePicker className="react-datepicker-input form-control" dateFormat="dd-MM-yyyy" selected={this.state.tanggal} onChange={date => this.tanggal(date)} />
            </div>
          </div>
        </div>

        <br/>
        <div className="col-md-12">
          <h3 className="soft-color display-4">Bahan Pokok</h3>

          {this.form_tambah_bahan_pokok()}
          {this.list_bahan_pokok()}

        </div>



			{/*##form_input##*/}

                                </div>
                                <br/><br/>

                                <button stype="submit" className="btn primary-button">Simpan</button>
                            </form>


                        </div>
                    </div>



                </div>

            </div>
        );
    }

}
