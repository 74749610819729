import React from 'react';
import { Link } from "react-router-dom";
import axios from "axios";
import {message, Skeleton} from 'antd';

import Header from "./include/Header";
import Footer from "./include/Footer";

import './Public.css';

const api_url = process.env.REACT_APP_API_URL;

export default class Ios_info extends React.Component {
  constructor(props) {
    super(props);

    this.state = { page_content: "", loading: true };

  }

  componentDidMount(){
     document.title = process.env.REACT_APP_APP_NAME;
  }

  render() {
    return (
      <div>
        <Header />

        <div className="container">

            <div className="col-md-12 page-content">
                <h1 className="homepage-title text-center secondary-color">iOS Version</h1>
                <p className="text-center">Aplikasi HABA mobile versi iOS sedang dalam masa pengembangan</p>
                <center>
                  <Link className="btn btn-menu bg-primary" to="/">
                      <i className="fas fa-home"></i> Kembali
                  </Link>
                </center>
            </div>

            <div class='box'>
              <div class='wave -one'></div>
              <div class='wave -two'></div>
              <div class='wave -three'></div>
            </div>

        <Footer />
        </div>
    </div>
    );
  }

}
