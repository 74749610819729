import React from 'react';
import axios from "axios";
import { message, notification, Skeleton } from 'antd';
import Moment from 'react-moment';
import 'moment/locale/id';
import Chart, {Bar} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const api_url = process.env.REACT_APP_API_URL;


export default class List extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      total_users  : 0,
      active_users  : 0,
      level_pengelola: 0,
      jumlah_survei: 0,
      jumlah_survei_publish: 0,
      jumlah_survei_draft: 0,
      daftar_survei: "",
      loading       : true,
      loading_survei: true
    };

  }

  componentDidMount(){
     document.title = process.env.REACT_APP_APP_NAME+"::Dashboard";
     this.load_data();
     this.load_data_survei();

   }

   load_data(){
     var form_data = {
       headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
     };

     axios.get(api_url+"statistik/all", form_data).then(
       response => {

          this.setState({
            total_users: response.data.data.jumlah_users,
            active_users:response.data.data.jumlah_users_active,
            level_pengelola:response.data.data.jumlah_group,
            jumlah_masjid:response.data.data.jumlah_masjid,
            jumlah_faskes:response.data.data.jumlah_faskes,
            jumlah_spbu:response.data.data.jumlah_spbu,
            jumlah_warkop:response.data.data.jumlah_warkop,
            jumlah_pelabuhan:response.data.data.jumlah_pelabuhan,
            jumlah_survei:response.data.data.jumlah_survei,
            jumlah_survei_publish:response.data.data.jumlah_survei_publish,
            jumlah_survei_draft:response.data.data.jumlah_survei_draft,
            loading: false
          });
      },
      error => {
        alert(error)
      }

    );
   }

   load_data_survei(){
     var form_data = {
       params: {limit: 5, offset: 0 },
       headers: { 'api_token': localStorage.getItem(process.env.REACT_APP_TOKEN) }
     };

     axios.get(api_url+"survei/list", form_data).then(
       response => {
          this.setState({
            daftar_survei: response.data.data,
            loading_survei: false
          });
      },
      error => {
        alert(error)
      }

    );
   }

   survei_row(){
     let data = this.state.daftar_survei;
     if( typeof data == "object" && data !== null){
       const res = data.map((value, index) =>
          <ul className="list-group" key={ Math.random() } >
            <li className="list-group-item d-flex justify-content-between align-items-center">
              {value.judul}
              <span className="badge badge-warning badge-pill"></span>
            </li>
          </ul>
        );
        return res;
    }
  }




  render() {
    // let chart_data = {
    //   labels: ['Masjid', 'Fasilitas Kesehatan', 'SPBU', 'Warung Kopi', 'Pelabuhan'],
    //   datasets: [
    //     {
    //       label: '',
    //       backgroundColor: 'rgba(63, 167, 213, 0.8)',
    //       borderColor: 'rgba(63, 167, 213, 0.8)',
    //       borderWidth: 0,
    //       hoverBackgroundColor: 'rgba(158, 158, 158, 0.8)',
    //       data: [this.state.jumlah_masjid, this.state.jumlah_faskes, this.state.jumlah_spbu, this.state.jumlah_warkop, this.state.jumlah_pelabuhan]
    //     }
    //   ]
    // }
    //
    //


    return (
    <div>
      <div className="row">

      <div className="col-12 grid-margin">

          <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                  <li className="breadcrumb-item">Dashboard</li>
              </ol>
          </nav>

      </div>

      <Skeleton loading={this.state.loading} active>
        <div className="col-md-4 grid-margin">
          <div className="row dashboard-card dashboard-card-primary">
            <div className="col-md-9 dashboard-card-content">
              <h5>Jumlah Pengelola</h5>
              <h1 className="primary-color">{this.state.total_users}</h1>
            </div>
            <div className="col-md-3 dashboard-card-icon">
              <i className="fas fa-users"></i>
            </div>
          </div>
        </div>

        <div className="col-md-4 grid-margin">
          <div className="row dashboard-card dashboard-card-primary">
            <div className="col-md-9 dashboard-card-content">
              <h5>Level Pengelola</h5>
              <h1 className="primary-color">{this.state.level_pengelola}</h1>
            </div>
            <div className="col-md-3 dashboard-card-icon">
              <i className="fas fa-user"></i>
            </div>
          </div>
        </div>

        <div className="col-md-4 grid-margin">
          <div className="row dashboard-card dashboard-card-empty">
            <div className="col-md-9 dashboard-card-content">
              <h5>&nbsp;</h5>
              <h1 className="primary-color">&nbsp;</h1>
            </div>
            <div className="col-md-3 dashboard-card-icon">
              <i className=""></i>
            </div>
          </div>
        </div>

        <div className="col-md-12 grid-margin">
          <div className="row h-100 dashboard-card primary-bg">

            <div className="col-md-8 my-auto">
              <div className="d-flex">
                <div className="wrapper">
                  <h1 className="display-4 page-title white-color">Halo, {localStorage.getItem('first_name')}</h1>
                  <h5 className="mb-5 font-weight-light text-white">Anda terakhir login pada <Moment format="dddd, DD MMMM YYYY HH:mm:ss" local>{localStorage.getItem('last_login')}</Moment></h5>
                </div>
              </div>
            </div>

            <div className="col-md-4 my-auto">
              <div className="d-flex">
                <div className="wrapper">
                  <h4 className="mb-0 font-weight-light text-white"><Moment format="dddd" local></Moment></h4>
                  <h3 className="mb-0 font-weight-light text-white"><Moment format="DD MMMM YYYY" local></Moment></h3>
                  <h5 className="mb-0 font-weight-light text-white"><Moment format="HH:mm:ss" interval={1000} local></Moment></h5>
                </div>
              </div>
            </div>


          </div>
        </div>

        {/*
        <div className="col-md-4">

          <div className="col-md-12">
            <div className="row dashboard-card dashboard-card-info">
              <div className="col-md-9 dashboard-card-content">
                <h5 className="white-color">Jumlah Survei</h5>
                <h1 className="white-color">{this.state.jumlah_survei}</h1>
              </div>
              <div className="col-md-3 dashboard-card-icon">
                <i className="white-color fab fa-wpforms"></i>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="row dashboard-card dashboard-card-green">
              <div className="col-md-9 dashboard-card-content">
                <h5 className="white-color">Survei Dipublish</h5>
                <h1 className="white-color">{this.state.jumlah_survei_publish}</h1>
              </div>
              <div className="col-md-3 dashboard-card-icon">
                <i className="white-color fab fa-wpforms"></i>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="row dashboard-card dashboard-card-warning">
              <div className="col-md-9 dashboard-card-content">
                <h5 className="white-color">Draft</h5>
                <h1 className="white-color">{this.state.jumlah_survei_draft}</h1>
              </div>
              <div className="col-md-3 dashboard-card-icon">
                <i className="white-color fab fa-wpforms"></i>
              </div>
            </div>
          </div>

        </div>

        <div className="col-md-8 dashboard-card">
          <h3 className="primary-color">Survei Terbaru</h3>
          <br/>
          {this.survei_row()}
        </div>

        */}

      </Skeleton>




      </div>
    </div>
    );
  }

}
