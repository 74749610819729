import React, { Component } from "react";
import { Link } from "react-router-dom";

class Header extends Component {

  componentDidMount(){
    let id = document.getElementById("sidebarCollapse");
    id.onclick = function(){
      document.getElementById("sidebar").classList.toggle("active");
      id.classList.toggle("active");
    }
  }

  render() {
    return (
      <nav className="navbar default-layout col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
        <div className="navbar-brand-wrapper d-flex align-items-top justify-content-center">
          <Link className="navbar-brand admin brand-logo" to="/admin/dashboard">
            <img src="/logo.png" alt="logo" />
          </Link>

          <Link className="navbar-brand brand-logo-mini" to="/admin/dashboard">
            <img src="/logo.png" alt="logo" />
          </Link>

        </div>

        <div className="navbar-menu-wrapper d-flex align-items-center">

          <button type="button" id="sidebarCollapse" className="navbar-btn">
              <span></span>
              <span></span>
              <span></span>
          </button>

          <ul className="navbar-nav navbar-nav-right">
            <li className="nav-item dropdown d-none d-xl-inline-block">
              <a href="#userDropdown" className="nav-link dropdown-toggle" id="UserDropdown"  data-toggle="dropdown" aria-expanded="false">
                <span className="profile-text">Halo, {localStorage.getItem('first_name')} {localStorage.getItem('last_name')}</span>

              </a>
              <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="UserDropdown">

                <Link className="dropdown-item mt-3" to="/admin/edit_profil">
                  Edit Profil
                </Link>

                <Link className="dropdown-item" to="/logout">
                  Logout
                </Link>

              </div>
            </li>
          </ul>
          <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>

  );
  }
}

export default Header;
